:root {
  /* COMPONENT SIZES */
  --appbarHeight: 64px;

  /* COLORS ***************************/
  --color-white: #ffffff;
  --color-gray-200: #d3d6d9;

  --color-gray1: #d4d4d4;
  --color-gray2: #aeaeae;
  --color-gray-light: #606060;
  --color-gray-semi: #404040;
  --color-gray-mid: #4f5459;
  --color-gray-dark: #3e444b;
  --color-gray-xdark: #32373e;
  --color-gray-600: #5d656f;
  --color-gray-650: #464b53;
  --color-gray-700: #383c42;
  --color-gray-725: #363a40;
  --color-gray-750: #2f3237;
  --color-gray-800: #25282c;
  --color-gray-dark-800: #484e55;
  --color-gray-xxdark: #1e2125;
  --color-black: #000;

  --color-red: #eb1833;
  --color-red-dark: #ea1732;

  --color-scw-orange-dark: #f38b00;
  --color-scw-orange-light: #ffb700;
  --color-scw-orange-yellow: #fed18f;
  --color-scw-dark-yellow: #ffc012;
  --color-scw-teal-dark: #19bdc8;
  --color-scw-teal: #1ad9e5;
  --color-scw-teal-light: #a0f3f3;
  --color-scw-success: #95bf40;
  --color-scw-green-darkest: #4a592c;
  --color-scw-green-dark-lightest: #cbe891;

  --color-scw-red: #FF5C7C;
  --color-scw-gray-2: #CCC;
  --color-scw-gray-light: #D2D3D5;
  --color-scw-gray: #BABCC0;
  --color-scw-green: #96E577;
  --color-scw-orange: #FFB700;
  --color-scw-black: #25282C;

  /* BACKGROUND COLORS ***************************/
  --bg-blue-dark: #333e48;
  --bg-gray-light: #3c4147;
  --bg-gray-mid: #313539;
  --bg-gray-dark: #303235;
  --bg-gray-xdark: #25292e;
  --bg-gray-xxdark: #1f2226;
  --bg-gray-850: #1c1e21;
  --bg-gray-900: #131416;
  --bg-black-mid: #1b1e21;
  --bg-black-venta: #1c1f23;
  --bg-black-xventa: #14171a;
  --bg-blackest: #000000;
  --bg-transparent-white-150: rgba(255, 255, 255, 0.06);

  /* TEXT COLORS ***************************/
  --text-white: #ffffff;
  --text-black: #000000;
  --text-link: #ffc131;
  --text-muted-dark: #aaaaaa;
  --text-muted-dark-light: #cccccc;
  --text-muted-light: #444444;
  --text-gray-dark: #c0c0c0;

  /* BORDER COLORS ***************************/
  --border-width1: 1px;
  --border-width2: 2px;
  --border-width3: 4px;
  --border-gray0: #aaaaaa;
  --border-gray1: #454545;
  --border-gray2: #606060;
  --border-gray3: #3c4147;
  --border-gray4: #707070;
  --border-gray5: #303030;
  --border-gray6: #464b53;
  --border-blue1: #050505;
  --border-yellow: #ffb700;
  --border-video-dark: #f27ce2;
  --border-challenge-dark: #59fabf;
  --border-checkpoint-dark: #f3fa59;
  --border-custom-dark: #469dff;
  --border-mission-dark: #745df6;
  /* FONT SIZES ***************************/
  --font-size1: 10px;
  --font-size2: 11px;
  --font-size3: 12px;
  --font-size4: 13px;
  --font-size5: 14px;
  --font-size6: 16px;
  --font-size7: 18px;
  --font-size8: 20px;
  --font-size9: 24px;

  --font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  /* FONT STYLES ***************************/
  --font-weight-xlight: 100;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 700;

  /* RADIUS ***************************/
  --radius-light: 1px;
  --radius-mid: 2px;
  --radius-semi: 4px;
  --radius-heavy: 50%;

  /* OPACITY ***************************/
  --opacity-30: 0.3;
  --opacity-50: 0.5;
  --opacity-80: 0.8;
  --opacity-90: 0.9;

  /* Line Heights ***************************/
  --line-height-reset: 1em;
  --line-height-headers: 1.2em;
  --line-height-text: 1.3em;
  --line-height-text-loose: 1.35em;

  /* SIZING ***************************/
  --size-header-height: 60px;
  --size-footer-height: 60px;
  --size-modal-min-height: 200px;
  --size-button-height-default: 34px;
  --size-button-height-variant: 44px;
  --size-number-size: 28px;
  --size-general-page-padding: 28px;
  --size-elem-xxs: 12px;
  --size-elem-xs: 16px;
  --size-elem-sm: 24px;
  --size-elem-md: 32px;
  --size-elem-lg: 48px;
  --size-elem-xl: 64px;
  --size-elem-xxl: 80px;
  --size-elem-width1: 320px;
  --size-elem-width2: 480px;
  --size-elem-width3: 610px;

  /* SHADOW ********************************/
  --shadow-fields-inner: inset 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  --shadow-container-float: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
  --shadow-container-float-dark: 0 2px 40px 10px rgba(0, 0, 0, 0.3);
  --shadow-tile: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  --shadow-nav-outer: 0 2px 10px 0 rgba(0, 0, 0, 0.4);

  /* SPACING ********************************/
  --spacing-s1: 4px;
  --spacing-s2: 8px;
  --spacing-s3: 10px;
  --spacing-s4: 12px;
  --spacing-s: 14px;
  --spacing-2s: 28px;
  --spacing-xs: 16px;
  --spacing-xs2: 18px;
  --spacing-m: 20px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-2xl: 40px;
  --spacing-3xl: 80px;
  --spacing-ender: 120px;

  /* BADGES ********************************/
  --badge-dimensions-w: 65px;
  --badge-dimensions-h: 65px;

}
