/* Removed due to the new Design System changes */
/* body, html {
  overflow: auto;
} */

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.button-wrapper button {
  min-width: max-content;
}

.outline-important {
  outline: auto!important;
}

.md-button {
  text-shadow: none;
  font-weight: bold;
  text-transform: lowercase;
  border: none;
  border-radius: 4px;
  min-width: 44px;
  padding: 0 15px;
  margin: 6px 4px;
  font-family: Open Sans, sans-serif;
}

.md-button .glyphicon {
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
  padding-bottom: 4px;
}

.md-button md-icon {
  vertical-align: middle;
}

.md-button.md-default-theme.md-accent:not([disabled]) md-icon, .md-button.md-accent:not([disabled]) md-icon {
  color: inherit;
}

.md-button span {
  display: inline-block;
}

.md-button::first-letter, .md-button span::first-letter {
  text-transform: uppercase !important;
}

.md-min {
  min-width: min-content;
}

.md-button.md-primary:hover, .md-button.md-primary:focus, .md-button.md-primary:disabled {
  background-color: var(--color-scw-orange) !important;
}

.md-button:disabled, .md-button.md-primary.md-raised:disabled {
  cursor: not-allowed !important;
  color: black;
  opacity: 0.5;
}


.md-button.md-lg {
  transform: scale(1.1);
}

.md-button.md-sm {
  transform: scale(0.9);
}

.md-button.md-xs {
  transform: scale(0.8);
}

.md-button:hover {
  opacity: 0.8;
}

.md-button.md-accent, .md-button.md-secondary, .md-button.md-accent:hover, .md-button.md-accent:focus, .md-button.md-accent:disabled {
  color: var(--color-scw-orange);
  background-color: rgba(96, 96, 96, 0.3);
}

.md-button.md-info .md-button.md-info:hover, .md-button.md-info:disabled, .md-button.md-info:focus{
  background-color: var(--color-scw-teal) !important;
  color: black;
}

.md-button.md-light, .md-button.md-light:focus, .md-button.md-light:hover, .md-button.md-light:disabled {
  background-color: rgba(158, 158, 158, 0.2);
}

.md-button.md-raised, .md-button.md-raised:not([disabled]) {
  box-shadow: none;
}

.md-button.md-success, .md-button.md-success:hover, .md-button.md-success:focus, .md-button.md-success:disabled{
  background-color: var(--color-scw-green) !important;
  color: black;
}

.md-button.md-danger, .md-button.md-danger:hover, .md-button.md-danger:focus, .md-button.md-danger:disabled {
  background-color: var(--color-scw-red) !important;
  color: black;
}

.md-button:disabled, .md-button.md-danger.md-raised:disabled {
  cursor: not-allowed !important;
  color: black;
  opacity: 0.5;
}

.btn-group {
  display: flex;
}

.btn-group > .md-button {
  margin-left: 0;
  margin-right: 0;
}

.btn-group-spaced > .md-button {
  margin-left: 2px;
  margin-right: 2px;
}

.md-nav-item .md-button {
  background: transparent;
  border-bottom: var(--color-scw-orange);
  color: black;
}

.material-icons {
  color: inherit;
}

md-icon {
  color: inherit;
}

.md-big-icon {
  width: min-content;
  height: min-content;
  font-size: 45px;
}
