body {
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.header-hidden .page-wrapper {
  /*
  Override inline style from the index.html file, we don't want to move that one to a css file since it could degrade
  the user experience for users until the CSS rule has been loaded
   */
  margin-top: 0 !important;
}

h5 {
  font-weight: bold;
  color: #ffb700;
  margin-top: 14px;
}

.table-auto {
  table-layout: auto;
}

.small-text {
  font-size: 0.75em;
}

.cursor-pointer {
  cursor: pointer;
}

.single-liner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}

.ui-view-container {
  position: relative;
}

[ui-view].ng-enter,
[ui-view].ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

[ui-view].ng-enter {
  /*
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	transform: translateX(100%);
	*/
  opacity: 0;
  transition-delay: 0.1s;
}

[ui-view].ng-enter-active {
  /*
	-webkit-transform: translateX(0%);
	-moz-transform: translateX(0%);
	transform: translateX(0%);
	*/
  opacity: 1;
}

[ui-view].ng-leave {
  opacity: 1;
}

[ui-view].ng-leave-active {
  /*
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	transform: translateX(-100%);
	*/
  opacity: 0;
}

.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.dropdown-menu li a.keyboard-selected {
  color: #fff;
  border-radius: 3px;
}

.missions-debug-bar {
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #14171a;
  border-top: solid 1px #3e444b;
  padding-top: 8px;
}

.missions-debug-bar:hover {
  opacity: 0.9;
}

.missions-debug-bar a {
  border: solid 1px #777;
  margin-left: 15px;
}

.missions-debug-bar-hintsText {
  margin-left: 15px;
}

.dropdown-menu > li > a {
  padding: 10px 20px 10px 10px;
}

.dropdown-menu .user-name {
  margin: 10px 20px;
}

.dropdown-menu .divider {
  margin: 3px 0 0;
}

.sub-menu-icon {
  font-size: 200%;
  display: block;
  float: left;
  margin-top: 5px;
  margin-right: 12px;
}

.border-bottom-menu {
  border-bottom: 0.55px solid #999999;
}

.help-menu {
  min-width: 300px !important;
}

.dropdown-menu.help-menu .navitem-text {
  margin-right: 44px;
}

.dropdown-menu.help-menu > li:first-child .navitem-text {
  margin-right: 58px;
}

.dropdown-menu.help-menu .navitem-text.small-text-slim {
  margin-right: 30px !important;
}

/*hide churnzero button*/
#czChatButtonWrapper,
.cz-chat-button-2-wrapper {
  display: none !important;
}

.churnzero-badge {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 0 8px;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  background-color: red;
}

.churnzero-alert {
  position: absolute;
  right: 30px;
  top: 5px;
  width: 12px;
  border-radius: 5px;
  height: 12px;
  background-color: red;
  border: 1px solid #333f48;
}

.dropdown-navitem:hover .churnzero-alert {
  display: none;
}

#cz_success_center_container {
  display: none !important;
}

.help-menu-new-tab-icon {
  position: absolute;
  font-size: 18px;
  top: 12px;
  right: 12px;
}

.small-text-slim {
  font-size: 80%;
  font-weight: 200;
  letter-spacing: 1px;
}

.btn {
  border: 0px;
  border-radius: 6px;
  font-size: 13px;
}

.btn,
.btn:hover {
  text-shadow: none;
}

.btn-xs {
  border-radius: 2px;
}

.btn.big-text {
  font-size: 18px;
}

.btn-xs {
  font-size: 12px;
}

.login-container {
  width: 400px;
  box-shadow: 0px 0px 52px 22px #444;
  border-radius: 5px;
  margin-bottom: 60px !important;
  margin-top: 60px !important;
  background-size: auto 250px;
  background-color: rgba(40, 40, 40, 0.7);
}

.login-container form {
  padding: 10px 30px 30px 30px;
  border-radius: 5px;
}

.login-container form button {
  margin-bottom: 15px;
}

.login-container .login-logo {
  width: 340px;
  margin: 0 auto;
  padding-top: 15px;
  display: block;
}

.centred {
  float: none;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.floating-box {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  background-color: #fff;
  width: 200px;
  height: 300px;
  padding: 15px;
}

.merge-button {
  display: none;
}

.question-progress {
  font-size: xx-large;
}

.answer-modal-glyph {
  font-size: xx-large;
}

.modal-open {
  overflow-y: scroll;
}

div.modal[uib-modal-window] {
  z-index: 1151 !important;
}

.incomplete {
  color: #3d3a39;
}

.incorrect {
  color: #c9493a;
}

.correct {
  color: #657d19;
}

.wandering-cubes-spinner:before {
  background-color: #f38b00;
}

.wandering-cubes-spinner:after {
  background-color: #ffb700;
}

.wandering-cubes-spinner {
  margin: 0;
}

.wave-spinner {
  margin: 0;
  height: 30px;
  text-align: center;
  font-size: 10px;
  width: 50px;
}

.wave-spinner > div {
  background-color: #f38b00;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

@-webkit-keyframes stretchdelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    background-color: #ffb700;
  }
}

@keyframes stretchdelay {
  0%,
  100%,
  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    background-color: #ffb700;
  }
}

.loading {
  position: relative;
  top: 10px;
  width: 50px;
  height: 30px;
  z-index: 100;
  margin-right: 15px;
  cursor: default;
}

.loading-text {
  margin-top: 18px;
  margin-right: 10px;
  font-size: 11px;
  cursor: default;
}

.glyphicon-user {
  position: relative;
  top: 4px;
  font-size: 46px;
  margin-left: 15px;
}

.glyphicon-user.reset-glyphicon-user-size-to-normal {
  font-size: 13px;
  margin-left: 0;
  top: 2px;
}

.user-menu-dropdown {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 5px !important;
  padding-right: 15px !important;
}

.user-menu-dropdown img {
  margin: 5px 9px;
  border-radius: 9px;
  height: 40px;
  width: 40px;
}

#tourButton {
  font-size: large;
}

body {
  overflow-x: hidden;
}
body.new-simple-flow-state {
  padding-top: 0;
}

.leaflet-container {
  background: #111;
}

.terminal-box {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-top: 1px solid #ffb700;
  z-index: 2000;
}

.terminal-close {
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.terminal {
  background-color: rgba(0, 0, 0, 0.8);
}

.cmd {
  background-color: transparent;
}

@-webkit-keyframes slideInTop {
  0% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes slideInTop {
  0% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes slideOutTop {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
}

@keyframes slideOutTop {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
}

.fade-out.on-remove.ng-hide-add {
  opacity: 0;
  transition: opacity 0.5s ease-in-out !important;
}

.fade-in.on-add.ng-enter,
.fade-in.on-add.ng-hide-remove {
  opacity: 1;
  transition: opacity 1s ease-in-out !important;
}

.slide-left.on-add.ng-enter {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: slideInright;
}

.slide-right.on-add {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: slideInRight;
}

.slide-left.on-remove.ng-hide-add {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: slideInLeft;
}

.slide-right.on-remove.ng-hide-add {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: slideOutRight;
}

.slide-up.on-add {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInTop;
  animation-name: slideInTop;
}

.slide-up.on-remove {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideOutTop;
  animation-name: slideOutTop;
}

.round-timer-wrapper {
  height: 42px;
  width: 150px;
  background-color: #32383e;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  z-index: 1035;
  position: absolute;
  left: 0;
  right: 0;
  top: -65px;
  margin-left: auto;
  margin-right: auto;
}

.round-progress-wrapper {
  position: relative;
  margin: 0 auto;
}

.round-progress-text {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 76px;
  font-size: 14px;
  color: #bbb;
  text-align: center;
}

.round-progress {
  margin-left: auto;
  margin-right: auto;
}

.timer-text-upper {
  width: 100%;
  padding-top: 2px;
  font-size: 10px;
  text-align: center;
}

.timer-text-lower {
  width: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
}

.nv-axis text {
  fill: #bbb;
}

.nv-legend text {
  fill: #bbb;
}

.visibility-hidden {
  visibility: hidden;
}

.not-displayed {
  display: none;
}

.uncloak-alert {
  display: block !important;
}

.textarea-noresize {
  resize: none;
}

.progressbar1 {
  width: 50%;
}

.progressbar2 {
  width: 33.33%;
}

.progressbar3 {
  width: 25%;
}

.progressbar-text {
  white-space: nowrap;
}

/*.nested-drawer {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: #111;
}

.nested-drawer.drawn {
	width: 300px;
}*/

.in.collapse + a.btn.showdetails:before {
  content: 'Hide details';
}

.collapse + a.btn.showdetails:before {
  content: 'Show details';
}

/*.ng-pageslide {
	background: #2e3338;
}

.ng-pageslide-content {
	padding: 12px;
}*/

.drawer-toggle-button,
.expand-code-toggle-button {
  position: absolute;
  left: 15px;
  top: -30px;
}

.view-diff-tutorial-button {
  position: absolute;
  right: 60px;
  bottom: 74px;
  z-index: 100;
}

.view-diff-tutorial-button > .glyphicon {
  font-size: 18px;
  opacity: 0.9;
}

.solution-button {
  margin-left: 5px;
}

header {
  margin-bottom: 10px;
}

footer {
  margin-top: 10px;
  /*position: absolute;
	bottom: 0;*/
  text-align: center;
  width: 100%;
}

body > footer {
  width: 100%;
  z-index: 0 !important;
}

.inline-header {
  display: inline;
}

.orange-text {
  color: #f38b00;
}

.yellow-text {
  color: #ffb700;
}

.dull-yellow-text {
  color: #dd9935;
}

.black-text {
  color: #000;
}

.red-text {
  color: #c9493a;
}

.red-text-scoring-info {
  color: #fc7e7e;
}

.green-text {
  color: #657d19;
}

.green-text-scoring-info {
  color: #8eaf23;
}

.completed-text {
  color: #757575;
}

.blue-text {
  color: #006b94;
}

.white-text {
  color: #fff;
}

.gold-text {
  color: #ffa008;
}

.silver-text {
  color: #c4c7ce;
}

.bronze-text {
  color: #ad6b2a;
}

.country-header {
  line-height: 30px;
}

.worldmap-terminal {
  font-size: 16px;
  position: absolute;
  left: 24px;
  top: 110px;
  z-index: 100;
  cursor: pointer;
}

.worldmap-home {
  font-size: 16px;
  position: absolute;
  left: 23px;
  top: 136px;
  z-index: 100;
  cursor: pointer;
}

.worldmap-mute {
  font-size: 16px;
  position: absolute;
  left: 25px;
  top: 160px;
  z-index: 100;
  cursor: pointer;
}

.worldmap-attribution {
  font-size: 8px;
  position: absolute;
  right: 0;
  bottom: 5px;
  z-index: 100;
  max-width: 40%;
  text-align: right;
  padding-right: 10px;
}

.navigate-back-control-page {
  position: relative;
  top: 9px;
  left: 9px;
  z-index: 1;
  margin-bottom: 10px;
}

.ui-select-container {
  height: auto;
  padding: 0;
}

.form-control.ui-select-container.ui-select-multiple {
  height: auto;
}

.select2-results .select2-highlighted ul {
  background: inherit;
  color: inherit;
}

/* start - from animate.css */
.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  25% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  75% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  25% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  75% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-o-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  25% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  75% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  25% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  75% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate(-150%, 0);
    transform: translate(-150%, 0);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate(-150%, 0);
    transform: translate(-150%, 0);
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

/* end - from animate.css */

.arc {
  stroke-dasharray: 3;
  animation: dash 3s linear forwards infinite;
  -webkit-animation: dash 3s linear forwards infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 30;
  }
}

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 30;
  }
}

th.tablesort-sortable,
.non-sortable-header {
  background-color: rgba(0, 0, 0, 0.6);
  text-align: left;
}

.sortable-header {
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  color: #dd9935;
  text-align: left;
}

.third-width-cell {
  width: 33%;
}

table .tablesort-asc,
table .tablesort-desc {
  background-color: rgba(0, 0, 0, 0.8);
}

.class-limited {
  background-color: rgba(97, 78, 102, 0.4);
}

.class-aware {
  background-color: rgba(0, 107, 148, 0.4);
}

.class-skilled {
  background-color: rgba(101, 125, 26, 0.4);
}

.class-champion {
  background-color: rgba(255, 183, 0, 0.4);
}

.shift-up {
  margin-top: -5px;
  margin-bottom: 2px;
}

.slide-left {
  z-index: 50;
  display: block;
}

.slide-left-remove {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #272b30;
  z-index: 20;
  display: block;
}

.slide-left-add {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #272b30;
  z-index: 20;
  display: block;
}

@-webkit-keyframes slideCodeOut {
  0% {
    left: 0%;
  }

  100% {
    left: 42%;
  }
}

@keyframes slideCodeOut {
  0% {
    left: 0%;
  }

  100% {
    left: 42%;
  }
}

.content-container {
  position: absolute;
  height: 100%;
  width: 42%;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 15px;
}

.introjs-tooltip {
  background-color: #e4e5e6;
  color: #333e48;
}

.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.4);
}

.introjs-tooltip {
  min-width: 300px;
  /*max-width: 500px;*/
}

.cursor-pointer,
.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-help,
.cursor-help:hover {
  cursor: help;
}

.not-allowed {
  cursor: not-allowed !important;
}

image[pointer] {
  cursor: pointer;
}

.colour-box-red {
  background-color: #f44336;
}

.colour-box-green {
  background-color: #4caf50;
}

.colour-box-orange {
  background-color: #ff9800;
}

.colour-box-scwred {
  background-color: #c9493a;
}

.colour-box-scworange {
  background-color: #f38b00;
}

.colour-box-scwyellow {
  background-color: #ffb700;
}

.colour-box-blue {
  background-color: #2196f3;
}

.colour-box-scwgreen {
  background-color: #657d19;
}

.colour-box-scwblue {
  background-color: #006b94;
}

.colour-box-scwpurple {
  background-color: #614d65;
}

.colour-box-darkpurple {
  background-color: #282040;
}

.colour-box-cyan {
  background-color: #00bcd4;
}

.colour-box-scwdarkgrey {
  background-color: #333e48;
}

.colour-box-scwgrey {
  background-color: #61666b;
}

.colour-box-scwlightgrey {
  background-color: #dce0e5;
}

.colour-box-bluegrey {
  background-color: #607d8b;
}

.colour-box-darkgreen {
  background-color: #3c9f40;
}

.colour-box-scwdullyellow {
  background-color: #dd9935;
}

.colour-box-teal {
  background-color: #009688;
}

.colour-box-purple {
  background-color: #9c27b0;
}

.colour-box-black {
  background-color: #161616;
}

.multicolour-bar-box {
  width: 20%;
  height: 15px;
  display: inline-block;
}

.intro-splash {
  width: 60%;
  margin-bottom: 50px;
}

.intro-splash-img {
  max-width: 100%;
  max-height: 400px;
}

.intro-splash-img-3 {
  max-width: 50%;
  font-size: 100px;
}

.intro-splash-pull-right {
  float: right;
}

.intro-splash-centred,
.text-centred {
  text-align: center;
}

.embedded-video {
  margin: 5px 0 0 10px;
  display: block;
}

.mission-video-close {
  position: absolute;
  top: 4px;
  right: 6px;
}

.bg-worldmap {
  position: fixed;
  top: 60px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  opacity: 0.15;
  z-index: -50;
}

.bg-worldmap .jvectormap-zoomin {
  display: none;
}

.bg-worldmap .jvectormap-zoomout {
  display: none;
}

.waiting-assignment .waiting-for-assignment {
  padding: 50px 0px 65px 0px;
  margin: 60px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 100 !important;
}

.waiting-assignment .waiting-for-assignment img {
  float: right;
  max-height: 200px;
}

.waiting-assignment .waiting-for-assignment h2 {
  font-weight: 300;
  font-size: 40px;
  margin-bottom: 30px;
  color: #ffb700;
}

.introjs-tooltipbuttons > a:hover {
  color: #666;
}

.active-mission-table {
  background-color: transparent;
}

.active-mission-table td[ng-click]:hover,
.active-mission-table tr.keyboard-selected {
  background-color: var(--color-scw-orange);
  color: black;
  box-shadow: none !important;
}

.active-mission-table tr[ng-click]:hover span,
.active-mission-table tr.keyboard-selected span {
  color: black !important;
}

.active-mission-table tr:hover button,
.active-mission-table tr.keyboard-selected button {
  background: white;
  color: black !important;
  font-weight: bold;
}

.active-mission-table tr:hover button:hover,
.active-mission-table tr.keyboard-selected button:hover {
  background: white;
}

.active-mission-table td {
  padding: 2px 6px 0px 4px;
}

.active-mission-item {
  margin: 0 -15px 10px -15px;
  padding: 0 15px 0 15px;
}

.active-mission-item:hover {
  background-color: rgba(192, 192, 255, 0.1);
}

.collapse-text-toggle {
  color: #999;
}

.collapse-text-toggle:hover {
  color: #eee;
}

.chart-legend {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.chart-legend .legend-controls {
  margin-top: 16px;
  width: 100%;
}

.chart-legend .legend-control {
  box-sizing: content-box;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: normal;
  padding: 3px 2%;
  color: #fff;
  margin-left: 0.5%;
  float: left;
  min-height: 70px;
  position: relative;
}

.chart-legend .legend-control-game {
  background-color: #000;
}

.chart-legend .donut-legend-control {
  width: 20.625%;
}

.chart-legend .spider-legend-control {
  width: 15.6%;
}

.chart-legend .maturity-legend-control-game {
  width: 45.75%;
}

.legend-control-beginner {
  border-top: 5px solid rgba(255, 255, 255, 0.2);
}

.legend-control-aware {
  border-top: 5px solid rgba(255, 255, 255, 0.5);
}

.legend-control-skilled {
  border-top: 5px solid rgba(255, 255, 255, 0.8);
}

.legend-control-champion {
  border-top: 5px solid rgba(255, 183, 0, 1);
}

.chart-legend .legend-control:first-child {
  border-bottom-left-radius: 3px;
  margin-left: 0px;
}

.chart-legend .legend-control:last-child {
  border-bottom-right-radius: 3px;
}

.legend-text-upper {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-top: 5px;
  word-break: break-word;
}

.donut-legend-text-upper {
  font-size: 13px;
}

.spider-legend-text-upper {
  font-size: 10px;
  height: 32px;
}

.spider-legend-text-upper-game {
  font-size: 12px;
  margin-top: 10px;
}

.maturity-legend-text-upper {
  font-size: 14px;
  height: 25px;
}

.legend-text-under {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-top: 0;
}

.donut-legend-text-under {
  font-size: 26px;
}

.spider-legend-text-under {
  font-size: 16px;
  /*position: absolute;*/
  /*bottom: 8px;*/
  /*left: 50%;*/
}

.maturity-legend-text-under {
  font-size: 20px;
  color: #ffb700;
}

.swtime-slider-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 81px;
  padding-top: 15px;
  border-top: 5px solid rgba(255, 255, 255, 0.5);
}

.sw-time-current {
  color: #fff;
}

.colour-box-innertext {
  margin-left: 15px;
  color: #fff;
}

.nvd3 text {
  fill: #fff;
}

.easy-pie {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 130px;
  height: 130px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.easy-pie-game {
  background-color: #000;
}

.easy-pie.easy-pie-time canvas {
  position: absolute;
  top: 13px;
  left: 13px;
}

.easy-pie canvas {
  position: absolute;
  top: 20px;
  left: 20px;
}

.easy-pie-text {
  line-height: 130px;
  z-index: 2;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Roboto', sans-serif;
}

.easy-pie-text sup {
  font-size: 12px;
}

.donut-text {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -54px;
  margin-left: -100px;
  z-index: 2;
  width: 200px;
}

.donut-text-upper {
  font-weight: 300;
  font-size: 60px;
  font-family: 'Roboto', sans-serif;
  line-height: 66px;
  color: rgba(255, 183, 0, 1);
}

.donut-text-under {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  color: rgba(255, 255, 255, 0.8);
}

.donut-label-web {
  font-weight: 300;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 46px;
  color: rgba(255, 183, 0, 1);
  margin-left: 75px;
}

.donut-label-mobile {
  font-weight: 300;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 46px;
  color: rgba(255, 183, 0, 1);
  margin-left: 70px;
}

.colour-box {
  width: 49.5%;
  padding: 0 0 100px 0;
  margin: 5px 0.5% 5px 0.5%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*
  Adapted from .colour-box-small but with width/padding/margin removed so
  that these can be controlled by bootstrap and container classes
 */
.colour-box-small-bootstrap {
  min-height: 130px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.colour-box:first-child {
  margin-left: 0;
}

.colour-box:last-child {
  margin-right: 0;
}

.colour-box-fullwidth {
  margin: 5px 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  /*position: relative;*/
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.colour-box-small {
  min-height: 130px;
  width: 24%;
  margin: 5px 0.5%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 993px) {
  .colour-box-container {
    padding: 0.5%;
  }
  .colour-box-container:first-child {
    padding-left: 0px;
  }
  .colour-box-container:last-child {
    padding-right: 0px;
  }
}

@media (max-width: 992px) {
  .colour-box-container {
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.simple-flow .colour-box-black {
  margin: 0 !important;
}

.simple-flow .colour-box-small:first-child {
  margin-left: 0;
}

.simple-flow .colour-box-small:last-child {
  margin-right: 0;
}

.colour-box-small-qtr {
  width: 24.25% !important;
}

.colour-box-small-third {
  width: 32.666%;
}

.colour-box-small-half {
  width: 49.5% !important;
}

.colour-box-small-full {
  width: 100%;
}

.colour-box-text-v {
  color: #fff;
  padding: 12px 20px;
  margin: 0px 0px 12px 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.colour-box-text-v-dark {
  /* color: #FFF; */
  padding: 12px 20px;
  margin: 0px 0px 12px 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.colour-box-text-v-game {
  color: #fff;
  padding: 12px 20px;
  margin: 0px 0px 20px 0px;
  background-color: #000;
}

.colour-box-text-h {
  margin-top: 40px;
  padding-left: 150px;
  color: #fff;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
  margin-bottom: 0;
}

.colour-box-text-h-accuracy {
  margin-top: 29px;
  padding-left: 150px;
  color: #fff;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
  margin-bottom: 0;
}

.colour-box-text-h2 {
  padding-left: 150px;
  color: #fff;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-top: 0;
}

.colour-box-text-h3 {
  padding-left: 150px;
  color: #fff;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-top: 0;
}

.colour-box-text-h5 {
  padding-left: 150px;
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-top: 0;
}

.colour-box-text-note {
  padding-left: 150px;
  color: #fff;
  font-size: 9px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-top: 0;
}

.slate-padding-fixup {
  padding-left: 0;
  padding-right: 0;
}

.assessment-challenge-row {
  padding: 15px;
  margin: 15px 0px;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #666;
  -moz-border-top-colors: none;
  -moz-border-right-colors: none;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  border-image: none;
  border-radius: 4px;
}

.incomplete-challenge {
  border-color: #ffb700;
}

.invalid-challenge {
  border-color: #ef5f5b;
}

.valid-challenge {
  border-color: #3b9a3a;
}

.deprecated-challenge {
  border-color: #33ebff;
}

.alert-info-custom {
  background-color: #33ebff;
  color: #000;
}

.alert-warning-custom {
  background-color: #f89406;
  color: #000;
}

.inactive-challenge {
  border-color: #666;
}

.invalid-previously-added-challenge {
  border-color: #e96506;
}

.abandon-assessment-container {
  width: 80%;
  padding-top: 18px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
}

.abandon-assessment-container > a {
  color: #fcb702;
  text-decoration: underline !important;
}

.table-cell {
  vertical-align: middle !important;
}

.action-cell {
  cursor: default !important;
}

.avatar-header {
  width: 100px;
  text-align: center;
}

.action-header {
  min-width: 200px;
  text-align: center;
}

.rank-header {
  width: 70px;
}

.centred-cell {
  text-align: center;
}

.hotkey-table {
  margin: 0 auto;
  background-color: transparent;
  width: 70%;
}

.hotkey-table td {
  padding: 6px;
  text-align: left;
}

.profile-avatar {
  float: left;
  margin-right: 20px;
  margin-bottom: 15px;
}

.answer-correct-modal-header {
  border-bottom: 2px solid #657d19;
}

.answer-incorrect-modal-header {
  border-bottom: 2px solid #c9493a;
}

.answer-modal-time {
  position: absolute;
  right: 25pt;
  top: 25pt;
  font-weight: bold;
  font-family: sans !important;
}

.answer-modal-time .glyphicon {
  position: relative;
  top: 1pt;
}

/*.modal-content {
	background-color: rgba(0,30,50,0.9);
	border: 1px solid rgba(0,50,80,1);
	border-radius: 0px;
}*/

.feedback-panel.feedback-panel-slide {
  max-height: 400px;
}

.feedback-panel {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-height: 0px;
  overflow: hidden;
}

a:hover,
a:focus {
  color: #dd9935;
}

a {
  color: #dd9935;
}

a.disabled {
  color: #dd993588;
  cursor: not-allowed;
}

.security-maturity-box {
  height: 460px;
}

.security-maturity-box-metrics {
  height: 452px;
}

.security-maturity-box-metrics > .relative.zoomable {
  height: 287px;
}

.rank-change-arrow {
  font-size: 12px;
}

.rank-change-text {
  font-size: 16px;
}

.metrics-delta {
  font-size: 12px;
}

.leaderboard-rank-change-arrow {
  font-size: 11px;
}

.leaderboard-table {
  color: #fff;
}

.leaderboard-me {
  background-color: rgba(255, 255, 255, 0.08);
}

.sw-column-correct {
  background-color: #657d19;
  height: 0;
  position: absolute;
  bottom: 38px;
  right: 0;
  width: 5px;
}

.sw-column-correct-left {
  background-color: #305500;
  right: 5px;
}

.sw-column-incorrect {
  background-color: #c9493a;
  height: 0;
  position: absolute;
  top: 38px;
  right: 0;
  width: 5px;
}

.sw-column-incorrect-left {
  background-color: #993000;
  right: 5px;
}

.side-panel-div {
  width: 29.55%;
  margin-left: 0.3%;
  margin-right: 0.3%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

#newsfeed-panel {
  background: #111;
  overflow: auto;
  /*border-top: 4px ridge #006b94;
	border-bottom: 4px groove #006b94;
	border-radius: 12px;*/
  padding: 0 15px;
  margin-top: 5px;
}

#newsfeed-panel p {
  margin: 0;
  line-height: 1.2;
  font-family: 'Inconsolata';
}

#leaderboard-panel {
  background: #111;
  overflow: auto;
  /*border-top: 4px ridge #006b94;
	border-bottom: 4px groove #006b94;
	border-radius: 12px;*/
  padding: 0 15px;
}

.worldmap-column {
  background-color: #111;
  /*border-top: 4px ridge #006b94;
	border-bottom: 4px groove #006b94;
	border-radius: 12px;*/
  width: 69.55%;
  margin-left: 0.3%;
  overflow: hidden;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.worldmap-column.worldmap-column-fullwidth {
  width: 100%;
}

.worldmap-row {
  width: 100%;
  margin: 0;
  padding: 0;
}

.worldmap-column-leaderboard-hidden {
  background-color: #111;
  /*border-top: 4px ridge #006b94;
	border-bottom: 4px groove #006b94;
	border-radius: 12px;*/
  width: 100%;
  margin-left: 0.3%;
  overflow: hidden;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

#worldmap {
}

.worldmap-overlay {
  font-family: 'Inconsolata', monospace;
  max-width: 70%;
  max-height: 40%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 30, 50, 0.9);
  border: 1px solid rgba(0, 50, 80, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 10px 5px;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.worldmap-overlay-hidden {
  left: -70%;
}

.worldmap-overlay-text {
  margin: 0;
}

.active-mission-button {
  font-family: 'Inconsolata', monospace;
  font-size: 12px;
  padding: 0 10px;
  border: solid #1f628d 2px;
  background-color: transparent;
  margin-left: 8px;
  margin-bottom: 3px;
  width: 74px;
}

.active-mission-button:hover {
  background: rgba(0, 80, 120, 0.6);
}

.active-mission-button:disabled {
  opacity: 0.3;
}

.active-mission-button:disabled:hover {
  background: inherit;
  cursor: default !important;
}

.worldmap-dialog {
  width: 70%;
  height: 60%;
  background-color: rgba(0, 30, 50, 0.9);
  border: 1px solid rgba(0, 50, 80, 1);
  position: absolute;
  top: 20%;
  left: 15%;
  z-index: 100;
  font-family: 'Inconsolata', monospace;
  /*border-top: 4px ridge #006b94;
	border-bottom: 4px groove #006b94;
	border-radius: 12px;*/
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.worldmap-dialog h4 {
  font-family: 'Inconsolata', monospace;
}

/*.worldmap-dialog h4:after {
	content: "_";
}*/

.worldmap-dialog.ng-animate.ng-enter {
  left: -100%;
}

.worldmap-dialog.ng-animate.ng-enter.ng-enter-active {
  left: 15%;
}

.worldmap-dialog.ng-animate.ng-leave {
  left: 15%;
}

.worldmap-dialog.ng-animate.ng-leave.ng-leave-active {
  left: -100%;
}

.worldmap-dialog-content {
  height: 83%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 15px;
}

.worldmap-dialog-footer {
  padding: 8px 15px 0px 15px;
  border-top: 1px solid rgba(0, 50, 80, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
}

.worldmap-dialog-button {
  font-family: 'Inconsolata', monospace;
  font-size: 13px;
  padding: 0 10px;
  border: solid #1f628d 2px;
  background-color: transparent;
}

.worldmap-dialog-button-accept {
  border: 2px solid #c9493a;
  background-color: transparent;
}

.worldmap-dialog-button-accept:focus {
  outline: 1px solid #c9493a;
}

.worldmap-dialog-button-close {
  border: 2px solid #1f628d;
  background-color: transparent;
}

.worldmap-dialog-button:hover {
  background: rgba(0, 80, 120, 0.6);
}

.worldmap-dialog-briefing-icons {
  min-height: 80px;
  text-align: center;
  margin-bottom: 7px;
  padding: 0 15px;
}

.worldmap-dialog-briefing-icon-attacker {
  float: left;
  width: 150px;
}

.worldmap-dialog-briefing-icon-secure {
  float: left;
  width: 50px;
  font-size: 24px;
  padding-top: 32px;
}

.padlock-pulse {
  -webkit-animation: padlock-pulse-green 1.2s infinite ease-in-out;
  animation: padlock-pulse-green 1.2s infinite ease-in-out;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

@-webkit-keyframes padlock-pulse-green {
  0%,
  100% {
    color: #859d39;
  }
  50% {
    color: #657d19;
  }
}

@keyframes padlock-pulse-green {
  0%,
  100% {
    color: #859d39;
  }
  50% {
    color: #657d19;
  }
}

.worldmap-dialog-briefing-icon-target {
  float: left;
  width: 50px;
  font-size: 10px;
  padding-top: 40px;
}

.chevron-red {
  color: #c9493a;
}

.worldmap-dialog-briefing-icon-system {
  float: left;
  width: 150px;
}

.worldmap-stats {
  width: 280px;
  min-height: 118px;
  max-height: 50%;
  background-color: rgba(0, 30, 50, 0.9);
  border: 1px solid rgba(0, 50, 80, 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  font-family: 'Inconsolata', monospace;
  font-size: 13px;
  overflow: hidden;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.worldmap-stats-min {
  right: -210px;
}

.worldmap-stats-otherinfo {
  float: left;
  width: 207px;
  min-height: 116px;
  padding: 5px 5px 8px 5px;
}

.worldmap-stats-otherinfo .col-md-3 {
  padding: 0;
  text-align: center;
}

.worldmap-stats-otherinfo p:first-child {
  margin: 0;
}

.worldmap-stats-otherinfo p {
  margin: 4px 0 0 0;
}

.worldmap-stats-otherinfo .progress {
  height: 6px;
  margin: 0;
}

.worldmap-stats-level-desc {
  font-size: 16px;
  line-height: 14px;
}

.worldmap-stats-levelinfo {
  float: left;
  width: 70px;
  height: 112px;
  padding: 5px 0;
}

.worldmap-stats-level-text,
.worldmap-stats-maturity-text,
.worldmap-stats-points,
.worldmap-stats-points-text {
  line-height: 14px;
  text-align: center;
  font-size: 14px;
}

.worldmap-stats-maturity-icon-past {
  opacity: 0.2;
}

.worldmap-stats-maturity-icon-future {
  opacity: 0.2;
}

.worldmap-pie {
  position: relative;
  height: 60px;
  text-align: center;
  margin-bottom: 2px;
}

.worldmap-pie canvas {
  position: absolute;
  top: 5px;
  left: 10px;
  margin-bottom: 2px;
}

.worldmap-pie-text {
  line-height: 60px;
  z-index: 2;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
}

.worldmap-maturity-shield {
  text-align: center;
}

.worldmap-maturity-shield img {
}

.row-no-margins {
  margin: 0;
}

.progress {
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}

.progress > .progress-bar {
  overflow: visible;
  white-space: nowrap;
}

.progress > .progress-bar > span {
  position: absolute;
  display: block;
  width: 100%;
}

.progress-bar-info {
  background-color: #006b94;
}

.progress-shift-up {
  margin-top: -15px;
  margin-bottom: 0;
}

.progress-no-bottom-margin {
  margin-top: 5px;
  margin-bottom: 0;
}

.preset-link {
  padding: 5px;
}

.preset-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mission-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.mission-button {
  width: 72px;
}

.panel {
  border: 1px solid #ddd;
  border-width: 0 1px 4px 1px;
}

.maturity-display-col {
  font-family: 'Roboto', sans-serif;
  width: 50%;
}

.maturity-display-col:first-child {
  float: left;
}

.maturity-display-col:last-child {
  float: right;
}

.maturity-display-next-glyphicon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  color: #c9493a;
}

.maturity-display-nextimg {
  height: 100px;
  opacity: 0.7;
}

.maturity-display-progressbars {
  width: 80%;
  margin: 0 auto;
}

.maturity-display-progressbars .progress {
  margin-bottom: 8px;
}

.maturity-display {
  width: 50%;
  text-align: center;
  padding-left: 10px;
}

.maturity-display-leveltext {
  font-family: 'Roboto', sans-serif;
}

.maturity-display > h1 {
  cursor: help;
}

.maturity-display-prev {
  position: absolute;
  top: 52px;
  left: 18px;
}

.maturity-display-prev > .glyphicon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: #657d19;
}

.maturity-display-next {
  position: absolute;
  top: 52px;
  right: 18px;
}

.maturity-display-next > .glyphicon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: #c9493a;
}

.maturity-display-prev > img,
.maturity-display-next > img {
  opacity: 0.3;
}

.maturity-display-prev > h5,
.maturity-display-next > h5 {
  cursor: help;
}

.popover-content > ul {
  padding-left: 20px;
}

.security-maturity-box .popover-content {
  width: 250px;
}

/* #us_report_button {
	right: 140px !important;
	z-index: 10010 !important;
} */

.select2-results {
  max-height: 400px;
}

.preloading-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(0,0,0,0.3);*/
  z-index: 5000;
}

.preloading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-width: 200px;
  min-height: 100%;
  /*	background-color: #222; */
  border-radius: 6px;
  -webkit-box-shadow: 20px 20px 20px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 20px 20px 20px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 20px 20px 20px -5px rgba(0, 0, 0, 0.75);
  background-image: linear-gradient(-45deg, hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.05));
  background-size: 5px;
}

.preloading > .wave-spinner {
  margin: 0 auto;
}

.preloading .preloading-bg-left,
.preloading .preloading-bg-right {
  background: #333;
  width: 50%;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0px;
  position: fixed;
}

.preloading .preloading-bg-right {
  background: #222;
  left: 50%;
}

.preloading .preloading-bg-left,
.preloading .preloading-bg-right {
  background: #222;
  background-image:
    -webkit-repeating-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0) 0%,
      hsla(0, 0%, 100%, 0) 6%,
      hsla(0, 0%, 10%, 0.1) 7.5%
    ),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0) 4%, hsla(0, 0%, 10%, 0.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0) 1.2%, hsla(0, 0%, 10%, 0.15)
          2.2%),
    linear-gradient(180deg, hsl(0, 0%, 0%) 0%, hsl(0, 0%, 18%) 47%, hsl(0, 0%, 16%) 53%, hsl(0, 0%, 12%) 100%);
}

.preloading > h4 {
  margin-bottom: 50px;
  text-align: center;
}

.preloading-logo {
  left: 0%;
  position: fixed;
  margin-top: 10%;
  margin-left: 40%;
  width: 20%;
  text-align: center;
  border: 1px solid #e80;
  border-radius: 50%;
  padding: 2% 3%;
  background: #222;
  background: -webkit-linear-gradient(bottom right, #000, #444);
  background: -moz-linear-gradient(bottom right, #000, #444);
  background: -o-linear-gradient(bottom right, #000, #444);
  background: linear-gradient(bottom right, #000, #444);
  box-shadow: #e80 0px 0px 15px 1px;
  animation: loading-glow 0.9s infinite alternate;
}

@keyframes loading-glow {
  from {
    box-shadow: 1px 1px 10px 1px #d60;
  }
  to {
    box-shadow: 0px 0px 15px 3px #e80;
  }
}

.preloading-logo .wave-spinner {
  margin: auto;
}

.app-loaded .preloading-logo .wave-spinner {
  display: none;
}

.preloading-logo .unlocked-message {
  display: none;
}

.app-loaded .preloading-logo .unlocked-message {
  display: block;
  font-weight: bold;
  color: #e80;
  text-shadow: #e80 0px 0px 8px;
  font-size: 13pt;
}

.preloading-logo img {
  width: 100%;
  height: auto;
}

@media screen and (orientation: portrait) {
  .preloading-logo {
    width: 60%;
    margin-left: 20%;
    margin-top: 40%;
    padding: 5% 9%;
  }
}

.preloading-container.app-loaded {
  z-index: -999;
  transition: z-index 0s ease-out;
  transition-delay: 1.5s; /* This delay is crucial for the animation to look correct! PLAT-10868 */
}

.preloading-container.remove-preloading {
  display: none;
}

.preloading-container.app-loaded .preloading-bg-left {
  left: 0%;
  width: 0%;
}

.preloading-container.app-loaded .preloading-bg-right {
  left: 100%;
  width: 0%;
}

.preloading-container.app-loaded .preloading-bg-right,
.preloading-container.app-loaded .preloading-bg-left {
  transition: all 0.8s;
  transition-delay: 0.7s;
}

.preloading-container.app-loaded .preloading-logo {
  left: -50%;
  opacity: 0;
  transition: all 0.8s;
  transition-delay: 0.7s;
}

.preloading .alert {
  border-radius: 0;
}

.btn-input-text {
  height: 36px;
}

.btn:hover {
  margin-top: 0;
}

@keyframes sweetAlertIn {
  0% {
    top: -100%;
  }

  100% {
    top: 50%;
  }
}

.sweet-alert.showSweetAlert {
  animation-name: sweetAlertIn;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

.sweet-alert {
  top: 50%;
  position: fixed;
  transform: translateY(-50%) translateX(-50%) !important;
  border-radius: 10px;
  overflow: auto;
  width: auto;
  max-height: calc(80%);
  margin: auto !important;
}

.linkedin-profile-section {
  position: absolute;
  top: 20px;
  right: 0;
}

.linkedin-profile-public {
  margin-right: 12px;
  margin-top: 1px;
}

.linkedin-profile-public .popover-inner {
  width: 250px;
}

.stats-public-url {
  text-align: right;
  margin-bottom: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-height: 0px;
  overflow: hidden;
}

.stats-public-url.stats-public-url-slide {
  max-height: 30px;
}

.shield-container {
  position: relative;
  text-align: center;
}

.shield-img {
  opacity: 0.9;
}

.shield-text {
  position: absolute;
  width: 100%;
  top: 58px;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
  font-size: 25px;
}

.shield-text-correct {
  -webkit-animation: shield-pulse-correct 2s infinite ease-in-out;
  animation: shield-pulse-correct 2s infinite ease-in-out;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes shield-pulse-correct {
  0%,
  100% {
    color: #ffb700;
    text-shadow:
      0 3px 5px #000,
      0 -3px 5px #222,
      3px 0 5px #000,
      -3px 0 5px #222;
  }
  50% {
    color: #ffd760;
    text-shadow:
      0 3px 5px #550,
      0 -3px 5px #552,
      3px 0 5px #550,
      -3px 0 5px #552;
  }
}

@keyframes shield-pulse-correct {
  0%,
  100% {
    color: #ffb700;
    text-shadow:
      0 3px 5px #000,
      0 -3px 5px #222,
      3px 0 5px #000,
      -3px 0 5px #222;
  }
  50% {
    color: #ffd760;
    text-shadow:
      0 3px 5px #550,
      0 -3px 5px #552,
      3px 0 5px #550,
      -3px 0 5px #552;
  }
}

.shield-text-incorrect {
  -webkit-animation: shield-pulse-incorrect 2s infinite ease-in-out;
  animation: shield-pulse-incorrect 2s infinite ease-in-out;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes shield-pulse-incorrect {
  0%,
  100% {
    color: #f9493a;
    text-shadow:
      0 3px 5px #000,
      0 -3px 5px #222,
      3px 0 5px #000,
      -3px 0 5px #222;
  }
  50% {
    color: #ff695a;
    text-shadow:
      0 3px 5px #500,
      0 -3px 5px #522,
      3px 0 5px #500,
      -3px 0 5px #522;
  }
}

@keyframes shield-pulse-incorrect {
  0%,
  100% {
    color: #f9493a;
    text-shadow:
      0 3px 5px #000,
      0 -3px 5px #222,
      3px 0 5px #000,
      -3px 0 5px #222;
  }
  50% {
    color: #ff695a;
    text-shadow:
      0 3px 5px #500,
      0 -3px 5px #522,
      3px 0 5px #500,
      -3px 0 5px #522;
  }
}

.public-player-statistics-header {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 10px;
  margin-top: -15px;
  margin-bottom: 25px;
}

.public-player-registered {
  position: absolute;
  top: 8px;
  right: 0;
}

.tutorial-button {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;

  font-size: 14px;
  bottom: 0px;
  right: 178px;

  background-color: rgb(0, 94, 153);

  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid solid none;
  border-width: 1px;
  color: rgb(255, 255, 255);
  border-radius: 5px 5px 0px 0px;

  position: fixed;

  box-sizing: content-box;
  -moz-box-sizing: content-box;

  padding: 0;

  text-align: left;
  line-height: 32px;

  cursor: pointer;
  text-shadow: none;
  z-index: 100;

  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  height: 33px;
  width: 130px;
  margin: 0;

  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;

  text-transform: uppercase;
  animation: 2s button-slide-up;

  right: 326px;
  display: none;
}

@keyframes button-slide-up {
  0% {
    height: 0px;
  }
  100% {
    height: 33px;
  }
}

.tutorial-button:hover {
  height: 42px !important;
  text-decoration: none;
  color: #fff;
  transition: height 0.5s ease;
}

.tutorial-icon {
  width: 2em;
  height: 2em;
  max-height: 100%;
  margin-top: 0.3em;
  display: block;
}

.tutorial-icon:before {
  font-size: 22px;
}

.swal-hint-pre {
  background: transparent;
  border: transparent;
  text-align: left;
  white-space: pre-wrap;
  white-space: -o-pre-wrap;
  white-space: -moz-pre-wrap;
  word-break: break-word !important;
}

.modal-content {
  background-color: rgba(0, 30, 50, 0.9);
  border: 1px solid rgba(0, 50, 80, 1);
  font-family: 'Inconsolata', monospace;
  border-radius: 0;
  cursor: initial;
}

.course-filter .modal-content {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: rgba(0, 30, 50, 1);
}

.modal-content h4 {
  font-family: 'Inconsolata', monospace;
}

.modal-header {
  border-bottom: 1px solid rgba(0, 50, 80, 1);
}

.modal-footer {
  border-top: 1px solid rgba(0, 50, 80, 1);
}

.home-modal-home-icon {
  color: #ffb700;
  font-size: 20px;
}

.thumbnail {
  overflow-x: hidden;
  overflow-y: auto;
  transition: box-shadow 0.2s ease-in-out;
  /*margin-bottom: 0;*/
}

.thumbnail > img {
  height: 150px;
}

.thumbnail-active-text {
  position: absolute;
  top: 4px;
  right: 8px;
}

.thumbnail-updated-text {
  position: absolute;
  top: 4px;
  left: 6px;
  color: #657d19;
}

.caption-heading {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.picker-caption-heading {
  font-size: 21px;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  color: #0590c5;
}

.caption-subheading {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.picker-caption-subheading {
  font-size: 18px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0 0 10px 0;
  color: #0590c5;
}

.thumbnail-simple-flow {
  height: 280px;
  padding-top: 20px;
  cursor: pointer;
  border: 1px solid rgba(0, 50, 80, 1);
  background-color: #001e32e6;
}

.thumbnail-language {
  height: 280px;
  padding-top: 20px;
  cursor: pointer;
  border: 1px solid #888;
}

.thumbnail-language:hover {
  border: 1px solid #888;
  box-shadow: #ccc 0px 0px 10px;
  outline-color: orange !important;
}

.thumbnail-simple-flow:hover {
  border: 1px solid #005587;
  box-shadow: #00a1ff 0px 0px 10px;
}

.thumbnail-language.language-deprecated,
.thumbnail-language.language-locked {
  cursor: inherit;
}

.language-active,
.language-selected {
  border: 1px solid #dd9935;
}

.thumbnail-language:hover,
.thumbnail-language:focus,
.thumbnail-language.keyboard-selected {
  border: 1px solid #ffb700;
  box-shadow: #e80 0px 7px;
  transform: scale(1.03);
  transition: all 0.2s ease-in;
}

.language-locked > .caption,
.language-complete > .caption,
.realm-locked > .caption,
.realm-complete > .caption,
.level-locked > .caption,
.level-complete > .caption {
  opacity: 0.5;
}

.language-deprecated > .deprecated-symbol {
  position: absolute;
  top: 32px;
  left: 0px;
  height: 115px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  color: #aaaaaa;
  padding: 10px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.deprecated-symbol > .deprecated-info-icon {
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}

.deprecated-symbol > .deprecated-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 13px;
  line-height: 130%;
  color: #aaaaaa;
  margin: 0px 8px;
}

.deprecate-info > .tooltip-inner {
  background-color: #383c42;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  text-align: left;
}

.deprecate-info-text {
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
}

.language-locked > .lock-symbol,
.level-locked > .lock-symbol {
  position: absolute;
  top: 58px;
  left: 0px;
  right: 0px;
  font-size: 20px;
  z-index: 1;
  height: 42px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid #c9493a;
  border-bottom: 1px solid #c9493a;
  color: #c9493a;
  padding: 6px 0;
}

.language-locked > .not-yet-supported,
.level-locked > .not-yet-supported {
  position: absolute;
  top: 58px;
  left: 0px;
  right: 0px;
  font-size: 20px;
  z-index: 1;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid #ffb700;
  border-bottom: 1px solid #ffb700;
  color: #ffb700;
  padding: 6px 0;
}

.language-coming-soon > .coming-soon-symbol {
  position: absolute;
  top: 58px;
  left: 0px;
  right: 0px;
  font-size: 20px;
  z-index: 1;
  height: 42px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid #f38b00;
  border-bottom: 1px solid #f38b00;
  color: #f38b00;
  padding: 6px 0;
}

.coming-soon-symbol-learning {
  position: absolute;
  top: 58px;
  left: 0px;
  right: 0px;
  font-size: 14px;
  z-index: 1;
  height: 32px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid #f38b00;
  border-bottom: 1px solid #f38b00;
  color: #f38b00;
  padding: 6px 0;
}

.coming-soon-label-learning {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  height: 32px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f38b00;
  padding: 6px 0;
}

.poc-symbol {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 14px;
  z-index: 1;
  height: 24px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #006b94;
  padding: 1px 0px;
}

.simple-flow-poc-symbol {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 14px;
  z-index: 1;
  height: 24px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1px 0px;
}

.thumbnail.thumbnail-language .caption {
  padding: 5px;
}

.thumbnail-buttons {
  text-align: center;
}

.bigletter {
  text-align: center;
  height: 100px;
  width: 100px;
  font-size: 66px;
  font-family: 'Roboto', sans-serif;
  background-color: #272b30;
  margin: 20px 0 0 10px;
  color: #1c1e22;
  text-shadow:
    #999 1px 1px 1px,
    #000 -1px -1px 2px;
  border: 1px outset #555;
  transition: all 0.2s ease-in-out;
}

.thumbnail-level:hover .bigletter,
.thumbnail-assessment:hover .bigletter,
.thumbnail-level:focus .bigletter,
.thumbnail-level.keyboard-selected .bigletter {
  text-shadow:
    #999 1px 1px 1px,
    #000 -1px -1px 2px,
    #ccc 0px 0px 50px;
  box-shadow: #ccc 0 0 10px;
  outline: none !important;
}

.level-active:hover .bigletter {
  text-shadow:
    #dd9935 1px 1px 1px,
    #000 -1px -1px 2px,
    #dd9935 0px 0px 50px;
  border: 1px outset #ad7925;
  box-shadow: #dd9935 0 0 10px;
}

.big-language-icon {
  text-align: center;
  height: 140px;
  width: 140px;
  font-size: 96px;
  padding-top: 8px;
  font-family: 'Roboto', sans-serif;
  background-color: #272b30;
  margin: 13px auto 0 auto;
  color: #1c1e22;
  text-shadow:
    #999 1px 1px 1px,
    #000 -1px -1px 2px;
  border: 1px outset #555;
  transition: all 0.2s ease-in-out;
}

.big-simple-flow-icon {
  text-align: center;
  height: 140px;
  width: 140px;
  font-size: 96px;
  padding-top: 8px;
  font-family: 'Roboto', sans-serif;
  margin: 13px auto 0 auto;
  border: 1px solid rgba(0, 50, 80, 1);
  color: #2a6ea1;
  text-shadow:
    #999 1px 1px 1px,
    #000 -1px -1px 2px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

.thumbnail-language:hover .big-language-icon,
.thumbnail-language:focus .big-language-icon,
.thumbnail-language.keyboard-selected .big-language-icon {
  text-shadow:
    #999 1px 1px 1px,
    #000 -1px -1px 2px,
    #ccc 0px 0px 50px;
  box-shadow: #ccc 0 0 10px;
}

.thumbnail-simple-flow:hover .big-simple-flow-icon {
  text-shadow:
    #4683b2 1px 1px 1px,
    #084370 -1px -1px 2px,
    #6fa3cb 0px 0px 50px;
  box-shadow: #00a1ff 0px 0px 10px;
}

/*.language-active .big-language-icon {
	border: 1px outset #ad7925;
	text-shadow: #dd9935 1px 1px 1px, #000 -1px -1px 2px;
}*/

.language-active:hover .big-language-icon {
  text-shadow:
    #dd9935 1px 1px 1px,
    #000 -1px -1px 2px,
    #dd9935 0px 0px 50px;
  border: 1px outset #ad7925;
  box-shadow: #dd9935 0 0 10px;
}

.bigglyph {
  text-align: center;
  height: 120px;
  width: 120px;
  font-size: 72px;
  font-family: 'Roboto', sans-serif;
  background-color: #272b30;
  margin: 0 auto 0 auto;
  padding-top: 16px;
  color: #1c1e22;
  text-shadow:
    #999 1px 1px 1px,
    #000 -1px -1px 2px;
  border: 1px outset #555;
  transition: all 0.2s ease-in-out;
}

/*.language-active > .bigletter, .realm-active > .bigglyph, .level-active > .bigletter {
	background-color: rgba(222, 153, 54, 0.35);
}*/

.thumbnail-realm {
  padding: 15px;
}

.thumbnail-assessment-language {
  padding: 15px;
  overflow: visible;
}

.thumbnail-assessment-list-item {
  margin: 15px 0px;
  overflow: visible;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 10px;
}

.assessment-list-heading {
  padding: 10px 10px 0 10px;
  margin: 10px 0 0 0;
}

.assessment-list-heading h4 {
  width: auto;
  margin-top: 0 !important;
}

.assessment-list-item {
  padding: 5px 12px;
  margin: 10px;
  background: #262a2f;
  font-weight: bold;
}

.thumbnail-no-assessment {
  background: #333e48;
}

.assessment-name-label {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}

.level-grouping-label-container {
  margin-bottom: 10px;
}

.level-grouping-label {
  padding: 3px 8px;
  margin-left: 10px;
  height: 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #ccc;
  color: #000;
}

.font-weight-bold {
  font-weight: bold;
}

.realm-row {
  /*margin-bottom: 20px;*/
}

.realm-active {
  /*border: 1px solid #dd9935;*/
}

.realm-active .bigglyph {
  /*background-color: #5D4005;*/
  border: 1px outset #dd9935;
  text-shadow:
    #dd9935 1px 1px 1px,
    #000 -1px -1px 2px;
}

.realm-caption,
.assessment-language-caption {
  padding: 10px 15px !important;
}

.assessment-done-successstamp {
  font-size: 35px !important;
  width: 150px;
  text-align: center;
  position: absolute;
  right: 35px;
  top: 10px;
  color: #657d19;
  border-radius: 5px;
  padding: 0px 20px;
  text-transform: uppercase;
}

.assessment-done-successstamp small {
  font-size: 11px;
  z-index: 1;
  position: absolute;
  margin-top: -15px;
  right: -80px;
  width: 200%;
}

.assessment-done-successstamp-fail {
  color: #a00;
  border-color: #a00;
}

/*.realm-complete, .level-complete {
	border: 1px solid #657d19;
}*/

.edit-left {
  float: right;
  margin-left: 5px;
}

.thumbnail-level,
.thumbnail-assessment {
  height: 150px;
  cursor: pointer;
  border: 1px solid #888;
}

.thumbnail-assessment {
  float: left;
  width: 48%;
  margin: 1%;
}

.thumbnail-level.level-locked {
  cursor: inherit;
}

.thumbnail-level:hover,
.thumbnail-assessment:hover,
.thumbnail-level.keyboard-selected {
  border: 1px solid #e80;
  box-shadow: #e80 0px 7px;
  outline: none;
}

.level-active {
  border: 1px solid #dd9935;
}

.level-active:hover,
.thumbnail-level:focus,
.thumbnail-level.keyboard-selected {
  border: 1px solid #ffb700;
  border-radius: 5px;
  transform: scale(1.03);
}

.realm-heading,
.assessment-language-heading {
  margin-top: 0;
}

.realm-heading .glyphicon,
.reporting-title .glyphicon {
  font-size: 18px;
}

.realm-text,
.assessment-language-text {
  padding-left: 0;
  padding-right: 0;
}

.recent-actiity-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.timing-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.level-heading {
  margin-top: 12px;
}

.thumbnail-item-complete {
  position: absolute;
  top: 4px;
  right: 6px;
}

.thumbnail-item-active-complete {
  top: 22px;
}

.thumbnail-item-complete > img {
  height: 30px;
}

.thumbnail-assessment-language--emitsCertificate-checkbox {
  width: 12px;
  height: 12px !important;
  display: inline-block !important;
}

.active-pulse {
  -webkit-animation: padlock-pulse-yellow 1.2s infinite ease-in-out;
  animation: padlock-pulse-yellow 1.2s infinite ease-in-out;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.green-pulse {
  -webkit-animation: padlock-pulse-green 1.2s infinite ease-in-out;
  animation: padlock-pulse-green 1.2s infinite ease-in-out;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.red-pulse {
  -webkit-animation: padlock-pulse-red 1.2s infinite ease-in-out;
  animation: padlock-pulse-red 1.2s infinite ease-in-out;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

@-webkit-keyframes padlock-pulse-yellow {
  0%,
  100% {
    color: #ffb700;
  }
  50% {
    color: #ad6910;
  }
}

@keyframes padlock-pulse-yellow {
  0%,
  100% {
    color: #ffb700;
  }
  50% {
    color: #ad6910;
  }
}

@-webkit-keyframes padlock-pulse-red {
  0%,
  100% {
    color: #c9493a;
  }
  50% {
    color: #89392a;
  }
}

@keyframes padlock-pulse-red {
  0%,
  100% {
    color: #c9493a;
  }
  50% {
    color: #89392a;
  }
}

.thumbnail-assessment-info {
  padding: 10px 15px;
  overflow: visible;
}

.language-header-display {
  padding: 14px 0px 0 10px !important;
  margin: 0 !important;
}

.player-stats-language-select-form {
  padding: 5px 0;
}

.leaderboard-language-select-form {
  padding: 5px 15px !important;
}

.player-stats-language-select-form label {
  margin-bottom: 2px !important;
}

.player-stats-language-select-form .ui-select-container {
  border: 1px solid #666 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.player-stats-language-select-form .btn {
  border: 0 !important;
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}

.my-stats-form .btn {
  /*padding-left: 0 !important;*/
}

.player-stats-language-select-form .btn-default-focus {
  color: inherit !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.player-stats-language-select-form .ui-select-choices {
  color: inherit !important;
  background-color: #272b30 !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.player-stats-language-select-form .ui-select-bootstrap .ui-select-choices-row {
  background-color: rgba(0, 0, 0, 0.3);
}

.player-stats-language-select-form .ui-select-bootstrap .ui-select-choices-row > a,
.player-stats-language-select-form .ui-select-bootstrap .ui-select-choices-row > span {
  color: #c8c8c8;
}

.player-stats-language-select-form .ui-select-bootstrap .ui-select-choices-row.active > a,
.player-stats-language-select-form .ui-select-bootstrap .ui-select-choices-row.active > span {
  color: #fff;
}

.tournament-list .btn-default-focus {
  outline: none !important;
  box-shadow: none !important;
}

.tournament-list .tournament-company-selector {
  height: 50px;
}

.tournament-list-item-header {
  display: flex;
}

.tournament-list-item-description {
  overflow-wrap: break-word;
  white-space: pre-line;
}

.tournament-list-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
}

.tournament-list-item-glyph {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.tournament-list .btn-default {
  background-image: none;
  background-color: #101d2b !important;
  color: #c8c8c8 !important;
  border: 1px solid #1f628d !important;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.tournament-list .btn-default .ui-select-placeholder {
  color: #c8c8c8 !important;
}

.tournament-list-filter {
  text-align: right;
  margin-bottom: -30px;
  height: 80px;
}

.tournament-list .tournament-list-filter input,
.game-input {
  background-color: #101d2b !important;
  color: #c8c8c8 !important;
  border: 1px solid #1f628d !important;
  vertical-align: middle;
  padding: 0 5px;
}

.tournament-li {
  border-bottom: 1px solid #1f628d;
  padding: 20px 25px;
  margin: 0 -15px;
  min-height: 400px;
}

.tournament-li-selected,
.tournament-li.keyboard-selected {
  background-color: #172a3e;
  border: 1px solid #ffb700;
}

.tournament-ui-select {
  border: none;
  width: 30%;
  margin-right: 14px;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  border-radius: 0;
}

.tournament-list .ui-select-choices {
  color: inherit !important;
  background-color: rgba(0, 20, 40, 0.6) !important;
  border: 1px solid #1f628d !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
}

.tournament-list input[type='search'] {
  border-radius: 0;
  color: #101d2b;
}

.tournament-list .ui-select-bootstrap .ui-select-choices-row {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 20, 40, 0.6);
}

.tournament-list .btn-xs:hover {
  background-color: rgba(255, 183, 0, 0.6) !important;
  color: white !important;
}

.tournament-list .ui-select-bootstrap .ui-select-choices-row > a,
.tournament-list .ui-select-bootstrap .ui-select-choices-row > span {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.tournament-list .ui-select-bootstrap .ui-select-choices-row > a:hover,
.tournament-list .ui-select-bootstrap .ui-select-choices-row > span:hover,
.tournament-list .ui-select-bootstrap .ui-select-choices-row.active > span {
  background-color: #1f628d;
}

.tournament-list .ui-select-bootstrap .ui-select-choices-row.active > a:hover,
.tournament-list .ui-select-bootstrap .ui-select-choices-row.active > span:hover {
  background-color: #1f628d;
}

.tournament-list .ui-select-bootstrap .ui-select-choices-row.active > a,
.tournament-list .ui-select-bootstrap .ui-select-choices-row.active > span {
  color: #fff;
}

.alert-tournament {
  margin-top: 8px;
  padding: 4px;
  margin-bottom: -9px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.home-country-form label {
  margin-bottom: 2px !important;
}

.home-country-form .ui-select-container {
  border: 1px solid rgba(0, 50, 80, 1) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.home-country-form .btn {
  border: 0 !important;
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}

.home-country-form .btn-default-focus {
  color: #c8c8c8 !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.home-country-form .ui-select-match-text,
.home-country-form .ui-select-placeholder {
}

.home-country-form .ui-select-choices {
  color: inherit !important;
  background-color: rgba(0, 30, 50, 0.9) !important;
  border: 1px solid rgba(0, 50, 80, 1) !important;
  outline: none !important;
  box-shadow: none !important;
}

.home-country-form .ui-select-bootstrap .ui-select-choices-row {
  background-color: rgba(0, 0, 0, 0.3);
}

.home-country-form .ui-select-bootstrap .ui-select-choices-row > a,
.home-country-form .ui-select-bootstrap .ui-select-choices-row > span {
  color: #c8c8c8;
}

.home-country-form .ui-select-bootstrap .ui-select-choices-row.active > a,
.home-country-form .ui-select-bootstrap .ui-select-choices-row.active > span {
  color: #fff;
}

.set-home-button-div {
  text-align: right;
}

.player-stats-language-select-form > .form-group {
  margin-bottom: 0;
}

#full-width-language,
#full-width-name {
  width: 100%;
}

.advanced-options-accordion {
  background: #3a3f44;
  margin-top: 23px;
  margin-bottom: 23px;
  border-radius: 5px;
}

#formgroup-times {
  margin-top: 10px;
}

.formgroup-maxtime,
.formgroup-timezone {
  margin-top: 16px;
}

.show-advanced-options-accordion {
  border-bottom-style: solid;
  border-bottom-color: #3a3f44;
  margin-bottom: 20px;
}

.panel-title .icon-text,
.icon-content {
  display: inline;
}

.json-textarea {
  height: 300px !important;
}

.modal-dialog {
  margin-top: 60px !important;
}

.modal-link {
  margin: 10px 0;
  font-size: smaller;
}

.logo-img {
  max-width: 80px;
  max-height: 40px;
}

.users-list .user-pic {
  width: 32px;
  height: 32px;
}

.no-overflow {
  overflow: hidden;
}

.assessment-progress-glyph {
  margin: 0px 3px 0px 3px;
  font-size: 20px;
}

.title-language {
  text-align: center;
  margin-top: 43px;
  margin-bottom: 18px;
}

.show-language {
  text-align: center;
  margin-left: -19px;
  margin-top: -8px;
  font-size: 20px;
}

/* challenge history */
#challenge-results {
  overflow: hidden;
  -webkit-padding-start: 0px;
  -moz-padding-start: 0px;
  -o-padding-start: 0px;
  -ms-padding-start: 0px;
  padding-start: 0px;
  padding: 5px 2%;
  margin: 0;
}

#challenge-results li {
  list-style-type: none;
  font-size: 14px;
  position: relative;
  padding-bottom: 78px;
}

#challenge-results li.challenge-result-incomplete {
  padding-bottom: 10px;
}

#challenge-results > li > .glyphicon {
  font-size: 24px;
}

/*challenge-results connectors*/
/*
#challenge-results li:before {
	content: '';
	width: 2px;
	height: 56px;
	background: #dd9935;
	position: absolute;
	left: 9px;
	top: -4px;
}
#challenge-results li.challenge-result-incorrect:before {
	background: #c9493a;
}
#challenge-results li.challenge-result-correct:before {
	background: #657d19;
}
*/

#challenge-results li:first-child:before {
  /*connector not needed before the first step*/
  content: none;
}

#challenge-results li:first-child {
  /*padding not needed before the first step*/
  padding-top: 1px;
}

#challenge-results li:last-child {
  /*space for absolute positioned text*/
  padding-bottom: 78px;
}

#challenge-results li .challenge-result-text {
  vertical-align: top;
  margin-left: 12px;
  margin-top: 3px;
  display: inline-block;
  position: absolute;
  font-size: 14px;
  width: 90%;
}

.challenge-result {
  position: relative;
}

.challenge-result-stage-breakdown {
  margin-left: 10px;
  font-size: 12px;
  color: #888;
}

.challenge-result-final {
  position: absolute;
  bottom: 0px;
  right: 30px;
}

.category-removed-by-hint {
  text-decoration: line-through;
  color: #666 !important;
}

.invite-participant-button {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 15px;
  z-index: 1;
}

.assessment-back {
  position: absolute;
  top: 30px;
  left: -35px;
  padding: 4px 8px 2px;
  font-size: 17px;
  color: #ffb700;
}

.assessment-recent-activity-table {
  border: 0;
  background-color: transparent;
  width: 100%;
}

.assessment-recent-activity-table td {
  padding: 1px;
}

.assessment-recent-activity-btn {
  width: 145px;
}

.assessment-recent-activity-timestamp {
  color: #777;
  font-size: 10px;
  margin-top: 2px;
}

.assessment-recent-activity-btn-cell {
  padding-top: 10px !important;
}

.show-description {
  margin-bottom: 10px;
}

@media (max-width: 1500px) {
  .colour-box-small {
    width: 32.6%;
  }

  .colour-box-small-full {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .intro-splash {
    width: 100%;
  }

  .security-maturity-box {
    height: 411px;
  }

  .security-maturity-box h1 {
    font-size: 32px;
    margin-top: 0px;
  }

  .security-maturity-box-metrics > .relative.zoomable {
    height: 237px;
  }

  .security-maturity-box-metrics h1 {
    font-size: 32px;
    margin-top: 0px;
  }

  .hide-for-small-columns {
    display: none;
  }
}

@media (min-width: 992px) {
  .timepicker {
    margin-top: -34px;
  }

  .file-jumper {
    position: absolute;
    top: -30px;
    right: 0;
    z-index: 9999999 !important;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .intro-splash-pull-right {
    float: none;
  }

  .colour-box {
    width: 100%;
    margin: 5px 0 5px 0;
  }

  .colour-box-small {
    width: 100%;
  }

  .colour-box-text-h {
    position: absolute;
  }

  .colour-box-text-h2 {
    position: absolute;
    top: 60px;
  }

  .security-maturity-box-metrics {
    height: 532px;
  }

  .security-maturity-box-metrics > .relative.zoomable {
    height: 365px;
  }

  .hide-for-small-columns {
    display: initial;
  }

  .timepicker {
    margin-top: initial;
  }

  .file-jumper {
    position: absolute;
    top: 5px;
    right: 15px;
  }
}

@media (max-width: 768px) {
  body {
    margin-top: 0px;
    padding-top: 0px;
  }

  .loading {
    position: absolute !important;
    top: 10px;
    right: 0px;
    width: 50px;
    height: 30px;
    z-index: 100;
    cursor: default;
  }

  .loading-text {
    position: absolute !important;
    top: 0px;
    right: 75px;
    margin-top: 18px;
    margin-right: 10px;
    font-size: 11px;
    text-align: right;
    cursor: default;
  }

  .security-maturity-box-metrics > .relative.zoomable {
    height: auto;
  }
}

.padding-right-12 {
  padding-right: 12px !important;
}

.stage-points-table {
  background: rgba(0, 0, 0, 0.25) !important;
}

.stage-points-table td {
  border: 0 !important;
}

.stage-points-table th {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0.4);
}

.add-assessment {
  margin-top: 15px !important;
}

.start-assessment {
  float: right;
}

.edit-assessment {
  position: relative;
}

.assessment-disabled {
  position: absolute;
  top: 40px;
  right: 15px;
}

.assessment-info-table {
  background-color: transparent;
}

.assessment-info-table > tbody > tr > th,
.assessment-info-table > tbody > tr > td {
  padding: 2px 20px 2px 0px;
}

.assessment-results-table th,
.assessment-results-table td {
  padding: 5px 20px 2px 0px;
  text-align: center;
}

.assessment-info-table > tbody > tr > th:last-child,
.assessment-info-table > tbody > tr > td:last-child {
  padding: 2px 0px 2px 0px;
}

.assessment-results-table td:last-child {
  padding: 5px 0px 2px 0px;
}

.assessment-results-table th:last-child {
  padding: 15px 0px 2px 0px;
}

.assessment-candidate-avatar {
  position: absolute;
  top: 25px;
  right: 14px;
}

.assessment-results-table {
  background-color: transparent;
  width: 100%;
  margin-bottom: 30px;
}

.assessment-results-table > tbody > tr > th {
  padding-top: 15px;
  border-bottom: 1px solid #555;
}

.assessment-status-count {
  font-size: 18px;
}

#assessment-tabs {
  margin-top: 20px;
}

.view-language {
  margin-top: 20px;
  padding: 15px;
  overflow: visible;
  /*text-align: center;*/
}

#assessments-view-container .assessment-hr {
  margin-top: 0px;
  border: 1px solid #999;
  width: 100%;
}

#assessments-view-container h2.assessment-title {
  margin-right: 350px;
}

.title-name {
  display: inline;
  margin-right: 10px;
}

.languageName {
  display: inline;
  font-size: 20pt;
  font-weight: bold;
}

.challenge-explanation {
  background: rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-top: 10px;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 30pt;
}

.challenge-explanation-thumbs {
  position: absolute;
  right: 0px;
  bottom: -45px;
  padding: 5pt 12pt;
  /* 	background: rgba(255,255,255,0.2); */
  border-radius: 3pt 0 0 0;
  color: white;
  text-transform: uppercase;
  font-size: 20px;
}

.challenge-explanation-thumbs.done {
  position: absolute;
  transform: scale(2);
  opacity: 0;
  transition: all 0.5s ease-out;
}

.challenge-explanation-thumbs .rating-label {
  font-size: 14px;
  color: lightgray;
  position: fixed;
  margin-left: -200px;
  margin-top: 6px;
  display: none;

  /* 	display: none; */
}

.points-earned-bar {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
  position: relative;
}

.points-earned-bar span {
  position: absolute;
  top: 1px;
  left: 5px;
}

.points-penalty-list {
  color: #c9493a;
  font-size: 11px;
}

.full-points-list {
  color: #dd9935;
  font-size: 11px;
}

.save-assessment-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.remove-assessment-challenge-btn {
  position: absolute;
  top: 8px;
  right: 15px;
}

.select2-container .select2-choice {
  height: 34px;
  line-height: 34px;
}

.select2-container-multi .select2-choices .select2-search-choice {
  line-height: 18px;
}

.select2-container-multi .select2-search-choice-close {
  top: 6px;
}

.select2-container .select2-choice abbr {
  top: 8px;
}

.select2-container .select2-choice .select2-arrow b {
  margin-top: 1px;
}

.apptype-language-list {
  margin-left: 10px;
  font-size: 10px;
}

.assessment-superseded {
  font-size: 11px;
}

.auth-wide-button {
  width: 100%;
  margin: 0 6px 0 0;
}

.auth-button {
  margin: 0;
}

.auth-button-group {
  margin-top: 30px;
}

.modal-section {
  margin: 10px 0 5px 0;
  padding: 20px;
  border-top: 1px solid #454545;
  clear: both;
}

.assessment-score-container {
  margin: 0px 0px 16px 0px;
  text-align: center;
  font-size: 48px;
}

.easy-pie-assessment-score {
  background-color: transparent;
  left: inherit;
  width: 150px;
  height: 110px;
}

.easy-pie-assessment-score-text {
  line-height: 110px;
}

.easy-pie.easy-pie-assessment-score canvas {
  top: 0px;
}

.easy-pie-assessment-score-text.dull-yellow-text {
  color: #dd9935;
}

.easy-pie-assessment-score-text.red-text {
  color: #c9493a;
}

.easy-pie-assessment-score-text.green-text {
  color: #657d19;
}

.yellow-text .unlimited-license {
  margin-right: 114px;
}

.unlimited-license {
  display: inline-block;
  margin-right: 5px;
}

/*** Start Angular CSP ***/

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

ng\:form {
  display: block;
}

.ng-animate-shim {
  visibility: hidden;
}

.ng-anchor {
  position: absolute;
}

/*** End Angular CSP ***/

.unassigned-user-box {
  background: #eee;
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.unassigned-user-box .img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border: 1px solid #ccc;
  background: #f5f5f5;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.form-heading-subtext {
  font-weight: initial;
  font-size: smaller;
}

.progress-bar-success {
  background-color: #3c9f40;
}

.progress-bar-warning {
  background-color: #dd9935;
}

.progress-bar-danger {
  background-color: #c9493a;
}

.accuracy-summary-progressbar {
  opacity: 0.75;
}

table:not(.MuiTable-root) {
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
}

table tbody tr.keyboard-selected {
  background: #fff;
  color: #333 !important;
  outline: none;
}

table tbody tr.keyboard-selected a {
  color: inherit;
  text-decoration: underline;
}

table tbody tr.keyboard-selected td:first-of-type {
  border-left: 3px solid #e80 !important;
}

.nvtooltip {
  background-color: #111;
  color: #ddd;
}

.nvtooltip table {
  table-layout: auto;
}

.non-sortable-header.non-sortable-header-assessments {
  width: 110px;
}

.non-sortable-header.non-sortable-header-admin {
  width: 220px;
}

.non-sortable-header tr th {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.license-progressbar {
  height: 5px;
  margin-bottom: 5px;
  width: 90%;
}

.license-inherited-text {
  margin-left: 10px;
  font-size: 11px;
  text-transform: uppercase;
}

/* Apply different styling to nested legend to differentiate between headings */
.company_preferences fieldset fieldset legend {
  font-size: 18px;
  margin-bottom: 0;
  border-bottom: none;
}

.invite-selected-btn {
  text-align: right;
  margin-top: 6px;
  margin-bottom: 6px;
}

.assessment-template-section {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #0c0d0e;
}

.formgroup-selfAssess {
  top: -19px;
}

.admin-tutorial-button {
  position: absolute;
  top: 0;
  right: 0;
}

.ng-animate .tutorial-button {
  visibility: hidden !important;
}

/* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.license-expiration-warning {
  font-size: 16px;
  position: fixed;
  z-index: 999;
  background-color: #d00;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #aaa;
  bottom: 15px;
  left: 30px;
}

.dev-leaderboard-switcher {
  font-weight: normal;
  font-size: 14px;
}

.dev-leaderboard-switcher-csv-download {
  border-radius: 4px 0 0 4px !important;
  text-decoration: initial !important;
}

.dev-leaderboard-switcher-csv-download-link {
  border-radius: 4px 0 0 4px !important;
  text-decoration: initial !important;
  & md-icon {
    padding-left: 8px;
  }
}

.border-radius-left {
  border-radius: 4px 0 0 4px;
}

.border-radius-right {
  border-radius: 0 4px 4px 0;
}

.dev-leaderboard-switcher-csv-download-icon {
  border-radius: 0 4px 4px 0 !important;
}

.admin-csv-download {
  font-size: 24px;
}

.bold {
  font-weight: bold;
}

.inline-block-form {
  display: inline-block;
}

.metrics-recalc-stats {
  margin-top: 20px;
}

.metrics-recalc-stats:hover {
  margin-top: 20px;
}

select {
  color: #333;
}

.leaderboard-pagination {
  margin-bottom: 5px;
}

.leaderboard-items-per-page {
  margin-bottom: 20px;
}

.assessment-list-filter {
  margin: 5px 0 10px 0;
}

.assessment-list-filter input {
  border-radius: 5px !important;
  border: 0px !important;
  padding: 0px 7px;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.btn-container {
  padding-top: 15px;
}

.certificate-templates-list {
  padding: 25px 15px 25px 15px !important;
  background: #000;

  border-radius: 5px;
}

.certificate-template-boxed {
  background: #000;
  border: 2px solid #333;
  padding: 0px 25px 15px 25px;
  margin-bottom: 20px;
  height: 220px;
}

.certificate-template-boxed-options {
  margin-top: auto;
  display: flex;
}

.certificate-template-boxed:hover {
  background: #333;
  border-bottom: 2px solid #59f;
  transition: all 0.2s ease-in-out;
}

.certificate-template-boxed-options a {
  margin: 10px;
}

.certificate-template-boxed-options small > a:first-child {
  margin-left: 0px;
}

.btn-link {
  text-decoration: none !important;
}

.btn-link i.glyphicon {
  top: 3px;
}

.text-link {
  color: #1ad9e5;
  text-decoration: underline;
}

.select2-container-disabled {
  opacity: 0.8 !important;
}

small input[type='checkbox'] {
  position: relative;
  top: 3px !important;
}

small .glyphicon {
  top: 2px;
}

.bsod-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.bsod-overlay {
  font-family: 'Lucida Console', 'Consolas', 'Lucida Sans Typewriter', monospace;
  background-color: #0000aa;
  position: fixed;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  color: #cccccc;
  z-index: 2000;
  font-size: 16px;
  overflow: auto;
}

.bsod-overlay-fullscreen {
  margin: -30px;
}

.bsod-container {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 670px;
  transform: translateX(-50%) translateY(-50%);
}

.bsod-header {
  text-align: center;
  margin-bottom: 10px;
}

.bsod-h1 {
  background-color: #aaaaaa;
  color: #0000aa;
  margin: auto;
  font-weight: bold;
  padding: 5px;
}

.bsod-btn {
  background-color: #aaaaaa;
  color: #0000aa;
}

.bsod-table {
  border: 0;
  background-color: transparent;
}

.bsod-table td {
  padding: 3px;
}

.bsod-logo,
.bsod-logo-fullscreen {
  display: none;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  text-align: center;
  margin-bottom: 30px;
}

@media (min-height: 700px) {
  .bsod-logo {
    display: block;
  }
}

@media (min-height: 650px) {
  .bsod-logo-fullscreen {
    display: block;
  }
}

.leaderboard-language {
  display: inline;
  font-size: 28px;
  width: 28px;
}

.leaderboard-language .leaderboard-language-tinyprogress {
  width: 28px;
  height: 7px;
}

.leaderboard-language-stacked {
  font-size: 28px;
}

.leaderboard-language-stacked .progress {
  margin: 8px 0 0 4px;
  background-color: #aaaaaa;
}

.leaderboard-language-stacked .progress > .progress-bar {
  white-space: nowrap;
}

.leaderboard-language-stacked .progress > .progress-bar > span {
  word-wrap: initial;
}

.leaderboard-language-icon-col {
  text-align: center;
}

.leaderboard-language > .progress,
.leaderboard-language-stacked > .progress {
  margin: 0;
}

.leaderboard-tag {
  margin-left: 5px;
  font-size: 100%;
}

.leaderboard-team {
  background-color: #20618d;
  font-size: 100%;
}

.metrics-questprogress-box {
  padding: 0 0 20px 0;
  margin-top: 5px;
}

.metrics-questprogress-h4 {
  color: #fff;
  font-size: 16px;
  padding: 12px 20px;
  margin: 0px 0px 20px 0px;
  background-color: #000;
}

.metrics-questprogress-list {
  color: #fff;
  font-size: 32px;
}

.metrics-questprogress-list .progress {
  border: 0;
  margin: 10px 0 0 0;
}

.metrics-questprogress-list .panel-group .panel {
  margin: 5px 20px 0 20px;
  background-color: inherit;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.metrics-questprogress-list .panel-group .panel .panel-heading {
  background-color: inherit;
  color: #fff;
}

.metrics-questprogress-thin-progress {
  height: 5px;
}

.metrics-questprogress-questlist {
  padding: 0px 35px;
}

.metrics-questprogress-quest:first-child {
  margin-top: 0px;
}

.metrics-questprogress-quest {
  font-size: 15px;
  margin-top: 15px;
}

.metrics-questprogress-list .accordion-toggle {
  outline: 0;
  color: inherit;
  text-decoration: inherit;
}

.metrics-questprogress-list .metrics-questprogress-devicon {
  font-size: 32px;
}

.metrics-questprogress-expandarrow {
  font-size: 11px;
}

.table-sorter {
  font-size: 11px;
}

.pickVulnOption {
  float: left;
  width: 49%;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #555;
  border-radius: 5px;
  margin: 0 2% 2% 0;
  cursor: pointer;
  position: relative;
}

.pickVulnOption:nth-child(2n + 2) {
  margin: 0 0% 2% 0;
}

#pickVulnAnswerFormInputs {
  margin: 0 0 5px !important;
}

.pickVulnOption:hover {
  background: #333;
  transition: all 0.2s ease-in-out;
}

.pickVulnOption.selected h5:after {
  content: '\2714';
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 10px;
}

.pickVulnOption.selected {
  background: #333;
  border: 1px solid orange;
  transition: all 0.2s ease-in-out;
}

.pickVulnOption.invalidated {
  display: inline-block !important;
  border: 1px solid #f22;
  transition: all 0.2s ease-in-out;
  cursor: not-allowed !important;
}

.pickVulnOption.invalidated h5,
.pickVulnOption.invalidated h6 {
  color: #f22;
}

.pickVulnOption.invalidated h5:after {
  content: '\2718';
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 10px;
}

.pickVulnOption .video-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 24px;
  z-index: 5;
}

.capitalize {
  text-transform: capitalize;
}

.assessments-credits-container {
  position: relative;
  top: 0px;
  color: #ffb700;
  background: #000;
  margin-top: 0px;
  box-shadow: 0px 0px 10px #ffb700;
  font-size: 11pt;
  padding: 5px 10px;
  border: 2px solid orange;
  border-radius: 5px;
  text-transform: uppercase;
}

.assessments-credits-unlimited {
  background: green;
  font-weight: bold;
  border: none;
}

.assessments-credits-critical {
  background: #e55;
  color: white;
  font-weight: bold;
  border: none;
}

.assessments-credits-critical span.glyphicon {
  font-weight: 300 !important;
  position: relative;
  left: -5px;
}

.progress-carousel-heading {
  padding: 0 20px;
}

.logo-cell {
  background-color: #333e48;
  width: 80px;
  height: 100%;
  display: inline-block;
  text-align: center;
  padding: 3px 0 !important;
}

ul[datepicker-popup-wrap] {
  width: 250px;
  left: initial !important;
}

.alert {
  margin-top: 8px;
}

.tournament-back {
  position: absolute;
  top: 24px;
  left: -50px;
  padding: 4px 8px 2px;
  font-size: 17px;
  color: #ffb700;
}

.tournament-list-empty {
  height: 440px;
  font-size: 28px;
  padding-top: 55px;
  text-align: center;
}

.tournament-list-empty-terminal {
  position: absolute;
  text-align: left;
  top: 0;
  right: 0;
  font-size: 6px;
  white-space: pre;
}

.tournament-list-empty-terminal-non-absolute {
  white-space: pre;
  font-size: 6px;
  margin: 0px auto 10px auto;
  width: 180px;
  text-align: left;
}

.tournament-list-empty-terminal-top {
  color: #c8c8c8;
  font-size: 12px;
}

.nav-tabs {
  border: 0;
  cursor: initial;
}

.nav-tabs > li.add-sso-group-tab > a {
  padding: 0;
}

.nav-tabs > li.add-sso-group-tab > a span {
  padding: 12px 15px 13px 15px;
  transition: padding 0.2s ease-in-out;
}

.nav-tabs > li.add-sso-group-tab > a:hover span {
  padding-bottom: 19px;
}

.tournament-tabs .nav-tabs > li.active > a,
.tournament-tabs .nav-tabs > li.active > a:hover,
.tournament-tabs .nav-tabs > li.active > a:focus,
.tournament-edit-tabs .nav-tabs > li.active > a,
.tournament-edit-tabs .nav-tabs > li.active > a:hover,
.tournament-edit-tabs .nav-tabs > li.active > a:focus {
  background-color: #101d2b;
  border: 1px solid #1f628d;
  border-bottom: 1px solid #101d2b;
  color: #ffb700;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
}

.tournament-tabs .nav-tabs > li > a,
.tournament-edit-tabs .nav-tabs > li > a {
  border-radius: 0;
  border: 1px solid transparent;
  color: #c8c8c8;
}

.tournament-tabs .nav-tabs > li > a:hover,
.tournament-tabs .nav-tabs > li > a.keyboard-selected,
.tournament-edit-tabs .nav-tabs > li > a:hover {
  background-color: #101d2b;
  border: 1px solid #1f628d;
  border-bottom: 1px solid #101d2b;
  outline: none;
}

.tournament-tabs .tab-pane {
  min-height: 400px;
  margin: 0 auto;
  background-color: rgba(0, 20, 40, 0.6);
  padding: 0px 15px;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  border: 1px solid #1f628d;
  border-radius: 0;
}

.tournament-tabs .tab-pane > .carousel,
.tournament-tabs .tab-pane > .carousel > .carousel-inner,
.tournament-tabs .tab-pane > .carousel > .carousel-inner > .item,
.tournament-tabs .tab-pane > .carousel > .carousel-inner > .item > .tournament-tabs-carousel-item-inner,
.tournament-tabs .tab-pane > .carousel > .carousel-inner > .item > .tournament-tabs-carousel-item-inner > .row,
.tournament-tabs .tab-pane > .carousel > .carousel-inner > .item > .tournament-tabs-carousel-item-inner > .row > div {
  min-height: 438px;
}

.tournament-edit-tabs .tab-pane {
  margin: 0 auto;
  background-color: rgba(0, 20, 40, 0.6);
  padding: 15px 0;
  font-size: 14px;
  border: 1px solid #1f628d;
  border-radius: 0px 5px 5px 5px;
}

.tournament-list .carousel-indicators {
  bottom: 0px;
}

.tournament-list .carousel-control {
  width: 5%;
  left: -6%;
}

.tournament-list .left.carousel-control {
  filter: inherit;
  background-repeat: no-repeat;
  background-image: inherit;
  cursor: pointer;
}

.tournament-list .right.carousel-control {
  left: auto;
  right: -6%;
  filter: inherit;
  background-repeat: no-repeat;
  background-image: inherit;
  cursor: pointer;
}

.tournament-list-info {
  text-align: left;
  padding: 40px 20px;
}

.tournament-list-info-inner {
  vertical-align: middle;
  display: inline-block;
  font-size: 16px;
  width: 100%;
}

.tournament-list-info-inner .feedback-panel > input,
.tournament-join-password-value > input {
  width: 80%;
  color: #c8c8c8;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px dotted #1f628d;
  box-shadow: none;
  margin-bottom: 20px;
}

.tournament-join-password-value > input {
  color: #ffb700;
}

.tournament-edit-form label {
  font-size: 16px;
}

.tournament-edit-form .form-control[disabled] {
  background-color: rgba(255, 255, 255, 0.1);
}

.tournament-edit-form .form-control,
.tournament-edit-form .select2-choice,
.tournament-edit-form .select2-choices {
  color: #c8c8c8;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  background-image: none;
}

.tournament-edit-form .form-control {
  border-bottom: 1px solid #666;
}

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: transparent;
  background-image: none;
  border: 0;
}

.tournament-edit-form .form-group-top-spaced {
  margin-top: 80px;
}

.tournament-edit-form .form-group-first-top-spaced {
  margin-top: 20px;
}

.tournament-edit-form .input-group-addon {
  padding: 0;
  background-color: initial !important;
  background-image: initial !important;
  border-bottom: 1px solid #666;
}

.tournament-edit-form .select2-arrow {
  background-color: initial !important;
  background-image: initial !important;
  border: 0 !important;
}

.tournament-edit-form .select2-search {
  margin-top: 5px;
}

.tournament-edit-form #challenge-results li .challenge-result-text {
  margin-top: 0;
}

.tournament-edit-form .challenge-result-stage-breakdown {
  margin-top: 2px;
}

.tournament-edit-form .tournament-quest-log-stage-info-container {
  margin: 1px 0;
}

.tournament-edit-form .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}

.scoring-config-raw-preview {
  border: 1px solid #1f628d;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 15px 0;
  padding: 15px;
}

.scoring-config-label {
  margin: 20px 0 0 0;
}

.config-explanation {
  margin-bottom: 10px;
  color: #999999;
}

.config-explanation-adversary {
  color: #999999;
  padding: 10px;
}

.scoring-example-table {
  border: 0;
  background: transparent;
}

.scoring-example-table td {
  padding: 5px;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}

.tournament-displayPic {
  padding: 20px;
  text-align: center;
}

.tournament-displayPic img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.tournament-displayPic-placeholder {
  font-size: 240px;
  vertical-align: middle;
  color: #ffb700;
}

.tournament-leaderboard-options p {
  margin: 8px 0 0 0;
}

.vertical-centred-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.drop-box {
  border: 2px dashed #cccccc;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.tournament-add-drop-box {
  width: 340px;
  height: 300px;
  margin: 8px auto;
}

.tournament-add-donut {
  height: 180px;
  top: -20px;
  float: left;
  margin-left: 30px;
}

.tournament-add-donut .donut-text {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 58%;
  left: 0;
  margin-top: 0;
  margin-left: 0;
  transform: translateY(-75%);
  -ms-transform: translateY(-75%);
  -moz-transform: translateY(-75%);
  -o-transform: translateY(-75%);
  -webkit-transform: translateY(-75%);
}

.tournament-add-donut .donut-text-upper {
  font-weight: 300;
  font-size: 35px;
  font-family: 'Roboto', sans-serif;
  line-height: 35px;
  color: rgba(255, 183, 0, 1);
}

.tournament-add-donut .donut-text-under {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  color: rgba(255, 255, 255, 0.8);
}

.tournament-add-apptype {
  margin-left: 15px;
  color: #ffb700;
}

.drop-box.dragover-error {
  border: 2px dashed #c9493a;
}

.displayPic-preview {
  max-height: 300px;
  max-width: 300px;
}

.displayPic-preview-col {
  position: relative;
}

.displayPic-cell {
  display: inline-block;
  text-align: center;
  padding: 3px 0 !important;
  vertical-align: top;
  width: 100%;
}

.displayPic-remove-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.tournament-edit-form .timepicker {
  width: initial;
  background-color: transparent;
  margin-bottom: -36px;
  float: right;
}

.tournament-edit-form .timepicker button {
  font-size: 14px;
  padding: 1px 10px;
  border: 2px solid #1f628d;
  background-color: rgba(0, 20, 40, 0.6);
  border-radius: 0;
  background-image: initial;
}

.tournament-form-options {
  margin-bottom: 8px;
}

.assessment-edit-form .timepicker {
  width: initial;
  background-color: transparent;
  margin-bottom: -36px;
  float: right;
}

.assessment-edit-form .timepicker button {
  font-size: 14px;
  padding: 1px 10px;
  border: 2px solid #bcbcbc;
  background-color: #34383d;
  border-radius: 0px;
  background-image: initial;
  margin-left: 5px;
}

.thumbnail-tournament-join-password {
  margin: 0 auto;
  background-color: rgba(0, 20, 40, 0.6);
  font-size: 14px;
  border: 1px solid #1f628d;
  border-radius: 0;
  text-align: center;
  padding: 150px 0;
}

.tournament-join-password-text {
  font-size: 24px;
}

.tournament-join-password-value {
  font-family: 'Inconsolata', monospace;
  font-size: 48px;
}

.tournament-join-password-close {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 20px;
}

.tournament-join-password-hide {
  position: absolute;
  bottom: 60px;
  right: 15px;
}

.tournament-list-join-password-heading {
  font-size: 36px;
}

.tournament-list-join-password-text {
  padding-top: 65px;
}

.tournament-participants {
  padding: 15px 30px;
}

.tournament-view-status {
  position: absolute;
  top: 44px;
  right: 15px;
  font-weight: bold;
  font-size: 16px;
}

.tournament-advanced-settings-accordion > .panel-group > .panel {
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: 0;
  border-radius: 0;
}

.tournament-advanced-settings-accordion > .panel-group > .panel-open {
  border: 1px solid #1f628d;
}

.tournament-advanced-settings-accordion > .panel-group > .panel > .panel-heading {
  display: none;
}

.tournament-advanced-settings-accordion > .panel-group > .panel > .panel-heading a:hover {
  text-decoration: none;
  color: #dd9935 !important;
}

.tournament-advanced-settings-accordion > .panel-group > .panel > .panel-heading a:focus {
  text-decoration: none;
  color: #c8c8c8;
}

.tournament-advanced-settings-accordion > .panel-group > .panel > .panel-heading .glyphicon {
  font-size: 14px;
}

.tournament-advanced-settings-accordion > .panel-group > .panel > .panel-collapse > .panel-body {
  border: 0;
}

.tournament-leaderboard-options {
  border: 1px solid #666;
  border-radius: 6px;
  padding: 15px;
  margin-top: 30px;
}

.tournament-leaderboard-options-table {
  table-layout: initial;
  width: initial;
  margin: 0 auto;
  background-color: transparent;
}

.tournament-leaderboard-options-table td {
  padding: 5px 30px;
}

.tournament-leader-thumbnail {
  margin-right: 10px;
  object-fit: contain;
  -o-object-fit: contain;
}

.full-width-black .bg-worldmap {
  z-index: 0;
}

.clear-unclickable-layer {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.tournament-live-status-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tournament-leaderboard-row {
  background-color: rgba(0, 20, 40, 0.6);
  border-top: 1px solid #1f628d;
  border-bottom: 1px solid #1f628d;
  margin: 10px 0 10px 0;
  white-space: nowrap;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tournament-leaderboard-row-me {
  border-top: 1px solid #ffb700;
  border-bottom: 1px solid #ffb700;
}

.tournament-leaderboard-row .progress {
  background-color: #001225;
  border-radius: 0;
  border: 1px solid #1f628d;
  overflow: visible;
  white-space: nowrap;
  padding: 3px;
  margin: 0 10px;
}

.tournament-leaderboard-row .progress-bar-info {
  background-color: #003050;
}

.tournament-leaderboard-row .progress-bar-success {
  background-color: #003010;
}

.tournament-leaderboard-row .progress-bar-warning {
  background-color: #564821;
}

.tournament-leaderboard-row .progress-bar-danger {
  background-color: #54170b;
}

.tournament-live-status-back {
  margin: auto;
  padding: 15px 15px 15px 0;
  font-size: 17px;
  color: #ffb700;
}

.tournament-live-status-back a {
  text-decoration: none;
}

#tournament-live-status-title {
  margin-bottom: 20px;
}

.tournament-leaderboard-languge-text {
  margin: 10px 0;
}

.tournament-leaderboard-language-icon {
  font-size: 16px;
}

.tournament-leaderboard-rank {
  font-size: 32px;
}

.tournament-leaderboard-data {
  font-size: 18px;
  font-weight: bold;
  word-break: break-all !important;
  white-space: normal !important;
  padding: 1.6rem 0.8rem 1.6rem;
}

.tournament-leaderboard-heading {
  text-transform: capitalize;
  padding: 1.6rem 0.8rem 1.6rem;
}

.tournament-leaderboard-narrow-column {
  width: 8%;
}

.tournament-leaderboard-rank-column {
  text-align: left !important;
}

@media (max-width: 768px) {
  .tournament-leaderboard-points {
    text-align: center !important;
  }
}

.tournament-leaderboard-gears {
  display: flex;
  flex-flow: row;
}

.tournament-leaderboard-other {
  font-size: 30px;
}

.tournament-leaderboard-autoscroll {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  margin-right: 30px;
}

.tournament-leaderboard-autoscroll label {
  margin-right: 20px;
  margin-top: 5px;
  white-space: nowrap;
}

.tournament-leaderboard-other-stat {
  -webkit-transition: 0.7s ease-in-out;
  -moz-transition: 0.7s ease-in-out;
  -o-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tournament-leaderboard-other-stat-active {
  opacity: 1;
}

.tournament-leaderboard-other-stats-text {
  font-size: 12px;
  position: relative;
  bottom: 10px;
}

.tournament-leaderboard-other-stats {
  font-size: 20px;
}

.tournament-leaderboard-superscript {
  font-size: 12px;
  vertical-align: top;
  line-height: 30px;
}

.tournament-leaderboard-avatar-column {
  padding-left: 30px;
}

.tournament-leaderboard-avatar {
  margin-right: 15px;
  margin-top: 2px;
}

.tournament-leaderboard-quest-progress {
  display: flex;
  justify-content: space-between;
}

.tournament-leaderboard-quest-progress .carousel {
  width: 90%;
}

.tournament-leaderboard-quest-progress .carousel {
  width: 90%;
}

.tournament-leaderboard-quest-progress .carousel > .carousel-indicators,
.tournament-leaderboard-quest-progress .carousel > .carousel-control {
  display: none;
}

.tournament-actions {
  text-align: right;
  z-index: 10;
  padding-top: 64px;
  padding-left: 0px;
}

.tournament-actions .form-control {
  width: 150px !important;
  height: 27px;
  margin-top: 10px;
  margin-right: 5px;
  color: #c8c8c8;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px dotted #888;
  box-shadow: none;
  background-image: initial;
  padding: 0 12px;
}

.tournament-actions .form-control[disabled] {
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-tournament {
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  padding: 1px 10px;
  border: 2px solid #1f628d;
  background-color: rgba(0, 20, 40, 0.6);
  border-radius: 0;
}

.btn-tournament > .glyphicon {
  font-size: 12px;
}

.tournament-participants .btn-tournament > .glyphicon {
  font-size: 11px;
}

.btn-tournament:focus,
.btn-tournament:hover,
.btn-tournament.active {
  border: 2px solid #1f628d;
  background: #1f628d;
}

.btn-tournament-action {
  width: 132px;
  margin-top: 10px !important;
  white-space: normal;
}

.btn-tournament-action-highlight,
.btn-tournament-action-highlight:focus,
.btn-tournament-action-highlight:hover {
  border: 2px solid #c9493a;
}

.btn-tournament-action-show {
  width: auto;
}

.btn-tournament-action-highlight:focus,
.btn-tournament-action-highlight:hover {
  background-color: #c9493a;
}

.btn-tournament-advanced-settings {
  margin-bottom: 10px;
}

.tournament-spots-remaining-joincode {
  right: 162px !important;
  bottom: 70px !important;
}

.tournament-spots-remaining {
  position: absolute;
  right: 162px;
  bottom: 33px;
  text-align: right;
}

.tournament-big-countdown {
  color: #fff;
  background-color: rgba(86, 1, 1, 0.5);
  padding: 10px 25px 25px 25px;
  border-top: 1px solid #af0000;
  border-bottom: 1px solid #af0000;
}

.tournament-big-countdown-green {
  background-color: rgba(1, 86, 1, 0.4);
  border-top: 1px solid #00af00;
  border-bottom: 1px solid #00af00;
}

.tournament-small-countdown {
  position: absolute;
  top: 92px;
  right: 30px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid white !important;
  border-left: 2px;
  border-right: 2px;
  padding: 0px 10px;
  color: #fff;
}

.tournament-small-countdown-alt {
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid white !important;
  border-left: 2px;
  border-right: 2px;
  padding: 0px 10px;
  color: #fff;
  white-space: nowrap;
}

.tournament-status {
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: bold;
  font-size: 16px;
}

.tournament-leaderboard-header {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap-reverse;
  text-transform: capitalize;
}

.tournament-leaderboard-header-left {
  display: flex;
  justify-content: left;
}

.tournament-leaderboard-header-right {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.tournament-leaderboard-countdown {
  display: flex;
  align-items: center;
}

.tournament-worldmap {
  overflow: hidden;
}

.tournament-worldmap-info {
  font-size: 16px;
  position: absolute;
  left: 10px;
  top: 64px;
  z-index: 100;
  cursor: pointer;
}

.tournament-worldmap .worldmap-mute {
  left: 10px;
  top: 90px;
}

.tournament-worldmap .worldmap-overlay {
  max-width: 60%;
}

.tournament-worldmap .worldmap-overlay-hidden {
  left: -60%;
}

.tournament-worldmap-displaypic {
  max-width: 60px;
  max-height: 68px;
  margin-bottom: 6px;
}

.tournament-worldmap-points-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
}

.tournament-worldmap-points-container .worldmap-stats-points {
  font-size: 18px;
  margin-bottom: 2px;
}

.tournament-worldmap-alt-pic {
  font-size: 56px !important;
  margin-top: 5px;
}

.tournament-worldmap-tabs {
  height: 100%;
  overflow: hidden;
}

.tournament-worldmap .worldmap-dialog {
  border: 0;
}

.tournament-worldmap-tabs > .nav-tabs {
  position: absolute;
  top: -41px;
  background: #101d2b;
}

.tournament-worldmap-tabs > .tab-content {
  height: 100%;
}

.tournament-worldmap-tabs .nav-tabs > li.active > a,
.tournament-worldmap-tabs .nav-tabs > li.active > a:hover,
.tournament-worldmap-tabs .nav-tabs > li.active > a:focus {
  background-color: #101d2b;
  border: 1px solid #1f628d;
  border-bottom: 1px solid #101d2b;
  color: #ffb700;
}

.tournament-worldmap-tabs .nav-tabs > li > a {
  border-radius: 0;
  border: 1px solid transparent;
  color: #c8c8c8;
}

.tournament-worldmap-tabs .nav-tabs > li > a:hover {
  background-color: #101d2b;
  border: 1px solid #1f628d;
  border-bottom: 1px solid #101d2b;
}

.tournament-worldmap-tabs .tab-pane {
  margin: 0 auto;
  background-color: rgba(0, 20, 40, 0.6);
  padding: 5px 15px;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  border: 1px solid #1f628d;
  border-radius: 0;
  overflow-y: auto;
  height: 100%;
}

.tournament-worldmap-dialog-buttons .btn-tournament {
  font-size: 18px;
  font-weight: bold;
  padding: 3px 10px;
  background-color: #00385d;
  width: 100px;
  -webkit-animation: tournament-worldmap-button-pulse-blue 1.2s infinite ease-in-out;
  animation: tournament-worldmap-button-pulse-blue 1.2s infinite ease-in-out;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.tournament-worldmap-dialog-buttons .btn-tournament-action-highlight {
  background-color: #49190a;
  -webkit-animation: tournament-worldmap-button-pulse-red 1.2s infinite ease-in-out;
  animation: tournament-worldmap-button-pulse-red 1.2s infinite ease-in-out;
}

.tournament-worldmap-dialog-buttons .btn-tournament:hover {
  -webkit-animation: none;
  animation: none;
  background-color: #00487d;
}

.tournament-worldmap-dialog-buttons .btn-tournament-action-highlight:hover {
  background-color: #69291a;
}

@-webkit-keyframes tournament-worldmap-button-pulse-blue {
  0%,
  100% {
    background-color: #00385d;
  }
  50% {
    background-color: #00487d;
  }
}

@keyframes tournament-worldmap-button-pulse-blue {
  0%,
  100% {
    background-color: #00385d;
  }
  50% {
    background-color: #00487d;
  }
}

@-webkit-keyframes tournament-worldmap-button-pulse-red {
  0%,
  100% {
    background-color: #49190a;
  }
  50% {
    background-color: #69291a;
  }
}

@keyframes tournament-worldmap-button-pulse-red {
  0%,
  100% {
    background-color: #49190a;
  }
  50% {
    background-color: #69291a;
  }
}

.tournament-worldmap-dialog-close {
  font-size: 32px;
  position: absolute;
  right: 24px;
  top: 0;
  z-index: 10;
}

.tournament-worldmap-info-close {
  font-size: 32px;
  position: absolute;
  right: 15px;
  top: 0;
  z-index: 10;
}

.tournament-worldmap-info-pic {
  max-width: 100%;
  margin: 15px 0;
}

.tournament-worldmap-leaders {
  width: 280px;
  max-height: 50%;
  background-color: rgba(0, 30, 50, 0.9);
  border: 1px solid rgba(0, 50, 80, 1);
  position: absolute;
  top: 150px;
  right: 0;
  z-index: 50;
  font-family: 'Inconsolata', monospace;
  font-size: 13px;
  overflow: hidden;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.tournament-worldmap-leaders-min {
  right: -210px;
}

.tournament-worldmap-leaders > table tr > td {
  padding: 2px 5px;
}

.tournament-worldmap-leaders > table,
.tournament-worldmap-leaders > table tr,
.tournament-worldmap-leaders > table tr > td {
  border: 0;
  background-color: transparent;
}

.tournament-worldmap-leaders .tournament-leader-pic {
  max-width: 58px;
  max-height: 58px;
}

.tournament-leader-info-column {
  padding: 0 10px;
}

.tournament-leader-name {
  font-size: 16px;
}

.tournament-worldmap-leaders-heading {
  font-size: 16px;
  padding-top: 5px;
  text-align: center;
}

.tournament-worldmap .challenge-result-stage-breakdown {
  color: #c8c8c8;
  font-size: inherit;
}

.tournament-worldmap #challenge-results {
  color: #fff;
}

.tournament-worldmap #challenge-results li .challenge-result-text {
  margin-top: 0;
}

.tournament-quest-log-stage-info-container {
  margin: 5px 0;
  display: inline-block;
}

.tournament-quest-log-stage-info {
  margin-left: 20px;
  font-size: 12px;
}

.tournament-worldmap .challenge-result-text {
  position: static !important;
}

.tournament-worldmap #challenge-results li {
  padding-bottom: 10px !important;
}

.tournament-worldmap-dialog-points {
  font-size: 32px;
}

.tournament-worldmap-quest-points-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

.tournament-worldmap .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 0;
}

.tournament-download-csv {
  margin-left: 10px;
  color: #eee;
}

.live-stats-btn-container {
  position: absolute;
  right: 14px;
  top: 20px;
}

.tournament-worldmap .countdown-container {
  position: absolute;
  top: 20px;
  left: 25%;
  width: 50%;
  color: #ffb700;
  background-color: rgba(74, 0, 0, 0.9);
  border: 1px solid #700;
  padding: 10px;
}

.tournament-worldmap .countdown-container h5 {
  color: #fff;
}

.tournament-worldmap .worldmap-dialog-content {
  height: auto;
}

.scoring-info-panel.scoring-info-panel-slide {
  max-height: 1000px;
}

.scoring-info-panel {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-height: 0px;
  overflow: hidden;
  margin-top: 5px;
}

.scoring-info-heading {
  font-weight: bold;
  font-size: 15px;
}

.tournament-scoring-info-table {
  background: transparent;
  margin-bottom: 8px;
  width: auto;
}

.tournament-scoring-info-table td,
.tournament-scoring-info-table th {
  text-align: center;
  padding: 0 20px 0 0;
}

#live-stats-btn {
  font-family: 'Inconsolata', monospace;
  font-size: 18px;
  font-weight: bold;
  padding: 6px 10px;
  border: solid #1f628d 2px;
  background-color: rgba(0, 30, 50, 0.9);
}

#live-stats-btn:hover {
  background: rgba(0, 80, 120, 0.6);
  text-decoration: initial;
}

.tournament-over-container {
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  color: #ffb700;
  background-color: rgba(74, 0, 0, 0.9);
  border: 1px solid #700;
  padding: 10px;
  padding-bottom: 25px;
  z-index: 1050;
}

.tournament-over-container p {
  color: #fff;
}

.tournament-over-container .btn {
  padding: 8px 30px;
  border-radius: 0;
  margin-top: 8px;
}

.no-animate.ng-enter-active,
.no-animate.ng-leave-active {
  transition: 0s none !important;
  -webkit-transition: 0s none !important;
  animation: 0s none !important;
  -webkit-animation: 0s none !important;
}

/** Company audit log table **/
.table-company-audit {
  table-layout: initial;
  word-wrap: initial;
}

#assessments-invite-users fieldset {
  background: #111 !important;
  padding: 10px 30px !important;
  border-radius: 5px;
}

#assessments-invite-users h2,
h2.yellow-text {
  margin-bottom: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold !important;
}

.yellow-shadow {
  text-shadow: #fa0 2px 0px 10px;
}

#assessments-invite-users h2 small {
  margin-left: 15px;
}

#assessments-invite-users .mid-band {
  position: absolute;
  top: 30%;
  left: 0px;
  right: 0px;
  font-size: 20px;
  z-index: 1;
  height: 42px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 1px solid lightblue;
  border-bottom: 1px solid lightblue;
  color: lightblue;
  padding: 6px 0;
}

.mid-band.invitee-external-developer {
  color: orange !important;
  border-color: orange !important;
}

.mid-band.invitee-remove-warning {
  color: orange !important;
  border-color: orange !important;
}

#assessments-invite-users .glyphicon {
  top: 1px;
  left: -2px;
}

#assessments-invite-users button .glyphicon {
  margin: 0px 5px;
  top: 2px;
}

#assessments-invite-users fieldset form {
  margin-top: 0;
}

.language-selected {
  border: 1px solid #ffb700 !important;
  box-shadow: #ffb700 0px 0px 10px !important;
  border: 1px solid #dd9935 !important;
}

.language-selected .big-language-icon {
  text-shadow:
    #dd9935 1px 1px 1px,
    #000 -1px -1px 2px,
    #dd9935 0px 0px 50px !important;
  border: 1px outset #ad7925 !important;
  box-shadow: #dd9935 0 0 10px !important;
}

#assessments-invite-users .small-language-icon {
  opacity: 0.8;
  font-size: 80%;
  margin-left: 10px;
}

#assessments-invite-users .assessment-list-filter {
  margin-top: 30px !important;
}

#assessments-invite-users .invitee-count {
  font-size: 11pt;
  padding: 10px;
  background: #333;
  margin-bottom: 15px;
}

#assessments-invite-users .users-grid > div {
  padding: 0px;
}

#assessments-invite-users .select-user-box {
  height: auto;
  background: #222;
  border: 2px solid #222;
  margin: 5px !important;
}

#assessments-invite-users .invitee-is-invited {
  height: auto;
  background: #222;
  border: 2px solid #f99b16;
  margin: 5px !important;
}

#assessments-invite-users .external-invitee-is-invited {
  height: auto;
  background: #222;
  border: 2px solid #f99b16;
  margin: 5px !important;
}

#assessments-invite-users .external-invitee-email {
  padding: 4px;
}

#assessments-invite-users .select-user-box .invitee-displaypic-container {
  float: left;
  width: 45px;
  height: 45px !important;
  overflow: hidden;
  padding: 0px !important;
  margin: 10px;
}

#assessments-invite-users .select-user-box .invitee-displaypic-container img {
  width: 100%;
  border-radius: 3px;
}

#assessments-invite-users .select-user-box .invitee-ext {
  position: absolute;
  bottom: 2px;
  right: 15px;
  font-size: 10px;
}

#assessments-invite-users .invite-indicator {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 16px;
  color: white;
}

#assessments-invite-users .invite-indicator.invitee-remove-warning {
  background: rgba(0, 0, 0, 0);
  font-weight: normal;
  color: red;
}

#assessments-invite-users .select-user-box .caption small {
  float: left;
  width: 100px;
}

.text-red {
  color: red;
}

#assessments-invite-users .search-help-box {
  border: 1px solid orange;
  padding: 15px;
  margin-bottom: 15px;
}

#assessments-invite-users .drop-box {
  text-align: left;
  margin: 15px 0;
}

.drop-box {
  background-color: #222;
  border: 2px dashed #cccccc;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.drop-box.dragover,
.drop-box:hover {
  background-color: #111;
}

.drop-box.dragover-error {
  border: 2px dashed #c9493a;
}

.userlist-file-example {
  font-family: Consolas, monospace;
  font-weight: bold;
  padding: 10px 0 0 18px;
  text-align: left;
}

.onboarding-message-input-container {
  margin-right: 16px;
}

.onboarding-message-input {
  width: 100%;
  height: 150px;
}

.onboarding-message-admins-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.onboarding-message-admins-list li {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.onboarding-message-admins-list img {
  width: 32px;
  height: 32px;
  display: block;
  margin: 0;
  padding: 0;
}

.intro-splash-welcome-heading {
  text-align: center;
  margin-bottom: 32px;
  color: #ffb700;
}

.intro-splash-onboarding-message-container {
  margin-bottom: 60px;
}

.intro-splash-onboarding-message {
  margin-top: 12px;
  margin-bottom: 32px;
}

.intro-splash-onboarding-message-heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #ffb700;
  font-weight: 400;
}

.intro-splash-onboarding-message-heading-icon {
  width: 40px;
  vertical-align: top;
  padding-right: 12px;
}

.intro-splash-onboarding-message-body {
  padding-left: 50px;
  color: #fff;
}

*[ng-click]:enabled,
*[ui-sref]:enabled {
  cursor: pointer !important;
}

.pagination-hidden-margin {
  margin-top: 30px;
}

.pagination li a.keyboard-selected,
.pagination li.active a {
  transform: scale(1.1);
  border-radius: 3px;
  border: 1px solid white;
  outline: none;
  z-index: 9;
}

/*** Account owner ***/
.account-owner-displayPic {
  width: 20px;
  height: 20px;
  border-radius: 25px;
  vertical-align: text-top;
}

.assessment-actions-dropdown.dropdown {
  position: relative;
  padding: 5px;
  /* top: 16px; */
  font-size: 1.7em;
  vertical-align: middle;
}

.assessment-actions-dropdown .btn-xs {
  position: relative;
  top: -3px;
  padding: 2px 15px;
}

.assessment-actions-dropdown .dropdown-menu {
  left: auto;
  right: -10px;
  top: 30px;
}

/*
	lea - Padding for all drop down menu icons
	      to pull icon away from text.
		  Consider dropping the .assessment-actions-dropdown to apply globally
*/
.assessment-actions-dropdown .dropdown-menu .fa,
.assessment-actions-dropdown .dropdown-menu .glyphicon {
  padding-right: 10px;
}

.empty-state-h1 {
  padding-top: 40px;
  font-size: 28px;
}

.empty-state-p {
  font-size: 16px;
  width: 60%;
  margin: 0 auto;
}

.empty-state-btn {
  padding-bottom: 40px;
  margin-top: 15px;
}

.icon-circle .glyphicon {
  font-size: 16px;
  margin: 0px;
}

.icon-circle {
  font-size: 16px !important;
  margin-left: 10px !important;
  padding: 10px 5px;
  border-radius: 2px;
  border: 0px;
  background: transparent;
}

.icon-circle div {
  float: right;
  margin: 0px 10px;
  position: relative;
  font-size: 13px;
  font-weight: normal;
}

.icon-circle div.slide {
  /*width: 0px;
	overflow: hidden;*/
}

.icon-circle:hover div.slide {
  width: auto;
  min-width: 10px;
  transition: all 0.3s ease-in-out;
}

.icon-main {
  color: #c67605;
  border-color: #c67605;
}

.icon-danger {
  color: #e9322d;
  border-color: #e9322d;
}

.icon-highlight {
  color: #4ab9db;
  border-color: #4ab9db;
}

.icon-circle:hover {
  transform: scale(1.1);
  transition: all 0.1s ease-in-out;
}

.icon-bg-main {
  background: #c67605;
  color: white;
}

.icon-bg-danger {
  background: #e9322d;
  color: white;
}

.icon-bg-highlight {
  background: #4ab9db;
  color: white;
}

.icon-md {
  font-size: 20px;
  font-weight: 100;
}

.assessment-attempts-options {
  width: 50%;
  text-align: right;
}

.input-xs {
  height: 24px !important;
}

select.input-xs {
  padding: 0px 10px;
  background: white;
  border: none;
}

.assessment-attempts-search {
  position: relative;
  top: 10px;
  width: 100%;
  background: #1c1e22;
  border: 1px solid #111;
  border-radius: 3px;
  padding: 10px 10px 15px 0px;
  text-align: right !important;
  display: inline-block;
}

.assessment-attempts-search form {
  float: right;
}

.assessment-attempts-search form .form-control {
  margin-left: 10px;
  float: left;
  width: auto;
}

.assessment-list-filter form {
  float: right;
}

.assessment-list-filter select {
  padding: 0px 10px;
  height: 20px !important;
  border-radius: 5px;
  background: white !important;
}

.icon-md {
  font-size: 20px;
  font-weight: 100;
}

.assessment-attempts-options {
  width: 50%;
  text-align: right;
}

.input-xs {
  height: 24px !important;
}

select.input-xs {
  padding: 0px 10px;
  background: white;
  border: none;
}

#durationSlider {
  margin: 20px 0 10px 0;
}

#durationSlider #custom-handle {
  width: 3em;
  height: 1.6em;
  top: 50%;
  margin-top: -0.8em;
  text-align: center;
  line-height: 1.6em;
}

.tournament-edit-tabs .nvd3 .tick line {
  opacity: 0;
}

.tournament-edit-tabs .nvd3 .nv-axis path {
  opacity: 0;
}

.assessment-attempts-search {
  position: relative;
  top: 10px;
  width: 100%;
  background: #1c1e22;
  border: 1px solid #111;
  border-radius: 3px;
  padding: 10px 10px 15px 0px;
  text-align: right !important;
  display: inline-block;
}

.assessment-attempts-search form {
  float: right;
}

.assessment-attempts-search form .form-control {
  margin-left: 10px;
  float: left;
  width: auto;
}

.assessment-list-filter form {
  float: right;
}

.assessment-list-filter select {
  padding: 0px 10px;
  height: 20px !important;
  border-radius: 5px;
  background: white !important;
}

#compare.ng-hide {
  display: none !important;
}

.worldmap-dropdown-img {
  margin-bottom: 4px;
  margin-right: 10px;
}

.worldmap-dropdown-language-icon {
  vertical-align: super;
  margin-right: 10px;
}

.btn-success {
  background-image: -webkit-linear-gradient(#58ac58, #42a442 60%, #339e33);
  background-image: -o-linear-gradient(#58ac58, #42a442 60%, #339e33);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#58ac58),
    color-stop(60%, #42a442),
    to(#339e33)
  );
  background-image: linear-gradient(#58ac58, #42a442 60%, #339e33);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff58ac58', endColorstr='#ff339e33', GradientType=0);
}

.btn-success:hover {
  background-image: -webkit-linear-gradient(#1f6d1f, #278327 40%, #2d922d);
  background-image: -o-linear-gradient(#1f6d1f, #278327 40%, #2d922d);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1f6d1f),
    color-stop(40%, #278327),
    to(#2d922d)
  );
  background-image: linear-gradient(#1f6d1f, #278327 40%, #2d922d);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1f6d1f', endColorstr='#ff2d922d', GradientType=0);
}

.btn-info {
  background-image: -webkit-linear-gradient(#54aac3, #3ba0be 60%, #2a99bb);
  background-image: -o-linear-gradient(#54aac3, #3ba0be 60%, #2a99bb);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#54aac3),
    color-stop(60%, #3ba0be),
    to(#2a99bb)
  );
  background-image: linear-gradient(#54aac3, #3ba0be 60%, #2a99bb);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff54aac3', endColorstr='#ff2a99bb', GradientType=0);
}

.btn-info:hover {
  background-image: -webkit-linear-gradient(#10728f, #1586a8 40%, #1894b9);
  background-image: -o-linear-gradient(#10728f, #1586a8 40%, #1894b9);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#10728f),
    color-stop(40%, #1586a8),
    to(#1894b9)
  );
  background-image: linear-gradient(#10728f, #1586a8 40%, #1894b9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff10728f', endColorstr='#ff1894b9', GradientType=0);
}

.challenge-toolbar-dropdown {
  margin-left: 5px;
  font-size: 20px;
  vertical-align: top;
  margin-right: 0px;
}

.challenge-toolbar-dropdown .glyphicon {
  color: #c8c8c8;
}

.toolbar-error-messages {
  position: absolute;
  right: 40px;
  bottom: -96px;
  z-index: 40;
  top: 18px;
}

.toolbar-error-messages .cancel-button {
  width: 10px;
  float: right;
  margin-top: -15px;
  margin-right: -9px;
}

.toolbar-row {
  max-height: 28px;
}

h4 .glyphicon {
  top: 2px !important;
  margin-right: 2px;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 36px;
}

.input-group-addon {
  height: 36px;
  padding: 5px 12px;
}

input::-ms-clear {
  display: none;
}

.input-clear-text-icon {
  z-index: 3;
  float: left;
  display: table-cell;
  width: auto;
  color: rgba(25, 27, 29, 0.67);
  border-left: 0px;
  border-right: 0px;
  position: absolute;
  right: 0px;
  background-color: rgba(255, 255, 255, 0);
  vertical-align: middle;
  height: 34px;
  padding: 8px 12px 8px 0px;
  margin-top: 1px;
  margin-bottom: 1px;
  cursor: pointer;
}

body {
  display: inline-block;
  width: 100%;
  height: 100% !important;
}

body.assessment-in-progress {
  padding-top: 10px;
}

body.challenge-in-progress {
  overflow: hidden;
  overflow-y: auto;
}

body.scw-body-light-background {
  background-color: white;
}

.display-inline-block {
  display: inline-block;
}

.form-control-xs {
  height: 20px;
  padding: 0px 4px;
}

.form-control-xs.select2-container {
  padding: 0;
  border: 0;
  width: 140px;
}

.form-control-xs.select2-container .select2-choice {
  height: 20px;
  line-height: 20px;
  border: 0;
}

.form-control-xs.select2-container .select2-choice abbr {
  top: 8px;
}

.form-control-xs.select2-container .select2-choice .select2-arrow b {
  margin-top: 1px;
  background: url(css/select2.png) no-repeat 0 -3px;
}

.form-control-xs.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px -3px;
}

.form-control-xs.select2-dropdown-open .select2-drop {
  border: 0;
}

.vertical-align-middle {
  vertical-align: middle;
}

.full-height-ui-select {
  height: 100%;
}

/* .contextual-learning .simple-flow-bg {
  display: flex;
  flex: auto;
  position: relative;
  z-index: 0 !important;
} */

.contextual-learning-container {
  position: fixed;
  top: 0;
  font-size: 12pt !important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.simple-flow,
.simple-flow-view {
  position: fixed;
  top: 0;
  font-size: 12pt !important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
}
.new-simple-flow-state.authed .simple-flow,
.new-simple-flow-state.authed .simple-flow-view {
  padding-top: 50px !important;
}

.simple-flow-links {
  padding-top: 15px;
  padding-left: 0.3%;
}

.simple-flow-bg {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background: #222;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  filter: grayscale(100%);
  z-index: 9997;
}

.simple-flow-modal-z-index-hack {
  z-index: 1151 !important;
}
.new-simple-flow-state .modal-backdrop {
  z-index: 1150 !important;
}

body.simple-flow-signup-modal-open header,
body.simple-flow-signup-modal-open .simple-flow-view {
  transition: filter 500ms ease-in-out;
  filter: blur(3px);
}

.simple-flow-view,
header {
  transition: filter 250ms ease-in-out;
}

div.simpleflow-worldmap-container {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.12;
  width: 100%;
  z-index: 9998;
}

div.simpleflow-worldmap-container svg {
  width: 100%;
}
.backdrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}
.backdrop-content {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.container-abs-selection {
  position: absolute;
  z-index: 10000;
  width: 100vw;
  margin: 0;
  padding: 20px;
}
.container-abs-less {
  width: calc(100% - 415px);
  left: 75px;
}

#main-view.contextual-learning {
  display: flex;
  flex: auto;
}

#contextual-learning-view {
  position: relative !important;
  padding-top: 0 !important;
  display: flex;
  flex: auto;
}

.contextual-learning.simple-flow.new-simple-flow {
  padding-top: 0 !important;
  position: relative;
}

.contextual-learning.worldmap-column.worldmap-column-fullwidth {
  margin-left: 0 !important;
}

.container-flex-selection {
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.select-picker {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  color: white;
  line-height: 14pt;
  padding: 10px;
  border-radius: 5px;
  min-height: 75px;
  max-width: 100%;
}
.select-picker-disabled {
  border: 3px solid #7b7b7b !important;
}

.picker-container {
  padding: 0;
  max-height: calc(100vh - 175px);
  white-space: nowrap;
  overflow: scroll;
  overflow-x: hidden;
}

.picker-search {
  width: 100%;
}

.picker-search-input {
  width: 100%;
  padding: 3px;
  margin: 5px 0;
}

.picker-list {
  width: 100%;
  padding: 5px;
}

.select-picker i {
  font-size: 14pt;
  color: white;
}
#languageChanger {
  position: absolute;
  color: white;
  width: 220px;
  line-height: 14pt;
  padding: 10px 5px;
  border-radius: 5px;
}
#languageChanger span.glyphicon {
  font-size: 8pt;
  font-weight: normal;
  margin-left: 5px;
  top: 1.5pt;
}
.select-picker img {
  width: 40px;
  height: 40px;
  filter: brightness(100);
}
.select-picker-header-icon {
  padding: 3px;
}
#languageChanger i {
  margin-left: 15px;
  font-size: 28pt;
  margin: -2pt 10px;
  float: left;
  color: white;
}
.simple-flow #languageChanger {
  margin-top: 15pt;
  position: absolute;
  color: white;
  width: 200pt;
  white-space: nowrap;
  line-height: 14pt;
  /*background: rgba(255,255,255,.03);*/
  padding: 10pt 5pt;
  z-index: 99999;
}
.simple-flow #flow-transitions {
  width: 60%;
  margin-left: 20%;
}

.simple-flow #flow-transitions > div {
  float: left;
  width: 33%;
  padding: 30px 0px;
  opacity: 0.2;
}

.simple-flow #flow-transitions > div:last-child {
  border-right: 0px;
}

.simple-flow #flow-transitions .icon {
  font-size: 30pt;
  font-weight: 100;
  font-style: normal;
  color: white;
  /* box-shadow: white 0px 0px 30px 2px; */
  padding: 20px;
  margin-bottom: 15px;
}

.simple-flow #flow-transitions .active {
  /*background: #e80;
	border-radius: 15px; */
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid #e80;
  opacity: 1;
}

.simple-flow #flow-transitions .transition-marker-tooltip {
  opacity: 1;
}

.simple-flow {
  color: white;
}

.simple-flow footer.learn-footer {
  font-size: 14pt;
  padding: 15px 0px;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0px;
  z-index: 0;
  color: #f90;
  bottom: 0pt;
  width: 100%;
}

.simple-flow button.btn.btn-primary {
  margin-top: -2px;
  font-size: 14pt;
  padding: 5px 25px;
  border-radius: 0px;
  background: #f90;
  margin-left: 15pt;
}

.new-simple-flow button.btn.btn-primary {
  border-radius: 6px;
  padding: 6px 12px;
}

.simple-flow button.btn.btn-primary i.icon {
  font-size: 80%;
  top: 2px;
  left: 5px;
}

.simple-flow h2 {
  margin: 0px;
  font-size: 14pt;
}

.simple-flow header {
  margin-top: 0pt;
  margin-bottom: 0px;
}

.simple-flow header img {
  width: 185px;
}

.simple-flow h1 {
  font-size: 24pt;
}

.simple-flow #transition-explainer .transition-explainer-box,
.simple-flow #transition-explainer .transition-mission-box {
  margin-top: 30pt;
  margin-bottom: 30pt;
  padding: 30pt;
  background: rgba(30, 30, 30, 0.7);
  color: #e80;
  font-size: 14pt;
  left: 0px;
}

.simple-flow #transition-explainer .read-more {
  background: #e80;
  color: #eee;
  padding: 3px 8px;
  white-space: nowrap;
}

.simple-flow #transition-explainer h4 {
  text-transform: uppercase;
  text-align: center;
  font-size: 120%;
  margin: 0px 0px 20pt 0px;
  font-family: impact, Inconsolata;
  letter-spacing: 3px;
}

.simple-flow #guideline {
  color: white;
}

.simple-flow #guideline h3,
.simple-flow #guideline h2 {
  font-weight: bold;
  margin-top: 20px;
  color: #ee8800;
}

.simple-flow #guideline h4 {
  color: #ee8800;
  font-weight: bold;
}

.simple-flow #guideline code {
  padding: 2px 4px;
  font-size: 90%;
  color: #ff9200;
  border: 1px dashed #ee8800;
  background-color: #222325;
  border-radius: 4px;
  font-weight: bold;
}

.simple-flow #guideline pre {
  display: block;
  padding: 2px 4px;
  font-size: 90%;
  color: #ff9200;
  border: 1px dashed #ee8800;
  background-color: #222325;
  border-radius: 4px;
  margin: 0 0 10px;
  word-break: break-all;
  word-wrap: break-word;
}

.simple-flow #guideline .tile {
  text-align: center;
  border: 1px solid black;
  list-style: none;
  margin-left: 10px;
  background-color: #23262d;
  display: inline-block;
  height: 100%;
}

.simple-flow #guideline pre br {
  content: ' ';
  display: none;
}

.simple-flow #guideline li {
  margin-top: 10px;
}

.simple-flow footer a {
  text-decoration: underline;
  margin-right: 15px;
}

.simple-flow footer a:hover {
  text-shadow: #e80 0px 0px 1px;
}

.simple-flow.learn .transition-explainer-box {
  transition: 0.5s;
}

.simple-flow.play .transition-explainer-box,
.simple-flow.game .transition-explainer-box {
  position: absolute;
  transition: all 0.5s ease-out;
  opacity: 0;
  transform: scale(0.2);
}

.simple-flow #transition-explainer-in-worldmap {
  width: 100%;
  margin: 0;
  padding: 0;
}

.simple-transition-explainer-in-worldmapflow #transition-explainer .transition-mission-box {
  position: relative;
  left: 3000px;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.simple-flow.new-simple-flow.play {
  overflow: hidden;
}

.simple-flow.play #transition-explainer .transition-mission-box,
.simple-flow #transition-explainer-in-worldmap .transition-mission-box {
  position: relative;
  width: auto;
  height: auto;
  left: 0px;
  display: block;
  transition: left 0.5s ease-out;
  transition-delay: 0s;
  font-size: 14pt;
  padding: 20pt;
}

.simple-flow #transition-explainer-in-worldmap .transition-mission-box h5,
.simple-flow #transition-explainer .transition-mission-box h5 {
  color: #eee;
  text-transform: uppercase;
  font-family: Impact, Inconsolata;
  letter-spacing: 2px;
  font-weight: normal;
  font-size: 14pt;
}

.simple-flow #transition-explainer .transition-mission-box img,
.simple-flow #transition-explainer-in-worldmap .transition-mission-box img {
  float: left;
  width: 30%;
  margin-right: 15pt;
}

.new-simple-flow #transition-explainer .transition-mission-box img,
.new-simple-flow #transition-explainer-in-worldmap .transition-mission-box img {
  float: left;
  width: 15%;
  margin-right: 15pt;
}

.new-simple-flow .mission-briefing-title {
  text-align: center;
  text-transform: uppercase;
  color: rgb(238, 136, 0);
}

.simple-flow #transition-explainer .transition-mission-box > div.row:first-of-type > div:first-of-type,
.simple-flow #transition-explainer-in-worldmap .transition-mission-box > div.row:first-of-type > div:first-of-type {
  border-right: 2px dashed #666;
}

.simple-flow #transition-explainer .transition-mission-box .mission-briefing,
.simple-flow #transition-explainer-in-worldmap .transition-mission-box .mission-briefing {
  color: #fff;
  font-family: courier, arial;
  font-size: 11pt;
}

.new-simple-flow #transition-explainer .transition-mission-box .mission-briefing,
.new-simple-flow #transition-explainer-in-worldmap .transition-mission-box .mission-briefing {
  color: #fff;
  font-family: courier, arial;
  font-size: 8pt;
}

.simple-flow #transition-explainer .transition-mission-box > div.mission-cta,
.simple-flow #transition-explainer-in-worldmap .transition-mission-box > div.mission-cta {
  margin-top: 15pt;
  padding-top: 15pt;
  border-top: 1px solid;
}

.simple-flow.game #transition-explainer .transition-mission-box,
.simple-flow.game #transition-explainer-in-worldmap .transition-mission-box {
  position: absolute;
  display: block;
  opacity: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: scale(0.2);
  transition: all 1s ease-out;
}

.simple-flow #transition-explainer-in-worldmap {
  position: absolute;
  left: 0;
  background-color: rgba(30, 30, 30, 0.7);
  bottom: 0;
}

.simple-flow.game #flow-transitions {
  width: 1000%;
  opacity: 0;
  transition: all 1s ease-out;
  transition-delay: 0.7s;
}

.simple-flow header {
  top: 0px;
}

.simple-flow.game header {
  transition: all 0.5s ease-out;
  position: relative;
  text-align: center;
  width: 100%;
  top: -400px;
  transition-delay: 1.2s;
}

.new-simple-flow-state.authed {
  padding-top: 0;
}
.new-simple-flow-state.authed header {
  min-height: 50px;
  z-index: 10000;
}
.new-simple-flow-state.authed header nav {
  z-index: 10000;
}

.simple-flow #game-mode-loading h4 {
  text-transform: uppercase;
  text-align: center;
  font-size: 22pt;
  margin: 0px 0px 30px 0px;
  text-shadow: #e80 0px 0px 30px;
  font-family: impact, Inconsolata;
  letter-spacing: 3px;
  color: #e80;
}

.simple-flow #game-mode-loading {
  opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  height: 100%;
  z-index: -99999;
  width: 100%;
  height: 100%;
  transform: translate3d(0%, 40%, 0);
}

.simple-flow #game-mode-loading .loader {
  margin: auto;
}

.simple-flow.game #game-mode-loading {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  transition-delay: 1.2s;
}

.simple-flow.game #languageChanger {
  display: none;
}

.new-simple-flow .select-picker {
  background-color: #1c1e22;
  border: 3px solid #ffb700;
}
.new-simple-flow #languageChanger {
  left: 66px;
  background-color: #1c1e22;
  border: 3px solid #ffb700;
}
.new-simple-flow .picker-container {
  background: #1c1e22;
  padding-top: 5px;
}
.new-simple-flow .select-picker-header {
  display: flex;
  flex-direction: row;
}
.new-simple-flow .language-picker-header {
  display: flex;
  flex-direction: row;
}
.select-picker-header .select-picker-header-title {
  flex-grow: 1;
  padding: 5px;
}
.language-picker-header .language-picker-header-title {
  flex-grow: 1;
}
.language-picker-header .language-picker-dd-icon {
  align-self: center;
  font-size: medium;
}
.select-picker-header .select-picker-dd-icon {
  align-self: center;
  font-size: medium;
}
.select-picker-icon {
  align-self: center;
  font-size: medium;
}
.new-simple-flow .language-picker-container {
  background: #1c1e22;
  padding-top: 5px;
}

.new-simple-flow .picker-container > div {
  padding: 3px;
}

.new-simple-flow #categoryChanger .picker-container > div {
  padding-right: 12px;
}

.new-simple-flow .language-picker-container > div {
  padding: 3px;
  padding-left: 12px;
}
.new-simple-flow button.btn.btn-primary {
  margin-left: 0;
}

.new-simple-flow button.btn.btn-primary > a {
  color: white;
}

.new-simple-flow button.btn.btn-primary > a:hover,
a:focus:not(.MuiLink-root):not(.MuiMenuItem-root):not(.ds-focus):not(.nav-link):not(.guideline-player-content a) {
  color: rgb(0, 85, 128);
  text-decoration: underline;
}

.new-simple-flow #leaderboard-panel {
  overflow: hidden;
}

.new-simple-flow #leaderboard-panel .leaderboard-table-container {
  overflow: auto;
  height: calc(100% - 220px);
}

.new-simple-flow #leaderboard-panel .leaderboard-buttons-container {
  position: absolute;
  bottom: 0;
  max-height: 220px;
  width: calc(100% - 25px);
  padding-right: 5px;
  padding-top: 5px;
}

.new-simple-flow #leaderboard-panel .leaderboard-buttons-container button {
  width: 100%;
  margin-bottom: 15px;
}

.new-simple-flow-register-button-container .btn.btn-primary {
  background-image: none;
  background-color: #f90;
  margin-bottom: 4px;
  color: white;
}

.new-simple-flow .language-picker-container > div:hover {
  background: rgba(255, 255, 255, 0.8);
  color: black;
  max-height: calc(100vh - 125px);
  overflow-y: auto;
}

.section-challenge-details {
  margin-bottom: 20px;
}
.section-challenge-details .flex {
  flex-grow: 1;
}

.container-less {
  padding: 0 5px;
}

.triple-column-colour-box {
  margin-right: -5px;
  margin-left: -5px;
  display: flex;
  flex-wrap: wrap;
}

.triple-column-colour-box .colour-box-scwdarkgrey {
  background-color: #303030;
}

.thin-row {
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0;
}

.thin-row .colour-box-small {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
}

.thin-row [class^='col-md'],
.thin-row #leaderboard-panel {
  padding: 0 5px;
  height: auto;
}

.thin-row-col .thin-row {
  margin: 0 0 10px 0px;
  height: auto !important;
}

.thin-row-col .thin-row:nth-child(3) {
  margin: 0;
}

.triple-column-colour-box .leaderboard-title {
  margin: 0;
}

.triple-column-colour-box [class^='col-md'] > div {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: #161616;
  height: 100%;
}

.triple-column-colour-box > div:nth-child(2) > div {
  position: relative;
  padding-bottom: 96px;
}

.triple-column-colour-box .maturity-display-col.right-box {
  width: 60%;
}

.triple-column-colour-box .maturity-display-col.left-box {
  width: 40%;
}

.triple-column-colour-box .maturity-display-col.left-box img {
  width: 100%;
}

.modal-content .simple-flow-modal-title {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Open Sans';
}

.modal-content .simple-flow-modal-subtitle {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  font-family: 'Open Sans';
}

.signup-modal-form {
  display: inline-block;
}

.simple-flow-modal-content {
  background-color: rgba(37, 41, 46, 0.94);
  border: 2px solid #454545;
  font-family: 'Open Sans';
}

.simple-flow-modal-header {
  border-bottom: 1px solid #454545;
}

.simple-flow-modal-footer {
  border-top: 1px solid #454545;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: right;
}

.simple-flow-modal-footer-buttons {
  display: flex;
  flex: 1;
  align-content: flex-start;
  justify-content: left;
  flex-direction: row-reverse;
}

.simple-flow-modal-footer-links {
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: small;
}

.simple-flow-modal-footer-links a {
  margin: 0;
  padding-left: 3px;
  font-size: small;
}

.signup-modal-inputs {
  border-bottom: 1px solid #454545;
}

.signup-modal-hr,
.stats-hr {
  border: 1px solid #454545;
  margin-left: 5px;
  margin-right: 5px;
}

.simple-flow-modal-required {
  font-size: 14px;
  color: inherit;
  font-weight: normal;
  font-family: 'Open Sans';
}

.simple-flow-modal-notification {
  font-size: 14px;
  color: inherit;
  font-weight: bold;
  font-family: 'Open Sans';
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  font-weight: bold;
}

.signup-btn {
  color: #000000;
  font-weight: bold;
}

.signup-modal-form .privacy-policy-optin {
  width: 100%;
  display: block;
}

.signup-modal-form .subscription-checkbox {
  float: left;
  height: 14px;
  width: 14px;
  background-color: initial;
  color: green;
}

.signup-modal-form .subscription-label {
  font-size: 14px;
  margin-left: 10px;
  float: left;
  width: 95%;
  font-weight: normal;
}

.signup-modal-form .purchasing-driver-dropdown {
  width: 60%;
}

.signup-modal-form .label-display {
  display: block;
  padding-top: 17px;
}

.signup-modal-form .input-display {
  width: 100%;
}

.signup-modal-form .ng-invalid-email {
  border-color: red;
}

.signup-modal-form .input-left-container {
  float: left;
  width: 50%;
  padding-right: 20px;
}

.signup-modal-form .input-left {
  float: left;
  width: 50%;
  line-height: 36px;
  margin-bottom: 0;
}

.signup-modal-form .input-right {
  float: right;
  width: 50%;
}

.signup-modal-form .input-required:after {
  content: ' *';
  color: #c9493a;
}

.simple-flow header h2 {
  color: #e80;
  font-size: 22pt;
  line-height: 40pt;
  font-weight: bold;
}

.simple-flow.capture .user-info {
  margin-top: 15px;
  margin-left: 30px;
  font-size: 12pt;
  line-height: 18pt;
  font-weight: bold;
}

.simple-flow.capture-updated .user-info {
  padding-left: 15px;
}
.simple-flow.capture header img {
  width: 100%;
}

.simple-flow .signup-button {
  margin-top: 0px !important;
  margin-left: 0px !important;
  font-size: 13pt !important;
  font-weight: bold;
  margin-right: 15px;
  border-radius: 0pt;
}

svg#new-simpleflow-worldmap path {
  fill: gray;
}

/** Fix for VERY small screens */
@media (max-height: 750px) {
  .simple-flow #flow-transitions,
  .simple-flow #transition-explainer {
    transform: scale(0.9);
    position: relative;
    top: -30pt;
  }
}

@media (max-height: 650px) {
  .simple-flow #flow-transitions,
  .simple-flow #transition-explainer {
    transform: scale(0.8);
    position: relative;
    top: -30pt;
  }

  .simple-flow #transition-explainer {
    top: -80pt;
  }

  .simple-flow footer,
  .simple-flow footer .btn {
    font-size: 11pt !important;
  }
}

.text-align-center {
  text-align: center;
}

.confidence-level-popover-paragraph {
  margin: 10px;
  color: black;
  text-decoration: none;
  font-weight: normal;
}

.confidence-level-popover-list {
  color: black;
  text-decoration: none;
  font-weight: normal;
  list-style: none;
}

.confidence-level-popover-list > li > i {
  margin-right: 5px;
}

.confidence-level-popover-menu {
  width: 300px;
  background-color: lightgrey;
  padding: 0;
}

.confidence-level-question-mark {
  position: absolute;
  margin-left: 5px;
  top: 2px;
}

.confidence-level-metrics-question-mark {
  position: absolute;
  margin-left: 5px;
  top: 2px;
  right: -18px;
}

.confidence-level-metrics-popover-menu {
  width: 300px;
  background-color: white;
}

.tournaments-join-languages-error-icon {
  color: #c9493a;
  position: relative;
  bottom: -8px;
}

.learning-progress-main-page {
  overflow: hidden;
  height: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: #1c1e22;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.learning-progress {
  overflow: hidden;
  height: 5px;
  margin-top: 55px;
  margin-bottom: 20px;
  background-color: #1c1e22;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.learning-progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  background-color: #7a8288;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.learning-progress-bar-success {
  background-color: #657d19;
}

.learning-progress-bar-danger {
  background-color: #c9493a;
}

.noclick {
  cursor: not-allowed;
}

#error-page {
  margin-top: 5%;
  margin-bottom: 5%;
}

#error-page h2 {
  color: #ffb700;
  font-size: 40pt;
  font-weight: bold;
}

#error-page h3 {
  margin-top: 25px;
}

#error-page .error-message {
  width: auto !important;
  margin: 50px 0 50px;
  font-size: 16pt;
  padding: 30px 30px;
  background: #333e48;
  color: white;
  border-radius: 4px;
  border: 2px #ffb700 solid;
}

#error-page .sso-error-message {
  padding: 1.5em 1em;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 1em 2em;
  border: solid #f5eddb 1px;
  border-radius: 3px;
}

#error-page .btn {
  font-size: 14pt;
}

#error-page .btn-demo {
  margin-top: 15px;
}

.custom-sweet-alert-button {
  color: #fff;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  -webkit-border-radius: 4px;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0;
  cursor: pointer;
  display: inline-block;
  box-shadow:
    0px 0px 2px rgba(243, 139, 0, 0.8),
    inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  background-color: rgb(243, 139, 0);
}

.custom-sweet-alert-anchor > a {
  color: #575757;
  text-decoration: underline;
}

.custom-sweet-alert-anchor > a:hover {
  color: #575757;
}

.chart-container-doughnut {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.chart-container-doughnut .chart-container {
  height: 100%;
  width: 100%;
}

.chart-container-doughnut canvas {
  margin: auto;
  margin-top: 0%;
  text-align: center;
  width: 100%;
  height: 100%;
}

*[perfect-middle] {
  top: 0;
  position: absolute;
  color: white;
  width: 100%;
  font-weight: normal;
  height: 100%;
  display: table-cell;
}

.doughnut-text.doughnut-value {
  color: #eb0;
  font-weight: bold;
  margin-bottom: 1%;
}

.doughnut-text.doughnut-label {
  background: transparent;
  border: none;
  color: white;
  font-family: Roboto;
  font-size: 50%;
  padding: 0px;
  margin: 0px;
}

partial-doughnut {
  display: block;
  width: 100%;
  height: 100%;
}

.assessment-closed-label {
  background: #ddd;
  color: #333;
  padding: 6px 10px;
  border-radius: 5px;
  margin: 15px;
  position: relative;
  top: -3px;
  text-shadow: none;
  font-size: 11pt;
  display: inline-block;
}

.assessment-info-bar {
  margin-top: -8px;
  font-size: 12pt;
  font-family: Roboto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.assessment-info-bar > div {
  float: left;
  width: 16%;
  min-width: 4cm;
  text-align: center;
}

.assessment-info-bar.loading {
  margin-top: -8px;
  padding: 60px 0px;
  text-align: center;
  font-size: 12pt;
  font-family: Roboto;
  width: 100%;
}

.assessment-info-bar.loading > div {
  width: auto;
  text-align: center;
  margin: auto;
  float: none !important;
}

.assessment-language-select-bar {
  margin: 25pt auto 25pt auto;
  font-size: 15pt;
  font-family: Roboto;
  width: 50%;
}

.assessment-table-head {
  white-space: nowrap;
}

.assessment-language-selected {
  box-shadow: #ffb700 0px 0px 10px !important;
  color: #dd9935;
}

.assessment-create-edit-header {
  padding-left: 0px;
  padding-right: 0px;
}

.assessment-language-selected-category {
  font-style: italic;
  font-size: 12px;
}

.assessment-language-select-border {
  padding: 7px;
}

.assessment-language-container {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: black 3px 3px 9px;
}

.languageSelect {
  color: white;
  line-height: 14pt;
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 16px;
  text-align: left;
}

.languageSelect i {
  margin-left: 15px;
  font-size: 28pt;
  margin: -2pt 10px;
  float: left;
  color: white;
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .assessment-language-select-bar {
    width: 65%;
  }
}

@media (min-width: 767px) and (max-width: 999px) {
  .assessment-language-select-bar {
    width: 80%;
  }
}

#assessment-attempt-view hr {
  margin-top: 0px;
  border: 1px solid #999;
  width: 100%;
}

.assessment-info-bar .dev-icon,
.assessment-info-bar .fa,
.assessment-info-bar .glyphicon {
  font-size: 20pt;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  top: 7px;
}

.assessment-info-bar .dev-icon {
  top: 3px;
  /*font-weight: bold;*/
}

.assessment-attempt-view h2 small.attempt-status {
  margin-left: 15px;
  background: #ddd;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 100;
  font-size: 11pt;
  text-shadow: none;
  text-transform: capitalize;
  position: relative;
  top: -4px;
}

#languages-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#each-languages-container {
  width: 232px;
  float: left;
  display: table-cell;
}

#one-language {
  width: 315px;
  margin-left: 137px;
}

.assessment-language {
  background-color: rgba(0, 0, 0, 0.15);
}

.attempt-status.attempt-status-failed {
  display: inline-block;
  margin: 15px;
  color: #fff !important;
  background: #d00 !important;
}

.attempt-status.attempt-status-succeeded {
  display: inline-block;
  margin: 15px;
  color: #fff !important;
  background: #090 !important;
}

.attempt-status.attempt-status-completed {
  display: inline-block;
  margin: 15px;
}

.attempt-status.attempt-status-pending {
  display: inline-block;
  margin: 15px;
}

.attempt-status.attempt-status-in-progress {
  display: inline-block;
  margin: 15px;
}

.attempt-status.attempt-is-retry {
  display: inline-block;
  margin: 15px;
}

#attempt-score-container {
  margin-top: 90px;
}

#attempt-score-container #userScore {
  height: 350px;
}

#attempt-score-container #groupScore {
  width: 60%;
  height: 60%;
  position: absolute;
  bottom: -22%;
  right: -15%;
}

#attempt-score-container #groupScore partial-doughnut {
  border-radius: 100%;
}

#attempt-score-container #levelsScore > div {
  height: 180px;
}

#attempt-score-container {
  margin-bottom: 120px;
}

#assessment-attempt-view .attempt-status {
  text-shadow: none;
  font-size: 11pt;
  padding: 6px 10px;
  background: #ddd;
  border-radius: 5px;
  color: #333;
  top: -3px;
  position: relative;
  text-transform: uppercase;
  font-family: Roboto;
  margin-left: 15px;
}

#assessment-attempt-view #subcategories-performance {
  padding: 30px 0px;
  overflow: hidden;
  float: none;
  background: rgba(255, 255, 255, 0.025);
}

#assessment-attempt-view #subcategories-performance canvas {
  cursor: pointer;
}

#assessment-attempt-view #attempt-categories-details {
  margin: 0 auto 60px;
  padding: 60px 0 30px;
  background: rgba(255, 255, 255, 0.025);
}

#assessment-attempt-view .attempt-categories-detail-row partial-doughnut {
  height: 200px;
}

#assessment-attempt-view .attempt-categories-detail-row h2 {
  padding-top: 5%;
  line-height: 40px;
}

#assessment-attempt-view #attempt-challenges-performance table thead tr {
  font-size: 115%;
  font-weight: bold;
  border-bottom: 2px solid #e80 !important;
}

#assessment-attempt-view #attempt-challenges-performance table td {
  text-align: center;
  vertical-align: middle;
  padding: 15px 0px;
}

#assessment-attempt-view #attempt-challenges-performance table td .glyphicon {
  font-size: 150%;
}

#assessment-attempt-view #attempt-challenges-performance table td .glyphicon-ok-sign {
  color: #090;
}

#assessment-attempt-view #attempt-challenges-performance table td .glyphicon-remove-sign {
  color: #c00;
}

#assessment-attempt-view #attempt-challenges-performance table td .glyphicon-hourglass {
  color: #f92;
  font-size: 15px;
}

#assessment-attempt-view .assessment-target-label {
  font-size: 11pt;
  text-transform: uppercase;
  padding: 0px;
  margin-top: 2px;
}

#assessment-attempt-view .assessment-target-label .glyphicon-user {
  font-size: 110%;
  background: #ddd;
  color: #333;
  padding: 10px;
  margin-top: 0px;
  top: 0px;
  border-radius: 50%;
}

#assessment-attempt-view .assessment-target-label .glyphicon-chevron-down {
  font-weight: lighter;
  margin-left: 10px;
  top: 2px;
}

#assessment-attempt-view .assessment-target-label span {
  line-height: 15pt;
  float: left;
  text-transform: none;
  text-align: right;
}

#assessment-attempt-view #attempt-categories-performance .explainer {
  text-align: left;
  font-size: 16px;
  margin-bottom: 20px;
}

#assessment-attempt-view #attempt-categories-performance .explainer span {
  color: #c8c8c8;
  padding: 5px 10px;
  padding-left: 0;
  border-radius: 5px;
}

#assessment-attempt-view .attempt-complete-block {
  font-size: 11pt;
  padding: 15px 0 0 0;
}

#assessment-attempt-view .attempt-notice-block {
  background: rgba(255, 255, 255, 0.05);
  padding: 50px;
  text-align: center;
  margin-top: 30px;
  font-size: 12pt;
}

#assessment-attempt-view .attempt-notice-block h3 {
  font-size: 17pt;
  font-weight: bold;
  line-height: 200%;
}

#assessment-attempt-view .attempt-notice-block p {
  margin-bottom: 15px;
  font-size: 15pt;
}

#assessment-attempt-view .attempt-notice-block button {
  font-weight: bold;
  padding-right: 18px;
}

#assessment-attempt-view .attempt-notice-block button i.glyphicon {
  margin: 0px 5px;
  font-size: 110%;
  top: 1.5pt;
}

#assessment-attempt-view .retake-course-text {
  font-size: 20px !important;
  font-weight: 500;
  max-width: 660px;
  text-align: center;
  margin: 24px auto !important;
}

#assessment-attempt-view .retake-course-text a {
  color: #ffb700;
}

#assessment-attempt-view .assessment-target-label .tooltip-inner {
  max-width: inherit;
}

.assessment-challenge-number {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  position: relative;
  top: 20px;
  left: 8px;
}

.assessment-challenge-number.keyboard-selected {
  border-radius: 3px;
  background: #e80;
  color: white;
}

#activeUsers {
  padding: 0px;
}

#activeUsers .chart-legend .donut-legend-control {
  width: 45.75%;
}

#activeUsers .donut-text {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -54px;
  margin-left: -100px;
  z-index: 2;
  width: 200px;
}

.sensei-pluginURL {
  background: #eee !important;
  margin-top: 24px;
}

.senseiadmin.aliases {
  margin-left: 10%;
}

.senseiadmin.languages {
  margin-left: 10%;
}

.sensei-repo-container .sensei-repo {
  color: #fff;
  padding: 15px 0;
  height: 80px;
  margin: 0px 0px;
  background: rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid #555;
  border-left: 3px solid transparent;
  transition: all 0.5s ease-in-out;
}

.sensei-repo-container .sensei-repo .repo-languages {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}

.sensei-repo-container .sensei-repo .download-button-container {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}

.sensei-repo-container .sensei-repo:hover {
  /*border-left: 3px solid #eee;*/
}

.sensei-repo-container .coming-soon {
  line-height: 30px;
}

.sensei-ui-select {
  border: none;
  margin-bottom: 14px;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  border-radius: 0;
}

.sensei-ui-select-choices {
  background-color: white;
}

.team-ui-select {
  border: none;
  width: 30%;
  margin-right: 14px;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  border-radius: 0;
}

.ui-select-choices-btn {
  background: #f5f5f5;
  margin: 10px 0px 0px 0px;
  border-radius: 5px;
  color: #333 !important;
}

.team-ui-select-choices {
  background-color: white;
}

.repo-alias {
  margin-bottom: 10px;
}

.repo-language {
  margin-bottom: 10px;
}

.build-box {
  padding: 20px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.build-box label {
  margin-top: 0px;
}

.build-box .form-control {
  font-size: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.build-box pre {
  font-size: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.build-box textarea {
  min-height: 100px;
}

.build-box .description {
  border: none;
  background-color: #fafafa;
}

.upload-build {
  background-color: #fff;
  border: 2px dashed #cccccc;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.upload-build:hover {
  border: 2px dashed #657d19;
  background-color: #eaeaea;
}

.upload-rule {
  background-color: #fff;
  border: 2px dashed #cccccc;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.upload-rule:hover {
  border: 2px dashed #657d19;
  background-color: #eaeaea;
}

.large-icon {
  font-size: 75px;
}

#uploadBuild .larger-text {
  font-size: 30px;
}

.ide-banner {
  cursor: pointer;
  margin-bottom: 24px;
}

.ide-banner img {
  width: 100%;
  border-radius: 5px;
}

#copyDownloadURL {
  width: 200px;
}

.installation-text {
  width: 65%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 24px;
  margin-top: 24px;
  border-radius: 3px;
}

#timeserieschart .chart-container {
  height: 355px;
  padding-top: 12px;
}

/* since UI bootstrap 2.5.0 popovers don't seem to actually work... */
[uib-popover-popup].popover,
[uib-popover-html-popup].popover,
[uib-popover-template-popup].popover,
[uib-datepicker-popup-wrap] > .uib-datepicker-popup.dropdown-menu {
  display: block !important;
}

[uib-datepicker-popup-wrap] [uib-datepicker] {
  width: 240px;
  overflow: hidden;
}

[uib-datepicker-popup-wrap] .btn.btn-default {
  background: 0;
  padding: 2px 5px;
}

[uib-datepicker-popup-wrap] .btn.btn-default.active {
  border: 1px solid #58ac58;
}

table.uib-timepicker {
  background-color: transparent;
  width: auto;
}

.uib-daypicker .uib-day .btn-info span {
  color: #222;
}

canvas.chart.non-responsive {
  margin: 0 auto;
}

.wider-filter-bar-for-level-grouping {
  margin-left: -72px;
  margin-right: -72px;
}

.disabled-clear-filter-button {
  cursor: default !important;
}

.notice-block {
  background: rgba(255, 255, 255, 0.05);
  padding: 60px 0px 90px 0px;
  text-align: center;
  margin-top: 30px;
  font-size: 12pt;
}

.notice-block h3 {
  text-transform: uppercase;
  line-height: 200%;
}

.notice-block p {
  margin-bottom: 30px;
}

.notice-block button {
  font-size: 100%;
  padding: 10px 18px;
}

.notice-block button i.glyphicon {
  margin: 0px 5px;
  font-size: 110%;
  top: 1.5pt;
}

#user-dashboard {
  margin-bottom: 60px;
}

#user-dashboard .container header {
  margin-top: 20px;
}

#user-dashboard .module-block .entry {
  padding-top: 22px;
}

#user-dashboard .entry .btn-link {
  border: 1px solid #777;
  padding: 5px 10px;
}

#user-dashboard .btn {
  text-transform: uppercase;
}

#user-search-section input {
  width: 400px;
}

#update-profile-view section {
  margin-bottom: 60pt;
}

#update-profile-view h3 .alert-success {
  position: relative;
  top: -2px;
  margin-left: 15px;
  border-radius: 5px;
}

#update-profile-view section hr {
  width: 100%;
  margin: 10px 0px;
  padding: 0px;
}

#update-profile-view .has-error .form-control {
  border-radius: 5px 5px 0px 0px;
}

#update-profile-view .alert-error {
  position: absolute;
  width: 89.5%;
  padding: 5px 10px;
  z-index: 9999;
  margin-top: 0px;
  border-radius: 0px 0px 5px 5px;
}

#update-profile-view .alert-success.ng-hide {
  transform: rotateX(90deg);
}

#update-profile-view .alert-success {
  overflow: hidden;
  opacity: 1;
  z-index: 9999;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 10px;
  height: 30px;
  transition: all 0.5s ease-in-out;
}

.container.thumbnail {
  padding: inherit;
}

.bootstrap-switch {
  border-width: 0px;
}

#update-profile-view .btn {
  padding: 5px 10px;
}

#update-profile-view .btn-link {
  border: 1px solid #777;
}

#update-profile-view .thumbnail {
  border: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-3 {
  margin-left: 15px;
}

.mr-3 {
  margin-right: 15px;
}

#user-dashboard #active-language {
  width: auto !important;
  max-width: none;
  position: relative;
  top: 0px;
  white-space: nowrap;
  display: inline-block;
  clear: both;
  float: left;
}

#user-dashboard #active-language .glyphicon-chevron-down {
  font-size: 80% !important;
  margin-left: 5px;
}

#user-dashboard #active-language:hover {
  color: white;
  transition: 0.2s all ease-in-out;
}

#user-dashboard #active-language .dev-icon {
  font-size: 38px;
  float: left;
  margin-right: 10px;
}

#user-dashboard #active-language .glyphicon-chevron-down {
  font-size: 80% !important;
  margin-left: 5px;
  top: 2px;
}

#user-dashboard header .dev-icon {
  float: left;
  font-size: 37px;
  margin-right: 15px;
}

#user-dashboard section h2 .heading-icon {
  font-size: 20pt !important;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

#user-dashboard section h2 .heading-icon.glyphicon {
  top: 5px;
}

#user-dashboard section h2 .heading-icon.glyphicon-file {
  font-size: 96% !important;
  top: 2px;
}

#user-dashboard section#training {
  margin-top: 0px;
}

#user-dashboard section {
  margin-top: 30px;
}

#user-dashboard h2:first-of-type {
  font-size: 18pt;
  line-height: 23pt;
}

#user-dashboard header h2:first-of-type {
  font-size: 24pt;
  line-height: 24pt;
}

#user-dashboard #training h2 .pull-right {
  margin-top: 3pt;
}

#user-dashboard h2:first-of-type img {
  height: 60px;
  top: -10px;
  position: relative;
  width: 60px;
  border-radius: 50%;
  border: 2px solid #777;
  padding: 5px;
  margin-right: 15px;
}

#user-dashboard hr {
  color: white;
  border: 1px solid #777;
}

#user-dashboard .training-indicators {
  padding: 15px;
}

#user-dashboard .indicator #security-maturity {
  margin-left: -12px;
}

#user-dashboard section#training .indicator {
  font-size: 12pt;
  line-height: 15pt;
  margin-bottom: 0px;
  padding: 30px 30px;
  height: 105px;
}

#user-dashboard section#training .indicator:hover,
#user-dashboard section#training .indicator.keyboard-selected {
  background: rgba(255, 255, 255, 0.05);
  border-bottom: 2px solid white;
}

#user-dashboard section#training .indicator img {
  width: 60px;
  float: left;
  margin-right: 15px;
  position: relative;
  top: -10px;
}

#user-dashboard section#training .indicator strong {
  color: #ffb700;
}

#user-dashboard section#training .indicator .icon {
  font-size: 28pt;
  margin-right: 15px;
  margin-bottom: 15pt;
}

#user-dashboard section#training .indicator:hover .icon {
  color: white;
}

#user-dashboard section hr {
  margin-bottom: 0px;
}

/* @keyframes placeholder {
	0%{
		background-position: -100px 0
	}
	100%{
		background-position: 800px 0
	}
} */

#user-dashboard .module-block .entry.placeholder {
  padding: 15px 10px;
}

#user-dashboard .module-block .entry.placeholder > * > * {
  animation-duration: 10s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 18%,
    rgba(255, 255, 255, 0.2) 48%,
    rgba(255, 255, 255, 0.15) 80%
  ) !important;
  background-size: 800px 104px;
  position: relative;
}

#user-dashboard .module-block .entry.placeholder .status-placeholder {
  width: 56px !important;
  height: 56px !important;
  background: rgba(255, 255, 255, 0.1);
}

@keyframes spinwait {
  50% {
    transform: rotateZ(360deg) scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: rotateZ(720deg) scale(1);
    opacity: 1;
  }
}

#user-dashboard #training .placeholder .icon {
  animation: spinwait;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

#user-dashboard #training .placeholder strong,
#user-dashboard #training .placeholder span.detail {
  animation-duration: 10s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 18%,
    rgba(255, 255, 255, 0.2) 48%,
    rgba(255, 255, 255, 0.15) 80%
  ) !important;
  background-size: 800px 104px;
  position: relative;
  color: transparent !important;
  border-radius: 5px;
}

#user-dashboard .module-block .entry.placeholder strong:before,
#user-dashboard .module-block .entry.placeholder span:before {
  content: 'THIS IS JUST A PLACEHOLDER';
  background: rgba(255, 255, 255, 0.1);
  width: 100% !important;
  color: transparent;
  border-radius: 5px;
}

#user-dashboard .module-block .entry.placeholder button {
  background: rgba(255, 255, 255, 0.1);
  color: transparent !important;
  text-shadow: none;
}

#user-dashboard .module-block .entry {
  color: #fff;
  padding: 22px 5px 15px;
  margin: 0px 0px;
  background: rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid #555;
  border-left: 3px solid transparent;
}

#user-dashboard .module-block .dual-size-entry {
  padding-top: 46px;
  padding-bottom: 38px;
}

#user-dashboard .module-block .entry strong {
  color: #ccc;
  text-decoration: none;
}

#user-dashboard .module-block .entry:hover strong,
#user-dashboard .module-block .entry.keyboard-selected strong {
  transition: all 0.2s ease-in-out;
  color: white;
}

#user-dashboard .module-block .entry:hover,
#user-dashboard .module-block .entry.keyboard-selected {
  border-left: 3px solid #eee;
  transition: all 0.5s ease-in-out;
  outline: none;
}

*:focus {
  outline: none;
}

#user-dashboard .module-block strong {
  text-transform: capitalize;
  font-size: 130%;
}

#user-dashboard .module-block .entry .btn {
  position: relative;
  top: 0px;
}

#user-dashboard .module-block .entry .icon {
  font-size: 20pt;
  float: left;
  margin-left: 0px;
  margin-right: 20px;
  padding: 15px 15px 15px 15px;
  border-radius: 50%;
  font-weight: 100 !important;
  position: relative;
  top: -5px;
}

#user-dashboard .module-block .icon.status-upcoming,
#user-dashboard .module-block .icon.status-not.started {
  font-size: 26pt;
  padding: 10px 13px !important;
}

#user-dashboard .module-block .entry .icon.status-pending,
#user-dashboard .module-block .entry .icon.status-in_progress,
#user-dashboard .module-block .entry .icon.status-active,
#user-dashboard .module-block .entry .icon.status-enabled {
  background: #e80;
  color: white;
}

#user-dashboard .nav-tabs .active a {
  background: #1c1c1c;
  border-radius: 3px 3px 0px 0px !important;
  outline: none;
}

#user-dashboard .module-block .entry .icon.status-upcoming,
#user-dashboard .module-block .entry .icon.status-not.started {
  background: #e80;
  color: #fff;
}

#user-dashboard .module-block .entry .icon.status-failed {
  background: #b33;
  color: #fff;
  width: 56.65px;
  text-align: center;
}

#user-dashboard .module-block .entry .icon.status-failed_no_retries {
  background: #b33;
  color: #fff;
  width: 56.65px;
  text-align: center;
}

#user-dashboard .module-block .entry .icon.status-expired {
  background: #a00;
}

#user-dashboard .module-block .entry .icon.status-done,
#user-dashboard .module-block .entry .icon.status-finished,
#user-dashboard .module-block .entry .icon.status-closed {
  background: #5a0;
  color: white;
}

#user-dashboard .module-block .entry .icon.status-disabled {
  background: #ddd;
  color: #333;
  padding-left: 13pt;
  padding-right: 13pt;
}

#user-dashboard .module-block .notice-block {
  display: inline-block;
  text-align: left;
  padding: 5%;
  margin: 0px !important;
  min-height: 212pt;
  position: relative;
  color: white;
  border-bottom: 1px solid #555;
  width: 100%;
}

#user-dashboard .module-block .notice-block .btn-link {
  padding: 0px;
}

#user-dashboard .module-block .status-label {
  font-size: 9pt;
  border-radius: 5px;
  padding: 3px 5px 3px 0px;
  position: relative;
  margin-right: 10px;
  text-transform: uppercase;
  color: #e80;
  float: left;
}

#user-dashboard .module-block .status-label.pending,
#user-dashboard .module-block .status-label.in_progress,
#user-dashboard .module-block .status-label.fail {
  color: #c00;
}

#user-dashboard .module-block .status-label.disabled {
  color: #ddd;
}

#user-dashboard .module-block .status-label.completed,
#user-dashboard .module-block .status-label.done,
#user-dashboard .module-block .status-label.closed,
#user-dashboard .module-block .status-label.finished,
#user-dashboard .module-block .status-label.success {
  color: #5a0;
}

#user-dashboard .module-block .notice-block .entry-info {
  margin: 15px 0px;
}

#user-dashboard .module-block .notice-block .entry-info-item {
  padding: 2pt 0px;
}

#user-dashboard .module-block .notice-block .entry-info-item .fa,
#user-dashboard .module-block .notice-block .entry-info-item .glyphicon {
  margin-right: 10px;
}

#user-dashboard .module-block .notice-block h3 {
  text-transform: none;
}

#user-dashboard .module-block .notice-block .pulse {
  animation-iteration-count: 3 !important;
  animation: pulse;
  animation-duration: 1s;
  animation-delay: 4s;
}

#user-dashboard .module-block .notice-block div.pull-left {
  width: 70%;
}

#user-dashboard .module-block .notice-block .icon {
  font-size: 64pt;
  color: white;
  margin: 0px;
  margin-right: 15px;
  border-radius: 50%;
  border: 2px solid transparent;
  padding: 15px 18px;
  margin-left: 0px;
  width: 25%;
}

#user-dashboard .module-block .notice-block h3 {
  line-height: 18pt;
}

#user-dashboard .module-block .notice-block button {
  font-size: 90%;
}

.vertical-flip {
  transform: rotateZ(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.topbar-user-label {
  font-size: 11pt;
  text-transform: uppercase;
  padding: 0px;
  margin-top: 2px;
}

.topbar-user-label .showprofile {
  font-size: 110%;
  color: #333;
  margin-top: 0px;
  top: -7px;
  border-radius: 50%;
  width: 55px;
  height: 51px;
  right: -6px;
  background: gray;
}

.topbar-user-label .glyphicon-chevron-down {
  font-weight: lighter;
  margin-left: 10px;
  top: 2px;
}

.topbar-user-label span {
  line-height: 15pt;
  float: left;
  text-transform: none;
  text-align: right;
}

#user-dashboard .btn-link {
  text-transform: uppercase;
}

.vertical-space {
  height: 60px;
}

.vertical-space-half {
  height: 30px;
}

#user-dashboard .dashboard-management {
  background: rgba(0, 0, 0, 0.3);
  padding: 5pt 20pt 20pt;
  width: 100%;
  display: inline-block;
  border-radius: 5px;
}

#user-dashboard .dashboard-management .user-onboarding-msg h1 {
  color: #ffb700;
  font-size: 36px;
}

#user-dashboard .dashboard-management .user-onboarding-msg h2 {
  color: #ffb700;
  font-size: 30px;
}

#user-dashboard .dashboard-management .user-onboarding-msg h3 {
  color: #ffb700;
  font-size: 24px;
  text-transform: none;
  line-height: normal;
}

#user-dashboard .dashboard-management .user-onboarding-msg h4 {
  color: #ffb700;
  font-size: 18px;
}

#user-dashboard .dashboard-management .user-onboarding-msg h5 {
  color: #ffb700;
  font-size: 14px;
  font-weight: normal;
  margin: none;
}

#user-dashboard .dashboard-management .user-onboarding-msg h6 {
  color: #ffb700;
  font-size: 12px;
}

@media screen and (max-width: 15cm) {
  #user-dashboard h2:first-of-type {
    font-size: 15pt;
    line-height: 23pt;
  }

  #user-dashboard section *[ng-repeat] .btn {
    margin-left: 3pt;
  }

  #user-dashboard .dashboard-management {
    width: 106% !important;
    position: relative;
    left: -3% !important;
    margin: 0px !important;
    padding: 0px;
  }

  #user-dashboard .dashboard-management h2 {
    padding: 0pt 12pt;
  }
}

#user-dashboard #onboarding .enabled,
#user-dashboard #onboarding .pending,
#user-dashboard #onboarding .recently-onboarded,
#user-dashboard #onboarding .recently-active {
  padding: 20pt 0pt 15pt;
  font-size: 30pt;
  line-height: 30pt;
  text-align: center;
  color: white;
}

#user-dashboard .company-overview {
  margin-left: 0;
  margin-right: 0;
}

#user-dashboard #onboarding small {
  line-height: 0pt !important;
  font-size: 11pt;
}

#user-dashboard h2:first-child .pull-right .btn {
  text-transform: uppercase;
  margin-top: 0px;
}

#user-dashboard h2:first-child .pull-right .btn-link {
  border: 1px solid #777;
  padding: 5px 10px;
}

#assessment-tabs .nav-tabs {
  font-size: 16px;
}

#assessment-tabs .nav-tabs > li > a {
  color: #c8c8c8;
}

#assessment-tabs .nav-tabs > li.active > a {
  color: #dd9935;
  background-color: #1c1e22;
  border: 1px solid #0c0d0e;
}

#invite-tabs .nav-tabs > li > a {
  color: #c8c8c8;
  border-radius: 4px;
}

#invite-tabs .nav-tabs > li.active > a {
  color: #dd9935;
}

.assessment-tab-pane {
  padding: 15px 15px;
}

.show-each-language {
  margin-top: 12px;
}

.tab-pane-summary {
  margin-top: 5px;
  margin-bottom: 20px;
}

.sa-button-container {
  margin-top: 20px;
}

.sa-confirm-button-container button.confirm {
  background-color: var(--color-scw-orange) !important;
  color: black;
}

.sa-button-container button.cancel {
  background-color: rgba(158, 158, 158, 0.2) !important;
  color: black;
}

.sweet-alert button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  padding: 0 8px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition:
    box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.sweet-alert button {
  text-shadow: none;
  font-weight: bold;
  text-transform: lowercase;
  border: none;
  border-radius: 4px;
  min-width: 44px;
  padding: 0 15px;
  margin: 6px 4px;
  font-family:
    Open Sans,
    sans-serif;
  box-shadow: none;
}

.sweet-alert button:hover {
  opacity: 0.8;
}

.sweet-alert button span {
  display: inline-block;
}

.sweet-alert button::first-letter,
.sweet-alert button span::first-letter {
  text-transform: uppercase !important;
}

.bootstrap-switch-small.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch-small.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch-small.bootstrap-switch .bootstrap-switch-label {
  vertical-align: middle;
}

/* Internet Explorer CSS */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tournament-edit-form .bootstrap-switch-small.bootstrap-switch .bootstrap-switch-handle-off,
  .tournament-edit-form .bootstrap-switch-small.bootstrap-switch .bootstrap-switch-handle-on,
  .tournament-edit-form .bootstrap-switch-small.bootstrap-switch .bootstrap-switch-label {
    line-height: 0.1;
  }

  table.leaderboard-table {
    table-layout: auto;
  }
}

.intro-walkthrough {
  background: rgba(0, 30, 50, 0.9);
  padding: 15px 25px;
  border-radius: 5px;
  color: #fff;
  font-size: 12pt;
}

.intro-walkthrough .introjs-prevbutton,
.intro-walkthrough .introjs-skipbutton,
.intro-walkthrough .introjs-disabled {
  display: none;
}

.new-simple-flow-quest .intro-walkthrough .introjs-skipbutton {
  display: inline-block;
}

.new-simple-flow-done-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.intro-walkthrough .introjs-donebutton {
  display: inline-block;
}

.intro-walkthrough .introjs-button,
.intro-walkthrough .introjs-button:hover {
  background: transparent;
  font-size: 10pt;
  text-shadow: none;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: none;
}

.intro-walkthrough .introjs-button:hover {
  background: #e80;
  border: 1px solid #e80;
  color: white;
}

.intro-walkthrough.force-interaction .introjs-button,
.intro-walkthrough.force-interaction .introjs-donebutton {
  display: none !important;
}

#user-dashboard #completed-attempts {
  margin-top: 30px;
}

#user-dashboard .completed-attempts {
  display: inline-block;
  padding-top: 15px;
  width: 100%;
}

#user-dashboard .completed-attempts .attempt {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid transparent;
  border-radius: 3px;
  height: 85pt;
  margin-top: 15px;
}

#user-dashboard .attempt .attempt--view {
  display: block;
  position: relative;
  height: 100%;
}

#user-dashboard .completed-attempts .attempt:hover {
  border-bottom: 1px solid white;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.5s ease-in-out;
}

#user-dashboard .completed-attempts .attempt .user-pic {
  display: inline-block;
}

#user-dashboard .completed-attempts .attempt img {
  width: 38pt;
  height: 38pt;
  padding: 3px;
  position: relative;
  float: none;
  border-radius: 50%;
  border: 1px solid #555;
}

#user-dashboard .completed-attempts .attempt .attempt-info {
  padding-left: 20pt;
}

#user-dashboard .module-block .attempt .user-info {
  overflow: hidden;
}

#user-dashboard .module-block .attempt .user-info span {
  break-word: nowrap;
  white-space: nowrap;
}

#user-dashboard .notice-block button {
  padding: 10px 15px;
  font-size: 11pt;
}

#user-dashboard .notice-block form .input-group {
  width: 100%;
}

#user-dashboard .notice-block form input,
#user-dashboard .notice-block form .input-group input:focus {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  width: 100% !important;
  color: #ddd;
  padding: 3px;
}

#user-dashboard .notice-block form .input-group label {
  position: absolute;
  top: 8px;
  left: 2px;
  font-weight: normal;
  width: 100%;
  text-transform: uppercase;
  font-size: 10pt;
  transition: all 0.2s ease-in-out;
}

#user-dashboard .notice-block form .input-group input:focus + label,
#user-dashboard .notice-block form .input-group input:valid + label {
  color: #e80;
  top: -15px;
  text-transform: uppercase;
  font-size: 80%;
  transition: all 0.2s ease-in-out;
}

#senseiToastyContainer h3 {
  margin: 0px;
  font-size: 16pt;
  line-height: 24pt !important;
}

#senseiToastyContainer {
  transition: all 0.5s ease-in-out;
  position: fixed;
  background: #f38b00;
  color: white;
  bottom: -50px;
  left: 50px;
  width: 450px;
  border-radius: 15px;
  padding: 15px 30px 15px 15px;
  opacity: 0;
  z-index: 9999999;
}

#senseiToastyContainer.active:hover {
  transition: all 0.2s ease-in;
  opacity: 0.5;
}

#senseiToastyContainer.active {
  transition: all 0.5s ease-in-out;
  bottom: 50px;
  opacity: 1;
}

#senseiToastyImage {
  float: left;
  width: 110px;
  border-radius: 50%;
  background-color: #272b30 !important;
  background: url(/public/images/sensei-cartoon.png);
  background-size: 162px;
  background-position: -27px -24px;
  background-repeat: no-repeat;
  height: 100px;
  margin-right: 30px;
}

#senseiToastyMessage {
  font-size: 11pt;
}

#trainingChallenge-button-back {
  top: -2px;
  position: relative;
}

#trainingChallenge-button-back .fa {
  padding: 1px 2px;
  top: 0px;
  font-size: 90%;
  position: relative;
}

.tournament-edit-form {
  padding: 45px;
}

.tournament-edit-form .picPicker .glyphicon {
  font-size: 60pt;
  font-weight: 100;
  margin-bottom: 30px;
}

.tournament-edit-form .picPicker:hover .glyphicon {
  text-shadow: 0px 0px 25px #e80;
}

.tournament-edit-form .picPicker:hover {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  color: #eee;
}

.tournament-edit-form .picPicker {
  opacity: 0.7;
  top: -8px;
  position: relative;
  padding: 0px;
  color: #eee;
  font-family: Verdana;
  text-align: center;
  margin-bottom: 10px;
}

.tournament-edit-form .picPicker .drop-box {
  padding: 70px 50px;
  border-radius: 10px;
}

.tournament-edit-form .form-group {
  margin-bottom: 45px;
}

.float-right {
  float: right;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.align-items-center {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.float-right-margin {
  float: right;
  margin: 5px;
}

.float-left-margin {
  float: left;
  margin: 5px;
}

.tournament-edit-form .form-group label {
  font-size: 100%;
}

.tournament-edit-form .bootstrap-switch-small.bootstrap-switch {
  border-radius: 0px;
}

.tournament-edit-form .bootstrap-switch-small.bootstrap-switch * {
  border-radius: 0px;
  margin: 0px;
}

.tournament-edit-adv-options {
  padding-right: 30px;
  padding-bottom: 15px;
}

.tournament-edit-form .form-control.ui-select-container.ui-select-multiple {
  height: auto;
}

.tournament-edit-action-buttons {
  position: absolute;
  right: 15px;
  top: 60px;
}

.tournament-edit-action-buttons div .md-button {
  text-transform: capitalize;
  margin-left: 0;
  margin-right: 0;
}

.tournament-edit-form .form-group .btn-tournament {
  top: -3px;
  position: relative;
}

.tournament-edit-form .has-error textarea,
.tournament-edit-form .has-error input {
  box-shadow: none !important;
  border-color: #e40;
}

.tournament-edit-form .error-messages .alert {
  position: absolute;
  padding: 2px 10px;
  font-size: 90%;
  font-weight: bold;
  left: 45px;
  opacity: 0;
}

.tournament-edit-form .has-error .error-messages .alert {
  left: 15px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.tournament-edit-form .input-group {
  width: 100%;
}

.tournament-edit-form .panel {
  border-radius: 5px;
  border: 1px solid #333;
  background: transparent;
}

.tournament-edit-form .panel-default .panel-heading h4 {
  font-size: 100% !important;
  font-weight: bold;
}

.panel-default .panel-heading {
  border-radius: 5px 5px 0px 0px;
  font-size: 100% !important;
  background: transparent;
  border: 0px !important;
  margin: 0px;
  padding: 0px 5px;
  background: #333;
  color: #fff;
  padding: 10px 15px;
}

.panel-default .panel-heading .icon {
  font-size: 110%;
  padding: 10px 10px 10px;
  margin-right: 5px;
  position: relative;
  top: 0px;
  border-right: 1px solid #777;
}

.tournament-edit-form .panel-default .panel-heading a:hover,
.tournament-edit-form .panel-default .panel-heading a:focus {
  color: inherit;
  text-decoration: none;
}

.tournament-edit-form .panel-default .panel-body {
  padding: 30px 15px 0px 30px;
}

.tournament-edit-form #rulesText {
  height: 150px;
  background: rgba(255, 255, 255, 0.05);
}

#platform-user-search .input-group-addon,
#company-user-search .input-group-addon {
  width: 36px;
}

.input-group-addon.cancel-button,
#company-user-search .cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-scw-gray-light);
  width: 36px;
}

#user-search-section input {
  width: 400px;
}

.uib-timepicker .form-control {
  padding: 8px;
}

.tab-content .action-header {
  padding: 8px 8px 8px 2px;
}

.active-mission-table .active-mission-button {
  padding: 0px 8px 0px 8px;
}

.span-normal-white-spacing {
  white-space: normal;
}

/* Hack for in-between small and medium bootstrap containers */
@media (min-width: 700px) and (max-width: 1200px) {
  #user-dashboard #training .container {
    width: 98%;
  }

  #user-dashboard .col-sm-6 {
    width: 50%;
  }

  #user-dashboard .col-sm-12 {
    width: 100%;
  }
}

.assessment-edit-form .assessment-accordion .panel-group {
  margin-top: -15px;
  margin-bottom: 0px;
}

.advanced-options-accordion-panel {
  border: 2px solid #484e55;
  background: #1c1f22;
}

.assessment-edit-form .advanced-options-accordion-panel .panel-heading {
  background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
}

.assessment-edit-form .advanced-options-accordion-panel .panel-heading .icon {
  color: #ffb700;
  padding-right: 5px;
  border-right: 2px solid #777;
}

.full-width {
  width: 100%;
}

.bulk-invite-error-row {
  margin-top: 15px;
}

#assessment-actions {
  word-wrap: normal;
  direction: rtl;
}

#leaderboard-panel-points {
  word-wrap: normal;
  direction: rtl;
}

#repo-general .input-group,
#repo-general .input-group .form-control {
  z-index: auto;
}

.inline-label {
  line-height: 24pt;
}

.modal-scw .modal-content {
  background: #111;
  border-radius: 3pt;
  font-size: 11pt;
  font-family: inherit;
  border-color: #444;
}

.modal-scw .modal-header {
  font-size: 12pt;
  border-color: #444;
  color: #f38b00;
  text-transform: capitalize;
  font-weight: bold;
}

.modal-scw .modal-footer {
  border-color: #444;
}

.modal-scw {
  background: rgba(0, 0, 0, 0.5);
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.border-0 {
  border: none;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mb-1 {
  margin-bottom: 5px;
}

.mt-1 {
  margin-top: 5px;
}

.ml-1 {
  margin-left: 5px;
}

.mr-1 {
  margin-right: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-top: 10px;
}

.ml-2 {
  margin-left: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mt-3 {
  margin-top: 15px;
}

.ml-3 {
  margin-left: 15px;
}

.mr-3 {
  margin-right: 15px;
}

.mb-4 {
  margin-bottom: 15px;
}

.mt-4 {
  margin-top: 15px;
}

.ml-4 {
  margin-left: 15px;
}

.mr-4 {
  margin-right: 15px;
}

.team-selector {
  max-width: 200px;
}

.team-selector > .ui-select-dropdown {
  display: flex;
  flex-direction: column;
}

.team-selector > .ui-select-dropdown > .search-container {
  display: flex;
}

.developer-summary-container {
  background-color: #1c1e22;
  border: 1px solid #0c0d0e;
  padding: 0;
  border-radius: 4px;
  margin-top: 10px;
}

.developer-summary-header {
  padding: 16px 16px 0 16px;
}

.developer-summary-block {
  padding: 0 16px 16px 16px;
}

.developer-summary-block .item {
  font-size: 18px;
  padding: 5px 10px;
  margin: 3px;
  background: #262a2f;
  height: 35px;
}

.developer-summary-block .item button {
  padding: 3px 5px;
  border-radius: 0;
}

.assessment-info {
  background-color: #1c1e22;
  border: 1px solid #0c0d0e;
  padding: 16px;
  margin: 25px;
  display: table-cell;
  width: 50%;
}

.assessment-info:first-child {
  border-right: 0;
  border-radius: 4px 0px 0px 4px;
}

.assessment-info:last-child {
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}

.assessment-attempt-info {
  background-color: #1c1e22;
  border: 1px solid #0c0d0e;
  border-radius: 4px;
  padding: 5px;
}

.assessment-attempt-info .item,
.assessment-info .item {
  white-space: nowrap;
  font-size: 18px;
  padding: 5px;
  margin: 3px;
  background: #262a2f;
  height: 35px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.assessment-attempt-info .item i,
.assessment-info .item i,
.assessment-info .item .ScwIcon {
  font-size: 15px;
  height: 21px;
  width: 21px;
  text-align: center;
}

.assessment-attempt-info .item span,
.assessment-info .item span {
  font-size: 18px;
}

.assessments-view-container .table-sorter,
.assessments-invite-container .table-sorter {
  display: inline-block;
  position: relative;
  top: 2px;
}

.col-container {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}

.col-assessment-attempt-info {
  display: table-cell;
  width: 33%;
}

.col-assessment-attempt-info .item .label {
  background-color: #5a5d61;
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 10px;
  padding-left: 0px;
  display: inline;
  margin-left: 5px;
}

.col-assessment-attempt-info .item .label span {
  font-size: 14px;
  text-transform: uppercase;
}

.col-assessment-attempt-info .item .label i {
  font-size: 12px;
  height: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

/*IE pagination bug fix (affects all occurences of pagination)*/
.pagination > .disabled > a {
  pointer-events: none;
}

.edit-user-sso-notice {
  font-style: italic;
  float: right;
}

@keyframes placeholder {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.custom-checkbox input[type='checkbox'] {
  opacity: 0;
}

.custom-checkbox label {
  position: relative;
  display: inline-block;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
  right: 4px;
  bottom: 9px;
}

.custom-checkbox label::before,
.custom-checkbox label::after {
  position: absolute;
  content: '';

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.custom-checkbox label::before {
  height: 16px;
  width: 16px;

  border: 1px solid;
  left: 0px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
	*to vertically center it.
	*/
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.custom-checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;

  transform: rotate(-45deg);

  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.custom-checkbox input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.custom-checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.custom-checkbox input[type='checkbox']:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.quests-active {
  font-family: monospace;
  font-size: 12px;
}

.quests-readonly {
  font-family: monospace;
  font-size: 12px;
  background-color: #d3d3d3;
  color: #696969;
}

.tournament-autohide-mins {
  max-width: 50%;
}

.tournament-increment-picker {
  background-color: transparent;
  width: auto;
}

.no-increment-buttons::-webkit-outer-spin-button,
.no-increment-buttons::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-increment-buttons {
  -moz-appearance: textfield;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.max-width-75 {
  max-width: 75px;
}

.max-width-200 {
  max-width: 200px;
}

.ml-1 {
  margin-left: ($ spacer * 0.25) !important;
}

.ml-2 {
  margin-left: ($ spacer * 0.5) !important;
}

.mr-1 {
  margin-right: ($ spacer * 0.25) !important;
}

.mr-2 {
  margin-right: ($ spacer * 0.5) !important;
}

.sample-show-hide {
  max-height: 100px;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}

.sample-show-hide.ng-hide {
  max-height: 0px;
  overflow: hidden;
}

.tournament-edit-sm-mb {
  margin-bottom: 15px !important;
}

.text-muted-important {
  color: #7a8288 !important;
}

.outerBox {
  width: 100%;
  height: 50%;
  display: inline-block;
  padding: 10px;
  text-align: center;
}

.sweet-alert,
.sweet-overlay {
  z-index: 9999999;
}

.disable-element {
  pointer-events: none;
  opacity: 0.5;
}

.assessment-template-heading {
  color: #ffffff;
  background: #3f444a;
  padding: 15px 5px;
  text-align: center;
}

.quest-progress-bar {
  margin-top: 15px;
}

.colour-box-text-v-game-custom {
  color: #fff;
  padding: 12px 20px;
  margin: 0px 0px 0px 0px;
  background-color: #000;
}

.colour-box-text-v-custom {
  color: #fff;
  padding: 12px 20px;
  margin: 0px 0px 0px 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.rankTest {
  font-size: 30px;
  font-weight: 500;
}

.rankTestDev {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.pointsText {
  font-size: 30px;
  font-weight: 500;
  display: inline-block;
  margin: 40px 5px 40px 5px;
}

.pointsTextDev {
  font-size: 30px;
  font-weight: 500;
  display: inline-block;
  margin: 40px 5px 40px 5px;
  color: white;
}

#circle {
  width: 150px;
  height: 150px;
  background-color: rgba(204, 0, 102, 0);
  -moz-border-radius: 75px;
  -webkit-border-radius: 75px;
  border-radius: 75px;
  display: inline-block;
  border: 10px solid #ffb700;
}

.symbolCustom {
  font-size: 20px;
  color: #ffb700;
}

.colour-box-dev {
  width: 99%;
  padding: 0 0 100px 0;
  margin: 5px 0.5% 5px 0.5%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.security-maturity-box-metrics-dev {
  height: 600px;
}

.relative-dev {
  height: 450px;
  position: relative;
}

tournaments-stats-mini .worldmap-stats {
  top: 80%;
  transform: translateX(210px);
  background-color: rgba(0, 30, 50, 0.5);
  cursor: pointer;
}

tournaments-stats-mini .worldmap-stats.partial {
  right: -210px;
  opacity: 1;
}

tournaments-stats-mini .worldmap-stats.active,
tournaments-stats-mini .worldmap-stats:hover {
  right: 0px;
  opacity: 1;
  transform: none;
  background-color: rgba(0, 30, 50, 0.9);
}

assessment-header {
  display: inline-block;
  width: 100%;
}

assessment-header .progress {
  height: 25px;
  vertical-align: middle;
}

assessment-header .progress .progress-bar-text {
  font-size: 11pt !important;
  font-weight: bold;
  color: #eee;
  padding: 3px;
}

assessment-header .assessment-progress {
  padding: 10px;
}

.ui-select-container[theme='selectize'] {
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 30px;
}

.ui-select-container[theme='selectize'] .ui-select-match {
  top: 3px;
  left: 2px;
  position: relative;
  border-bottom: 1px solid;
}

/* Handle up direction Selectize */
.ui-select-container[theme='selectize'].direction-up .ui-select-dropdown {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  background-color: #1c1c1c;
  padding: 5px;
}

.ui-select-container[theme='selectize'].selectize-control > .selectize-dropdown {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
  background-color: #1c1c1c;
  color: #c8c8c8;
  z-index: 10;
  padding: 5px;
}

/* .keyboard-group-selected {
	outline: none !Important;
	border: none !important;
	box-shadow: 0px 0px 10px 3px orange !important;
	-webkit-appearance:none;
} */

.container-fluid--link:focus {
  color: var(--color-scw-orange-light);
}

.clear {
  clear: both;
}

.checkbox-inline {
  padding: 0px;
  margin-left: 15px;
  border-radius: 5px;
}

.checkbox-inline.keyboard-selected,
.checkbox-inline:focus,
input.keyboard-selected,
input:focus:not([class*='MuiInputBase']):not(.ds-focus),
.select2-choice.keyboard-selected,
.select2-choice:focus,
textarea.keyboard-selected,
textarea:focus:not(.MuiInputBase-input),
.btn.keyboard-selected,
.btn:focus,
.sweet-alert .btn.keyboard-selected,
.sweet-alert .btn:focus,
.select2-choices input.keyboard-selected {
  outline: none !important;
  border: none !important;
  box-shadow: 0px 0px 0px 3px #e80 !important;
}

.sweet-alert .btn {
  margin: auto;
}

.activity-slider-container {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.activity-slider.rzslider .rz-bar {
  background: #757575;
  height: 2px;
}

.activity-slider.rzslider .rz-selection {
  background: #a57e1a;
}

.activity-slider.rzslider .rz-pointer {
  width: 8px;
  height: 16px;
  top: auto; /* to remove the default positioning */
  bottom: 0;
  background-color: #ffb700;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.activity-slider.rzslider .rz-pointer:after {
  display: none;
}

.activity-slider.rzslider .rz-bubble {
  bottom: 14px;
  color: white;
}

.activity-slider.rzslider .rz-limit {
  /* font-weight: bold; */
  color: lightgrey;
}

.activity-slider.rzslider .rz-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: #ffe4d1;
  top: -1px;
}

.activity-slider.rzslider .rz-tick.rz-selected {
  background: orange;
}

.activity-total {
  color: white;
  min-height: 3em;
  margin-bottom: auto;
  text-align: right;
  font-size: medium;
  margin-right: 2em;
}

.select2 .disabled {
  opacity: 0.5;
}

search-widget .form-group {
  margin: 0px;
}

#ng-swal_content section {
  margin: 0;
  padding: 10px 0 10px 0;
}

.repo-general--coming-soon {
  width: 16px !important;
  height: 16px !important;
}

.keyboard-selected {
  box-shadow: 0px 0px 0px 3px #e80 !important;
  transition: box-shadow 0.3s linear;
  outline: 0px !important;
  border-left: 2px solid #e80;
}

table tbody tr.keyboard-selected {
  box-shadow: none;
}

button.keyboard-selected {
  background: #e80;
  border: #e80;
}

challenge {
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.management-keyboard-navigation-help {
  margin-bottom: 20px !important;
}

.management-keyboard-navigation-help--shortcut {
  margin-bottom: 5px !important;
}

.uppercase {
  text-transform: uppercase;
}

.tournament-edit-quest-level.keyboard-selected,
.tournament-edit .challenge-result.keyboard-selected {
  padding: 5px;
  background: #e80;
  color: white;
}

.tournament-edit .challenge-result.keyboard-selected .tournament-quest-log-stage-info-container {
  color: white;
}

.tournament-edit-quest-level.keyboard-selected .icon {
  color: white;
}

#company-admin-actions-bar .metrics {
  margin-right: 10px;
}

#company-admin-actions-bar .more {
  margin-top: 10px;
}

.admin-advance-options {
  padding: 20px !important;
}

.admin-advance-options--intro {
  margin-bottom: 10px !important;
  font-size: 18px !important;
  color: #5bc0de !important;
}

.admin-other-options-dialog .option-container {
  margin-top: 2em;
}

.admin-other-options-dialog .option {
  display: block;
  background: #eee;
  padding: 1em;
  height: calc(100%);
  border-radius: 0.5em;
  /* height: 120px; */
}

.admin-other-options-dialog .option .icon {
  font-size: 2em;
  margin-bottom: 0.4em;
}

.admin-other-options-dialog .option.keyboard-selected,
.admin-other-options-dialog .option:hover {
  background: var(--color-scw-orange);
  color: black;
  box-shadow: none;
  transform: scale(1.05);
}

.bootstrap-switch-label.keyboard-selected {
  background: #e80;
}

.keyboard-navigation-help {
  font-size: 12px;
  font-family: verdana, sans-serif;
  padding: 0.5em;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px 5px 0px 0px;
  position: fixed;
  background: #fff;
  bottom: 0px;
  z-index: 10999;
  transform: translateX(-50%);
  left: 50%;
  opacity: 0;
  bottom: -100px;
  width: 90%;
  transition: 0.5s all ease-in-out;
  box-shadow: 0px 0px 3px 2px #ccc;
  border-bottom: none;
}

.keyboard-navigation-help-enabled .keyboard-navigation-help {
  opacity: 1;
  bottom: 0px;
  transition: 0.5 all ease-in-out;
  font-family: verdana;
}

.keyboard-navigation-help-entry {
  background: white;
  color: #333;
  padding: 5px;
  float: left;
  margin-right: 1em;
}

.keyboard-navigation-help-entry kbd {
  position: relative;
  padding: 2px 5px 2px 5px;
}

kbd {
  font-family: verdana, sans-serif;
  font-weight: bold;
  font-size: 1em;
}

.only-when-focused {
  display: none;
}

:focus .only-when-focused {
  display: block;
}

/* .keyboard-navigation-help-enabled #us_report_button,
.keyboard-navigation-help-enabled #Support,
.keyboard-navigation-help-enabled .tutorial-button {
	display: none !important;
} */

.sweet-alert button.keyboard-selected {
  background: #e80 !important;
  box-shadow: 0px 0px 0px 5px #e80 !important;
}

@media (max-width: 1199px) {
  .assessments-list_items-per-page {
    visibility: hidden;
  }
}

.leaderboard-slider-buttons {
  border: none;
  color: #dd9935;
  font-size: 12px;
  background: transparent;
  padding: 0;
  position: relative;
  top: 10px;
}

.leaderboard-slider-buttons:hover {
  color: white;
}

.autoscrollBtn {
  position: relative;
  top: 22px;
  right: -138px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 0px 10px;
  color: #fff;
  white-space: nowrap;
  margin-left: 30px;
  height: 25px;
}

.wave-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.wave-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.wave-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.wave-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.container.manager-metrics-courses {
  width: 100% !important;
  padding: 0 10%;
  margin: 0;
}

.badge-no-text {
  position: absolute;
  top: 5px;
  right: 18px;
  border-radius: 25px;
  background: #ec5252;
  color: white;
  width: 12px;
  height: 12px;
}

.modal-level-group > .modal-dialog > .modal-content {
  background-color: #25292e;
  border: none;
}

.modal-new-features {
  z-index: 5000 !important;
}

.modal-new-features > .modal-dialog {
  height: 600px;
}

.modal-new-features > .modal-dialog > .modal-content {
  background-color: white;
  border: none;
}

.iframe-container-assessments {
  position: relative;
  height: 480px;
  width: 100%;
  overflow: hidden;
  background: transparent;
}

.iframe-container-new-features {
  position: relative;
  min-height: 585px;
  width: 100%;
  overflow: hidden;
  background: transparent;
}

.iframe-container-new-features > iframe {
  visibility: hidden;
}

.iframe-container-courses,
.iframe-container-adv,
.iframe-container-tournaments {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.iframe-container-courses,
.iframe-container-adv {
  height: calc(100vh - 64px);
}

.iframe-container-tournaments {
  height: calc(100vh - 64px - 80px);
}

.iframe-container-product-trial iframe {
  position: absolute;
  top: 0;
  overflow: hidden;
  border: 0;
  height: 100%;
  width: 100%;
}

.iframe-container-challenge-player {
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  /*
  The bottom most row of pixels is missing when bottom is set to 0.
  I have no fucking clue why, but setting bottom to 1px fixes it.
   */
  bottom: 1px;
  overflow: hidden;
}

.challenge-breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #15141e;
  height: 52px;
  padding: 22px 20px 20px 20px;
}

.challenge-breadcrumb-item {
  font-weight: bold;
  color: #ccc;
  font-size: 13px;
  font-family: 'Red Hat Display', sans-serif;
}

.challenge-breadcrumb-item a {
  text-decoration: none;
  color: #ccc;
  transition: none;
  font-size: 13px;
}

.challenge-breadcrumb-item .fa {
  font-size: 10px;
}

.challenge-breadcrumb-item a:hover {
  text-decoration: underline !important;
}

.challenge-breadcrumb-item span {
  color: #8a8a8a;
  padding-left: 8px;
  padding-right: 8px;
}

.challenge-breadcrumb-item:last-child * {
  color: #fff;
  padding: 0;
}

.breadcrumb-stats-container {
  height: 100%;
}

.breadcrumb-stats-title-container {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  cursor: pointer;
}

.breadcrumb-stats-content-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  z-index: 99999;
  background-color: #2f3237;
  border: 1px solid #3e444b;
  padding: 10px;
  width: 210px;
  right: -300px;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.breadcrumb-stats-content-container.active {
  right: 0;
}

.assessment-progress-popin > .breadcrumb-stats-content-item {
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.breadcrumb-stats-content-item p {
  margin: 0;
}

.breadcrumb-stats-content-item .progress {
  height: 14px;
  margin: 0;
}

.breadcrumb-stats-assessment-progressbar .progress {
  margin: 0;
}

.iframe-container-reporting {
  border: 1px solid #d5d5d5;
  overflow: hidden;
  padding-top: calc(100vh - 180px);
  position: relative;
  margin: 20px 40px 10px 40px;
  border-radius: 2px;
}

.iframe-container-reporting .spinner-container {
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}

.iframe-container-reporting iframe,
.iframe-container-adv iframe,
.iframe-container-challenge-player iframe,
.iframe-container-courses iframe,
.iframe-container-assessments iframe,
.iframe-container-new-features iframe,
.iframe-container-tournaments iframe {
  overflow: hidden;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.reporting-title {
  margin: 0px 0px 0px 40px;
}

.level-grouping-tooltip .tooltip-inner {
  max-width: 250px !important;
  padding: 10px;
}

.level-group-disabled input {
  display: none !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.flex-right {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.assessment-description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assessment-wrapper-pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.assessment-page-options {
  position: absolute;
  right: 0;
}

.assessment-page-options .form-inline {
  margin: 5px 5px 0 0;
}

.group-select {
  padding: 0;
  margin-left: 0;
  margin-right: 5px;
  font-weight: normal !important;
}

.reporting-modal .modal-content {
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  border: none;
}

.reporting-modal h5,
.reporting-modal h1,
.reporting-modal .modal-body .ui-select-container .select2-drop .select2-results {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.reporting-modal .modal-header {
  background-color: #fafafa;
  max-height: 56px;
  padding: 16px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border-bottom: 1px solid #454545;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  border-radius: 4px 4px 0 0;
}

.reporting-modal .modal-title {
  color: #555555;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  font-weight: 700;
}

.reporting-modal .modal-body {
  min-height: 35px;
  padding: 16px;
}

.reporting-modal .modal-body p {
  color: #9ea2ab;
  margin: 10px 0;
  font-weight: 700;
  line-height: 1.35em;
  font-size: 14px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.reporting-modal .md-button.md-primary.md-raised,
.reporting-modal .md-button.md-primary:hover,
.reporting-modal .md-button.md-primary:focus,
.reporting-modal .md-button.md-primary:disabled {
  background-color: #15bbb9 !important;
  color: white;
}

.reporting-modal a {
  color: #15bbb9;
}

.reporting-modal .md-button.md-accent {
  background-color: #9e9e9e;
  color: white;
}

.reporting-modal .select2-highlighted {
  background-color: #15bbb9 !important;
}

.reporting-modal .select2-choices {
  border-radius: 4px;
  border: none !important;
  box-shadow: none !important;
  background: white;
}

.reporting-modal .select2-choices input:focus {
  box-shadow: none !important;
  border: none !important;
}

.reporting-modal .select2-drop {
  margin-top: 0;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

.reporting-modal .select2-choices .select2-search-choice {
  box-shadow: none;
  background: #fafafa;
  color: #555555;
  border: 1px solid #9e9e9e;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  padding: 6px;
}

.reporting-modal .select2-choices .select2-search-choice:hover {
  background: #ececec;
}

.reporting-modal .modal-header {
  border-bottom: 1px solid #9e9e9e;
}

.reporting-modal .modal-footer {
  border-top: 1px solid #9e9e9e;
  padding: 4px 16px;
}

.reporting-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.reporting-modal .select2-results .select2-highlighted {
  background-color: white !important;
  color: inherit;
}

.reporting-modal .select2-results .select2-highlighted:hover {
  background-color: #f5f5f5 !important;
}

.reporting-modal .select2-search-choice-close {
  transition: none;
}

.reporting-modal .select2-results .select2-result-label {
  padding: 8px;
}

.reporting-modal .select2-results {
  padding: 0;
  margin: 0;
}

.reporting-modal .form-control {
  border: 1px solid #9e9e9e;
}

.reporting-modal .select2-container-multi .select2-search-choice-close {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

#maturity-badge {
  position: relative;
  margin: 0 auto;
}

#maturity-badge div {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.badge-front {
  z-index: 100;
}

.activities-security-maturity {
  display: inline-flex;
  justify-content: space-between;
}

.activities-security-maturity-text {
  margin-left: 15px;
}

.custom-swal-self-adjust {
  width: 35%;
}

@media only screen and (max-width: 1920px) {
  .custom-swal-self-adjust {
    width: 45%;
  }
}

@media only screen and (max-width: 1200px) {
  .custom-swal-self-adjust {
    width: 50%;
  }
}

.learning-hub-icon {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background-color: var(--color-scw-gray-2);
  -webkit-mask-image: url(/public/images/learning-hub.svg);
  mask-image: url(/public/images/learning-hub.svg);
}

.assessments-list-csv-export .option-container {
  margin: 2em auto 0 auto;
}

.scw-body {
  align-content: stretch;
  display: flex;
  flex-direction: column;
}

.scw-body .scw-main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.scw-body .scw-main {
  flex: auto;
}

/* Light theme baseline */
.reporting-app {
  background-color: #f6f8fb;
  color: #000000de;
  min-height: 100%;
  overflow: auto;
}

.leaderboard-table .label {
  text-transform: uppercase !important;
}

.breadcrumb-badge {
  font-weight: bold;
  display: inline-flex;
  font-size: 12px;
  border-radius: 8px;
  padding: 4px 8px;
  font-family: 'Poppins', 'Open Sans', Helvetica, Arial, sans-serif;
  margin: 6px 0 8px 12px;
  color: #fbfbfd;
  background: #494b5f;
}

.osano-cm-widget {
  display: none;
}

.tournament-modal-timer-container {
  display: flex;
  justify-content: space-evenly;
}

.tournament-modal-timer-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tournament-modal-footer-items {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tournament-modal-checkbox-label {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.tournament-global-leaderboard-stats {
  position: absolute;
  font-size: 20px;
}

.tournament-global-company-rank {
  display: flex;
  gap: 20px;
}

.mct-banner {
  margin-bottom: 40px;
}

#ScwCertificatePreview .MuiButtonBase-root {
  margin-top: 5px;
}

.ds-tooltip-fix {
  display: inline-block;
  gap: 5px;
}

.ds-focus:focus,
.ds-focus[type='checkbox']:focus {
  outline: 2px solid #459bff;
  outline-offset: 2px;
}

.ds-focus:not(:focus-visible),
.ds-focus[type='checkbox']:not(:focus-visible) {
  outline: 0;
}

#swal-button-container .ds-focus:focus {
  outline: 2px solid #459bff !important;
  outline-offset: 2px !important;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.maturity-display-col .progress-bar-success {
  background-color: #398439;
}

.a11y-h5-style {
  font-weight: bold;
  color: #ffb700;
  margin-top: 14px;
}

.a11y-alert-danger {
  background-color: #bd2828;
  color: #ffffff;
  border-color: #ed4d63;
}

.a11y-help-button {
  margin-bottom: 15px;
  background: transparent;
  border: none;
}

.a11y-button {
  background: transparent;
  border: none;
}

.a11y-policy-links a {
  text-decoration: underline;
}

.a11y-header-container {
  display: inline;
}

form[name='registerForm'] .alert-danger,
form[name='loginForm'] .alert-danger,
form[name='resetForm'] .alert-danger,
form[name='inviteAcceptForm'] .alert-danger,
form[name='publicRegistrationForm'] .alert-danger,
form[name='forms.profileForm'] .alert-danger,
form[name='forms.passwordForm'] .alert-danger {
  background-color: #bd2828;
  color: #ffffff;
}

form[name='registerForm'] .has-error,
form[name='resetForm'] .has-error,
form[name='inviteAcceptForm'] .has-error,
form[name='publicRegistrationForm'] .has-error,
form[name='forms.profileForm'] .has-error,
form[name='forms.passwordForm'] .has-error {
  .control-label {
    color: #c8c8c8;
  }
  .form-control {
    border-width: 2px;
  }
}
